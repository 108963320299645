var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Tooltip } from "@mui/material";
import { useState } from "react";
export function TooltipIfTruncated(_a) {
    var { textRefCurrent } = _a, tooltipPops = __rest(_a, ["textRefCurrent"]);
    const [enableTooltip, setEnableTooltip] = useState(false);
    const chechEnableTooltip = () => {
        const cw = textRefCurrent === null || textRefCurrent === void 0 ? void 0 : textRefCurrent.clientWidth;
        const sw = textRefCurrent === null || textRefCurrent === void 0 ? void 0 : textRefCurrent.scrollWidth;
        if (cw && sw)
            setEnableTooltip(sw > cw);
    };
    return (_jsx("span", Object.assign({ onMouseEnter: chechEnableTooltip }, { children: enableTooltip ? (_jsx(Tooltip, Object.assign({}, tooltipPops, { open: tooltipPops.open, title: tooltipPops.title, arrow: true, placement: "top" }, { children: tooltipPops.children }))) : (_jsxs(_Fragment, { children: [tooltipPops.children, " "] })) })));
}
