var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Unit } from "molstar/lib/mol-model/structure";
import { FileType } from "../../utils";
import { findSystemByKey } from "../stateTree";
export const selectionRefToIndexes = (sel, molstar) => {
    const selection = molstar.managers.structure.selection.getStructure(sel.cell.obj.data);
    return selectionToIndexes(selection);
};
const unitToIndexes = (unit) => {
    if (!Unit.isAtomic(unit))
        return;
    const atomicSourceIndex = unit.model.atomicHierarchy.atomSourceIndex;
    const chainIndexes = Array.from(unit.elements).map((element) => atomicSourceIndex.value(element));
    return chainIndexes;
};
const selectionToIndexes = (selection) => {
    if (!selection || selection.elementCount === 0)
        return;
    const indexes = [];
    for (const unit of selection.units) {
        //different chains
        const chainIndexes = unitToIndexes(unit);
        if (chainIndexes) {
            indexes.push(...chainIndexes);
        }
    }
    return indexes;
};
function addElementToOSelectionDict(selectionStrings, moleculeId, element) {
    if (!(moleculeId in selectionStrings)) {
        selectionStrings[moleculeId] = {
            molId: moleculeId,
            elements: [],
        };
    }
    selectionStrings[moleculeId].elements.push(element);
}
function extractNumberFromIdx(str) {
    const match = str.match(/\((\d+)\)$/);
    if (match) {
        const num = Number(match[1]);
        if (!Number.isNaN(num) && num > 0)
            return num - 1;
    }
    return undefined;
}
export function molstarSelectionsToSelectionStrings(molstar, vss) {
    const loaded_systems = vss.getState().loaded_structures;
    const selectionStrings = {};
    for (const [cellRef, { structure: sel_structure, selection }] of molstar
        .managers.structure.selection.entries) {
        if (!sel_structure)
            continue;
        const system = findSystemByKey(loaded_systems, "cellRef", cellRef, true);
        const moleculeId = system === null || system === void 0 ? void 0 : system.moleculeID;
        if (!moleculeId)
            continue;
        if ((system === null || system === void 0 ? void 0 : system.type) === "sdf" && system.numEntries && system.numEntries > 1) {
            for (let i = 0; i < selection.elements.length; i += 1) {
                const entrySelStructure = sel_structure.units[i];
                const entrySelection = selection.elements[i];
                if (!entrySelStructure || !entrySelection) {
                    continue;
                }
                const sdfIdx = extractNumberFromIdx(entrySelStructure.model.entryId);
                if (sdfIdx === undefined)
                    continue;
                const entryIndexes = unitToIndexes(entrySelStructure);
                if (!entryIndexes || entryIndexes.length === 0)
                    continue;
                const indexStr = entryIndexes.map((e) => e.toString()).join(" ");
                const selElement = {
                    selection: indexStr,
                    sdfIdx,
                };
                addElementToOSelectionDict(selectionStrings, moleculeId, selElement);
            }
        }
        else {
            const indexes = selectionToIndexes(sel_structure);
            if (!indexes || indexes.length === 0)
                continue;
            const indexStr = indexes.map((e) => e.toString()).join(" ");
            const selElement = {
                selection: indexStr,
                sdfIdx: system.type === "sdf" ? 0 : undefined,
            };
            addElementToOSelectionDict(selectionStrings, moleculeId, selElement);
        }
    }
    return Object.values(selectionStrings);
}
export function getSystemRepresentations(loaded_systems, toMolId, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        const toSystem = findSystemByKey(loaded_systems, "moleculeID", toMolId);
        if (!toSystem)
            return [];
        const newToSystemReps = toSystem.representations
            ? [...toSystem.representations]
            : [];
        const molNumAtoms = yield pyodide.RunPythonAsync({
            context: {},
            script: `
    mol = systems.get("${toMolId}")
    mol["molecule"].numAtoms if mol else 0
    `,
        });
        const newSel = `index < ${molNumAtoms}`;
        newToSystemReps.forEach((rep) => {
            rep.selection =
                rep.selection === "all"
                    ? rep.selection === newSel
                        ? rep.selection
                        : newSel
                    : `(${rep.selection}) and (index < ${molNumAtoms})`;
        });
        return newToSystemReps;
    });
}
export function addSmallMolRepresentations(newToSystemReps, [indexFrom, indexTo]) {
    const addReps = [];
    newToSystemReps.forEach((rep) => {
        addReps.push(Object.assign(Object.assign({}, rep), { selection: `(index >= ${indexFrom}) and (index<=${indexTo})` }));
    });
    newToSystemReps.unshift(...addReps);
}
function selectionInTargetSystem(selString, repSelection, addition_limits_index) {
    const newSelStr = `index ${addition_limits_index[0]} to ${addition_limits_index[1]}`;
    return repSelection === "all"
        ? newSelStr
        : `(${repSelection}) and (${newSelStr})`;
}
// async function checkMolIsSdf(pyodide: PyodideWorker) {
//   const isSDF = await pyodide.RunPythonAsync({
//     context: {},
//     script: `
//     len(set(_updated_mol.syst_to_mol_tmp.resname)) == 1
//     `,
//   });
//   return isSDF;
// }
export function copyRepresentationToTargetSystem(fromMolId, addition_limits_index, elements, newToSystemReps, loaded_systems) {
    return __awaiter(this, void 0, void 0, function* () {
        // const toIsSDF = await checkMolIsSdf(pyodide);
        const fromSystem = findSystemByKey(loaded_systems, "moleculeID", fromMolId);
        if (!fromSystem)
            return;
        const fromSystemReps = fromSystem === null || fromSystem === void 0 ? void 0 : fromSystem.representations;
        if (!fromSystemReps)
            return;
        const fromIsSDF = fromSystem.type === FileType.sdf;
        for (const element of elements) {
            const { selection: selectionFrom, sdfIdx } = element;
            for (const rep of fromSystemReps) {
                if (fromIsSDF &&
                    sdfIdx !== undefined &&
                    rep.refs &&
                    rep.refs[sdfIdx] &&
                    rep.refs[sdfIdx] === undefined)
                    continue;
                let selectionTo;
                // if (fromIsSDF && toIsSDF) {
                //   selectionTo = "all";
                // } else {
                selectionTo = selectionInTargetSystem(selectionFrom, rep.selection, addition_limits_index);
                // }
                newToSystemReps.unshift(Object.assign(Object.assign({}, rep), { selection: selectionTo }));
            }
        }
    });
}
