// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { findSystemFileInTree, setSystemCategoryDataViz } from "../utils";
import { createNewSystemFromDC, createNewSystemFromLocal, } from "../../FileLoaders/createNewSystem";
import { getAbsPathFromTable } from "../../3dViewer/Controls/utils";
export function loadDCFileFromTable(vss, molstar, pyodide, DCpath, fileName, s, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        const dcFilePath = `${DCpath}?files=${fileName}`;
        let system = findSystemFileInTree(s, dcFilePath);
        if (system === null || system === void 0 ? void 0 : system.cellRef) {
            setSystemCategoryDataViz(system, vss);
            return system;
        }
        else {
            system = yield createNewSystemFromDC(vss, molstar, pyodide, dcFilePath, undefined, undefined, "data-viz-interaction", representations);
            // if (cellRefs) addCellRefToTableRow(activeTableIdx, row, cellRefs);
            return system;
        }
    });
}
export function loadLocalFileFromTable(vss, molstar, pyodide, localBasePath, fileName, s, systemName, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        const absPath = getAbsPathFromTable(fileName, localBasePath);
        let system = findSystemFileInTree(s, absPath);
        if (system === null || system === void 0 ? void 0 : system.cellRef) {
            setSystemCategoryDataViz(system, vss);
            return { system, loadedNewFile: false };
        }
        else {
            system = yield createNewSystemFromLocal(vss, molstar, pyodide, absPath, systemName ? systemName : fileName, "data-viz-interaction", representations);
            // const newSyst = await createNewSystemFromDC(
            //   vss,
            //   molstar,
            //   pyodide,
            //   dcFilePath
            // );
            // if (cellRefs) addCellRefToTableRow(activeTableIdx, row, cellRefs);
            return { system, loadedNewFile: system !== undefined };
        }
    });
}
