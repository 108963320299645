// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FileType } from "../../utils";
import { loadFileAsText } from "./loadFileAsText";
import { loadFileAsArrayBuffer } from "./loadFileAsArrayBuffer";
import { formatErrors } from "./errorMessageFormatting";
import { supportedFiles, validateNAPfile } from "../../";
/**
 * Retrieves the content of files associated with the system passed.
 * If the system is a trajectory, it returns both the content of the
 * trajectory and topology files.
 *
 * For all other system types, only the topology file content is returned.
 *
 * An error is thrown if the system has no associated files
 * or if the topology file content could not be read.
 */
export function getFileContentForSystem(acceptedFiles, system) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (system.type === FileType.trajectory ||
                system.type === FileType.ensemble ||
                system.type === FileType.coordinates_group) {
                const { topFileContent, trajFileContent, topFileName, trajFileName, isInVFS, } = yield loadTrajectoryFilePair(acceptedFiles, system);
                return {
                    topFileContent: topFileContent,
                    trajFileContent: trajFileContent,
                    topFileName: topFileName,
                    trajFileName: trajFileName,
                    isInVFS: isInVFS,
                };
            }
        }
        catch (e) {
            console.error(e);
        }
        if (!system.files)
            throw Error(`system ${system.name} has no associated files!`);
        const [topFileName] = system.files; // non-traj --> only 1 file
        const fileRef = acceptedFiles[topFileName]; // pickup from filename:file list
        let topFileContent;
        let isInVFS = false;
        if (fileRef["isLoaded"]) {
            // to do - improve in the monorepo. These are files loaded through electron CLI. We already have the file content, no need to read it again. We need to extend the args interface to consider that these keys are present
            topFileContent = fileRef["data"];
        }
        else if (fileRef["isInVFS"]) {
            topFileContent = fileRef["data"];
            isInVFS = true;
        }
        else {
            topFileContent = yield loadFileAsText(fileRef, system.name);
        }
        if (topFileContent === undefined)
            throw Error(`Topo file ${topFileName} could not be read.`);
        return {
            topFileName: topFileName,
            topFileContent: topFileContent,
            trajFileName: "",
            trajFileContent: "",
            isInVFS: isInVFS,
        };
    });
}
function loadTrajectoryFilePair(acceptedFiles, system) {
    return __awaiter(this, void 0, void 0, function* () {
        const trajFiles = system.files;
        /*================  ERROR HANDLING PART ========================*/
        const errorMsg = `${system.name}: Trajectories need two file types - trajectory (${supportedFiles.coordinates_trajectory.join(", ")}) and model (${[
            ...supportedFiles.topology,
            ...supportedFiles.coordinates_static,
        ].join(", ")}).`;
        if (!trajFiles || (trajFiles === null || trajFiles === void 0 ? void 0 : trajFiles.length) > 2)
            throw new Error(errorMsg);
        const trajFileName = trajFiles.find((f) => {
            const ext = f.split(".").pop();
            return ext && supportedFiles.coordinates_trajectory.includes(ext);
        });
        const topFileName = trajFiles.find((f) => {
            const ext = f.split(".").pop();
            return (ext &&
                (supportedFiles.topology.includes(ext) ||
                    supportedFiles.coordinates_static.includes(ext)));
        });
        if (!trajFileName || !topFileName)
            throw new Error(errorMsg);
        /*==============================================================*/
        const topFile = acceptedFiles[topFileName];
        const trajFile = acceptedFiles[trajFileName];
        let topFileContent;
        let isInVFS = false;
        if (topFile["isLoaded"]) {
            // to do - improve in the monorepo. These are files loaded through electron CLI. We already have the file content, no need to read it again. We need to extend the args interface to consider that these keys are present
            topFileContent = topFile["data"];
        }
        else if (topFile["isInVFS"]) {
            topFileContent = topFile["data"];
            isInVFS = true;
        }
        else {
            topFileContent = yield loadFileAsText(topFile, system.name);
        }
        let trajFileContent;
        if (trajFile["isLoaded"]) {
            // to do - improve in the monorepo. These are files loaded through electron CLI. We already have the file content, no need to read it again. We need to extend the args interface to consider that these keys are present
            trajFileContent = trajFile["data"];
        }
        else if (trajFile["isInVFS"]) {
            trajFileContent = trajFile["data"];
            isInVFS = true;
        }
        else {
            trajFileContent = yield loadFileAsArrayBuffer(trajFile, system.name);
        }
        return {
            topFileContent: topFileContent,
            trajFileContent: trajFileContent,
            topFileName: topFileName,
            trajFileName: trajFileName,
            isInVFS: isInVFS,
        };
    });
}
export function readConfigFile(fileContent) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject(["File reading was aborted"]);
        reader.onerror = () => reject(["File reading has failed"]);
        reader.onload = () => {
            const stringContent = reader.result;
            try {
                const fileConfData = JSON.parse(stringContent);
                const validationResult = validateNAPfile(fileConfData);
                if (validationResult.isOk) {
                    resolve(fileConfData);
                    return;
                }
                if (validationResult.errors) {
                    reject(formatErrors(validationResult.errors));
                    return;
                }
            }
            catch (_a) {
                reject(["Wrong format."]);
            }
        };
        reader.readAsText(fileContent);
    });
}
