import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList, } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { RoundActionIconButton } from "../../components/Buttons/ActionIconButton/RoundActionIconButton";
export var DownloadRowsSelection;
(function (DownloadRowsSelection) {
    DownloadRowsSelection["all"] = "all";
    DownloadRowsSelection["filtered"] = "filtered";
})(DownloadRowsSelection || (DownloadRowsSelection = {}));
export function DownloadTableOptions({ downloadTable, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDownloadTable = (selection) => {
        downloadTable(selection);
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Download table", onClick: handleClick }, { children: _jsx(DownloadIcon, { fontSize: "small" }) })), _jsx(Menu, Object.assign({ id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    "aria-labelledby": "basic-button",
                } }, { children: _jsxs(MenuList, Object.assign({ dense: true }, { children: [_jsxs(MenuItem, Object.assign({ onClick: () => {
                                handleDownloadTable(DownloadRowsSelection.all);
                            } }, { children: [_jsx(ListItemIcon, { children: _jsx(TableRowsIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Download All Rows" })] })), _jsxs(MenuItem, Object.assign({ onClick: () => {
                                handleDownloadTable(DownloadRowsSelection.filtered);
                            } }, { children: [_jsx(ListItemIcon, { children: _jsx(FilterAltIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Download Filtered Rows" })] }))] })) }))] }));
}
