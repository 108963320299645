import { jsx as _jsx } from "react/jsx-runtime";
import { MSAPanelSelector } from "./MSAPanelSelector";
export var SeqOrMsaEum;
(function (SeqOrMsaEum) {
    SeqOrMsaEum["seqs"] = "seqs";
    SeqOrMsaEum["msa"] = "msa";
})(SeqOrMsaEum || (SeqOrMsaEum = {}));
export function SeqOrMsaSelector({ seqsOrMsa, setSeqsOrMsa, }) {
    const onChange = (event) => {
        setSeqsOrMsa(event.target.value);
    };
    const options = [
        { label: "Sequences", value: SeqOrMsaEum.seqs },
        { label: "MSAs", value: SeqOrMsaEum.msa },
    ];
    return (_jsx(MSAPanelSelector, { activeValue: seqsOrMsa, onChange: onChange, options: options }));
}
