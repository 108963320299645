// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { selectSystemByIndexArray } from "../stateTree";
export function getChildCellRef(system) {
    let currChildrenRefs = [];
    if (system.children) {
        system.children.forEach((s) => {
            const cr = getChildCellRef(s);
            currChildrenRefs = currChildrenRefs.concat(cr);
        });
    }
    else {
        if (system.cellRef)
            currChildrenRefs = currChildrenRefs.concat(system.cellRef);
    }
    return currChildrenRefs;
}
export function childCellRefFromIndexArray(vss, positionInTree) {
    const _systems = vss.getState().loaded_structures;
    const system = selectSystemByIndexArray(positionInTree, _systems);
    if (!system)
        return [];
    const childRefs = getChildCellRef(system);
    return childRefs;
}
