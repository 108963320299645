import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Tooltip } from "@mui/material";
import { useState } from "react";
export function TooltipControlled(tooltipPops) {
    // Controlled so that the tooltip is not open when hovering its popup.
    const [open, setOpen] = useState(false);
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const handleTooltipClose = () => {
        setOpen(false);
    };
    return (_jsx(Tooltip, Object.assign({}, tooltipPops, { open: open, title: tooltipPops.title }, { children: _jsx("span", Object.assign({ onMouseEnter: handleTooltipOpen, onMouseLeave: handleTooltipClose, onTouchStart: handleTooltipOpen, onTouchEnd: handleTooltipClose }, { children: tooltipPops.children })) })));
}
