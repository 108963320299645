// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function formatMSA(tablearray) {
    const result = {};
    tablearray.forEach((subArray) => {
        const key = subArray[0];
        const value = subArray.slice(1);
        result[key] = value;
    });
    return result;
}
export function getStrucToPosMap(posToStrucMap) {
    const msa_poses = Object.keys(posToStrucMap);
    const struc_to_msa = {};
    for (const msa_pos of msa_poses) {
        const chain = posToStrucMap[msa_pos]["chainid"];
        const resid = posToStrucMap[msa_pos]["resid"];
        if (chain === undefined || resid == undefined)
            continue;
        if (!(chain in struc_to_msa)) {
            struc_to_msa[chain] = {};
        }
        struc_to_msa[chain][`${resid}`] = msa_pos;
    }
    return struc_to_msa;
}
