// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { removeRepresentations } from "../RepresentationControls/removeRepresentations";
import { addRepresentation } from "../showFile";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { addReverseIndexing, FileType, indexSelector, } from "../../utils";
import { createArrayFromStartToN } from "../../utils/general_utils";
import { PluginStateObject, PluginStateTransform, } from "molstar/lib/mol-plugin-state/objects";
import { ParamDefinition as PD } from "molstar/lib/mol-util/param-definition";
import { Task } from "molstar/lib/mol-task";
import { Structure, Unit, } from "molstar/lib/mol-model/structure";
import { NAPGenericStore } from "../../GenericViewerState";
function ofTrajectorySelectionArray(trajectory, ctx, params) {
    return __awaiter(this, void 0, void 0, function* () {
        if (trajectory.frameCount === 0) {
            return Structure.Empty;
        }
        const numFrames = trajectory.frameCount;
        const shownFrames = params.shownFrames.filter((e) => e < numFrames && e >= 0);
        if (shownFrames.length === 0) {
            return Structure.Empty;
        }
        const units = [];
        let first = void 0;
        let count = 0;
        for (let i = 0, il = shownFrames.length; i < il; i += 1) {
            const frameIdx = shownFrames[i];
            const frame = yield Task.resolveInContext(trajectory.getFrameAtIndex(frameIdx), ctx);
            if (!first)
                first = frame;
            const structure = Structure.ofModel(frame);
            for (let j = 0, jl = structure.units.length; j < jl; ++j) {
                const u = structure.units[j];
                const invariantId = u.invariantId + count;
                const chainGroupId = u.chainGroupId + count;
                const newUnit = Unit.create(units.length, invariantId, chainGroupId, u.traits, u.kind, u.model, u.conformation.operator, u.elements);
                units.push(newUnit);
            }
            count = units.length;
        }
        return Structure.create(units, {
            representativeModel: first,
            label: first.label,
        });
    });
}
const StructureFromTrajectorySelection = PluginStateTransform.BuiltIn({
    name: "structure-from-trajectory-selectiom",
    display: {
        name: "Structure from Trajectory Frame Selectiom",
        description: "Create a molecular structure from a trajectory, including only a array of frames.",
    },
    from: PluginStateObject.Molecule.Trajectory,
    to: PluginStateObject.Molecule.Structure,
    params: {
        shownFrames: PD.Value([]),
    },
})({
    apply({ a /* this one gets the type in the "from" field above */, params }) {
        return Task.create("Build Structure", (ctx) => __awaiter(this, void 0, void 0, function* () {
            const s = yield ofTrajectorySelectionArray(a.data, ctx, params);
            const props = {
                label: "Ensemble",
                description: Structure.elementDescription(s),
            };
            return new PluginStateObject.Molecule.Structure(s, props);
        }));
    },
});
export function displayFrameSelection(trajCellRef, shownFrames, molstar) {
    return __awaiter(this, void 0, void 0, function* () {
        let ensemble;
        try {
            ensemble = yield molstar
                .build()
                .to(trajCellRef)
                .apply(StructureFromTrajectorySelection, {
                shownFrames,
            })
                .commit();
        }
        catch (_a) { }
        if (!ensemble)
            return undefined;
        let ensembleCellRefs = undefined;
        if (Array.isArray(ensemble)) {
            ensembleCellRefs = ensemble
                .map((s) => (s ? s.ref : undefined))
                .filter((s) => !!s);
            if (ensembleCellRefs.length === 0)
                ensembleCellRefs = undefined;
        }
        else if (ensemble && ensemble.ref) {
            ensembleCellRefs = [ensemble.ref];
        }
        return ensembleCellRefs;
    });
}
export function showSDFEntriesAsFrameEnsemble(system, trajCellRef, molstar, pyodide, vss, selection, numMols, positionInTree) {
    var _a, e_1, _b, _c;
    var _d;
    return __awaiter(this, void 0, void 0, function* () {
        NAPGenericStore.getState().setLoadingDCFile(true);
        const entryIndexesAvail = createArrayFromStartToN(0, numMols - 1);
        let visibleEntrieIDs;
        try {
            visibleEntrieIDs = indexSelector(selection, addReverseIndexing(entryIndexesAvail));
        }
        catch (e) {
            console.error(`${e}`);
            visibleEntrieIDs = entryIndexesAvail;
        }
        const ensembleCellRefs = yield displayFrameSelection(trajCellRef, visibleEntrieIDs, molstar);
        if (!ensembleCellRefs) {
            NAPGenericStore.getState().setLoadingDCFile(false);
            return;
        }
        // Remove previous ensemble if present
        const currentCellRef = system.cellRef;
        if (currentCellRef && currentCellRef.length > 0) {
            const model = molstar.state.data.selectQ((q) => q.byRef(currentCellRef[0]));
            if (model) {
                try {
                    for (var _e = true, model_1 = __asyncValues(model), model_1_1; model_1_1 = yield model_1.next(), _a = model_1_1.done, !_a;) {
                        _c = model_1_1.value;
                        _e = false;
                        try {
                            const cell = _c;
                            if (((_d = cell.obj) === null || _d === void 0 ? void 0 : _d.label) !== "Ensemble")
                                continue;
                            yield PluginCommands.State.RemoveObject(molstar, {
                                state: molstar.state.data,
                                ref: cell.transform.ref,
                            });
                        }
                        finally {
                            _e = true;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (!_e && !_a && (_b = model_1.return)) yield _b.call(model_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        let addedReps = undefined;
        if (system.representations) {
            // remove old representations
            // create new representations
            system.cellRef = ensembleCellRefs;
            addedReps = yield addRepresentation(molstar, pyodide, 
            // system,
            system.type == FileType.sdf, system.moleculeID, system.type == FileType.sdf, undefined, system.cellRef, system.visibility, system.representations, system.aromaticBonds, visibleEntrieIDs);
            yield removeRepresentations(system.representations, molstar);
        }
        vss.getState().updateSystemParameters(positionInTree, {
            cellRef: ensembleCellRefs,
            representations: addedReps,
            visibleElementIDs: selection,
        });
        NAPGenericStore.getState().setLoadingDCFile(false);
    });
}
