var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Popover, } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { dispatchTextInputDialogEvent } from "../../UserInputDialogs/TextInputDialog";
export function MoreGroupOptionsPopover({ anchorEl, handleClosePopover, updateGroupName, }) {
    const dialogOpen = Boolean(anchorEl);
    return (_jsx(Popover, Object.assign({ id: dialogOpen ? "group-extra-options-popover" : undefined, open: dialogOpen, anchorEl: anchorEl, onClose: handleClosePopover, anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "left",
        } }, { children: _jsx(MoreGroupOptionsMenu, { handleClosePopover: handleClosePopover, updateGroupName: updateGroupName }) })));
}
function MoreGroupOptionsMenu({ handleClosePopover, updateGroupName, }) {
    const handleChangeGroupName = () => __awaiter(this, void 0, void 0, function* () {
        const name = yield dispatchTextInputDialogEvent({
            title: "Group name",
            text: "",
        });
        if (name && updateGroupName) {
            updateGroupName(name);
        }
        handleClosePopover();
    });
    return (_jsx(_Fragment, { children: _jsx(MenuList, Object.assign({ dense: true }, { children: !!updateGroupName && (_jsxs(MenuItem, Object.assign({ onClick: handleChangeGroupName }, { children: [_jsx(ListItemIcon, { children: _jsx(EditIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Rename" })] }))) })) }));
}
