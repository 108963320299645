// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { moleculeFilesPath } from "../PyodideTerminal/constants";
import { flushFolderInVFS } from "../apps/utils";
export function loadInMoleculeKit(pyodide, systemID, modelfileContent, modelfileName, isSDF, trajfileContent, trajfileName, isInVFS) {
    return __awaiter(this, void 0, void 0, function* () {
        const modelvirtualFilePath = isInVFS
            ? modelfileContent
            : storeFileInVirtualFS(pyodide, modelfileContent, modelfileName, systemID);
        let trajvirtualFilePath;
        /* if they do not exist, they are set to empty strings which are falsy */
        // TODO: setting them to undefined would make the signature clearer
        if (trajfileContent && trajfileName) {
            trajvirtualFilePath = isInVFS
                ? trajfileContent
                : storeFileInVirtualFS(pyodide, trajfileContent, trajfileName, systemID);
        }
        const ok = yield createMolecule(pyodide, modelvirtualFilePath, trajvirtualFilePath, systemID);
        flushFolderInVFS(pyodide, "/mnt");
        if (!ok)
            throw Error(`Failed to instantiate Molecule class for model at ${modelfileName ? modelfileName : modelvirtualFilePath}`);
        const mols = yield getMoleculeTopologyAndCoordinates(isSDF, systemID, pyodide);
        return mols;
    });
}
export function getMoleculeTopologyAndCoordinates(isSDF, systemID, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        let mols = [];
        if (isSDF) {
            mols = yield pyodide.RunPythonAsync({
                context: {},
                script: `list(map(getMoleculeTopologyAndCoordinates, systems["${systemID}"]["sdfs"]))`,
            });
        }
        else {
            let mol = yield pyodide.RunPythonAsync({
                context: {},
                transfer: true,
                script: `getMoleculeTopologyAndCoordinates(systems["${systemID}"]["molecule"])`,
                debug: false,
            });
            mols.push(mol);
        }
        return mols;
    });
}
export const storeFileInVirtualFS = (pyodideWorker, fileContent, fileName, molID) => {
    let _fileContent = fileContent;
    let encoding = "utf8";
    if (fileContent && typeof fileContent !== "string") {
        _fileContent = new Uint8Array(fileContent);
        encoding = "binary";
    }
    const filePath = `${moleculeFilesPath}/${molID}_${fileName.replaceAll("/", "-")}`;
    /* SEE https://emscripten.org/docs/api_reference/Filesystem-API.html#FS.writeFile */
    pyodideWorker.FS.writeFile(filePath, _fileContent, encoding);
    return filePath;
};
export const createMolecule = (pyodide, modelFilePath, trajFilePath, molID) => __awaiter(void 0, void 0, void 0, function* () {
    const isOk = yield pyodide.RunPythonAsync({
        context: { modelFilePath, trajFilePath, molID },
        script: `
      from js import modelFilePath, trajFilePath, molID
      createMolecule(modelFilePath, trajFilePath, molID)
    `,
    });
    return isOk;
});
