// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addRepresentation } from "../showFile";
import { FileType } from "../../utils";
import { defaultColor, defaultType } from "./representationParamOptions";
import { getActiveSystemEntries } from "../../utils/IndexSelector/indexSelectorToIndexArray";
//
export function copyActiveRepresentation(system, activeRepId, molstar, pyodide, vss) {
    return __awaiter(this, void 0, void 0, function* () {
        // The new rep is a copy of the selected rep, if exists
        let newRep = (system === null || system === void 0 ? void 0 : system.representations)
            ? system.representations[activeRepId]
            : undefined;
        if (!newRep) {
            newRep = {
                selection: "all",
                alpha: 1,
                color: defaultColor,
                type: defaultType,
                visibility: true,
                aromaticBonds: system.aromaticBonds,
            };
        }
        const activeSystemEntries = getActiveSystemEntries(system);
        const addedReps = yield addRepresentation(molstar, pyodide, system.type === FileType.sdf, system.moleculeID ? system.moleculeID : "", system.type === FileType.sdf, undefined, system.cellRef, system.visibility, [newRep], system.aromaticBonds, activeSystemEntries);
        const addedRep = addedReps[0];
        if (addedReps.length === 0 || !system.stateTreePosition)
            return;
        vss.getState().add_representation(system.stateTreePosition, addedRep);
        // if (addedRep.refs) {
        //   setVisibilityForSDF(molstar, system?.visibleElementIDs, addedRep.refs);
        // }
        const newRepIdx = system.representations ? system.representations.length : 0;
        return newRepIdx;
    });
}
