import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { Popover, IconButton, Grid, Typography, FormHelperText, } from "@mui/material";
import { Box, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { hexToNum, numToHex } from "../utilsColors";
import { defaultColorRange } from "../../RepresentationControls/representationParamOptions";
import { InputNumberFocusOut } from "./InputNumberFocusOut";
import { ColorRangeElements } from "./ColorRangeElements";
function OpenRangeOptionsBtn({ disabled, handleClick, }) {
    return (_jsx(Tooltip, Object.assign({ title: "More options", arrow: true }, { children: _jsx(IconButton, Object.assign({ disabled: disabled, onClick: handleClick, color: "primary", sx: {
                mb: "0.1rem",
            } }, { children: _jsx(AddCircleOutlineIcon, { sx: { fontSize: 20 } }) })) })));
}
function SelectColorRagePopoverContent({ bfactorColorRange, bfactorColors, callback, }) {
    const _rangeMin = bfactorColorRange && bfactorColorRange.length > 0
        ? bfactorColorRange[0]
        : 0;
    const _rangeMax = bfactorColorRange && bfactorColorRange.length > 1
        ? bfactorColorRange[1]
        : 100;
    const _colors = bfactorColors && bfactorColors.length > 0
        ? bfactorColors
        : defaultColorRange;
    const _colorsHex = _colors.map((c) => numToHex(c));
    const [rangeMin, setRangeMin] = useState(_rangeMin);
    const [rangeMax, setRangeMax] = useState(_rangeMax);
    const [colors, setColors] = useState(_colorsHex);
    useEffect(() => {
        setRangeMin(_rangeMin);
    }, [_rangeMin]);
    useEffect(() => {
        setRangeMax(_rangeMax);
    }, [_rangeMax]);
    useEffect(() => {
        setColors(_colorsHex);
    }, [JSON.stringify(_colorsHex)]);
    useEffect(() => {
        const colorsNum = colors.map((c) => hexToNum(c));
        const newParams = {
            bfactor_color_range: [rangeMin, rangeMax],
            bfactor_colors: colorsNum,
        };
        callback(newParams);
    }, [rangeMin, rangeMax, JSON.stringify(colors)]);
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", justifyContent: "center", alignItems: "flex-start" }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, xs: true, 
                // columnSpacing={1}
                direction: "row", alignItems: "center", justifyContent: "flex-start" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 1 }, alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem" }, component: FormHelperText }, { children: "From:" })), _jsx(Box, Object.assign({ sx: { width: "3.5em" } }, { children: _jsx(InputNumberFocusOut, { currVal: rangeMin, onUpdate: setRangeMin }) }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 1 }, alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem" }, component: FormHelperText }, { children: "To:" })), _jsx(Box, Object.assign({ sx: { width: "3.5em" } }, { children: _jsx(InputNumberFocusOut, { currVal: rangeMax, onUpdate: setRangeMax }) }))] })) }))] })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem" }, component: FormHelperText }, { children: "Colors:" })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ColorRangeElements, { colors: colors, onUpdate: setColors }) }))] })));
}
export function SelectColorRageOptions({ bfactorColorRange, bfactorColors, disabled, callback, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "color-range-popover" : undefined;
    return (_jsxs("div", { children: [_jsx(OpenRangeOptionsBtn, { disabled: disabled, handleClick: handleClick }), _jsx(Popover, Object.assign({ id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                }, sx: {
                    "& .MuiPaper-root": { borderRadius: "9px", p: 1 },
                    "& .react-colorful__pointer": {
                        height: "20px",
                        width: "20px",
                    },
                    "& .react-colorful": {
                        height: "180px",
                        width: "200px",
                    },
                    "& .react-colorful__hue": {
                        height: "20px",
                    },
                    "& input": {
                        // border: "none",
                        border: "1px solid #e6e6e6",
                        // width: "100px",
                        mt: 1,
                        p: "5px",
                        "input:focus": {
                            border: "none",
                        },
                    },
                } }, { children: _jsx(SelectColorRagePopoverContent, { bfactorColorRange: bfactorColorRange, bfactorColors: bfactorColors, callback: callback }) }))] }));
}
