var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import { FileType } from "../../utils";
import { shallow } from "zustand/shallow";
import { recursiveGetAllChildSystems } from "../stateTree";
import { addSmallMolRepresentations, copyRepresentationToTargetSystem, getSystemRepresentations, molstarSelectionsToSelectionStrings, } from "./utils";
import { updateMoleculeReps } from "../../PyodideTerminal/updateMoleculeReps";
import { dispatchNotificationEvent } from "../../NotificationSystem/utils";
import { newSystFromMolObj } from "./newSystFromMolObj";
function CopySelectionOptions({ vss, handleClosePopover, pyodide, molstar, }) {
    //vss.getState().loaded_structures;
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    let systemsArr = [];
    loaded_structures.forEach((system) => {
        const groupSystems = recursiveGetAllChildSystems(system);
        systemsArr.push(...groupSystems);
    });
    // set max width, show end part of the system name if too long
    // fix mix sdfs and proteins
    const handleSystemClick = (e, system) => { var _a, e_1, _b, _c, _d, e_2, _e, _f; return __awaiter(this, void 0, void 0, function* () {
        const molSelectedStrings = molstarSelectionsToSelectionStrings(molstar, vss);
        if (!molSelectedStrings)
            return;
        const loaded_systems = vss.getState().loaded_structures;
        try {
            if (!system) {
                // Copy to a new sistem
                //1. create molecule from selections
                yield pyodide.RunPythonAsync({
                    context: {},
                    script: `
        _new_mol_from_sel = CreateMoleculeFromSelections()
        `,
                });
                const newToSystemReps = [];
                try {
                    for (var _g = true, molSelectedStrings_1 = __asyncValues(molSelectedStrings), molSelectedStrings_1_1; molSelectedStrings_1_1 = yield molSelectedStrings_1.next(), _a = molSelectedStrings_1_1.done, !_a;) {
                        _c = molSelectedStrings_1_1.value;
                        _g = false;
                        try {
                            const sel = _c;
                            const { elements, molId: fromMolId } = sel;
                            const res = yield pyodide.RunPythonAsync({
                                context: { elements, fromMolId },
                                script: `
            from js import elements, fromMolId
            _new_mol_from_sel.add_selection_to_new_mol(elements, fromMolId)
            `,
                            });
                            yield copyRepresentationToTargetSystem(fromMolId, res["addition_limits_index"], elements, newToSystemReps, loaded_systems);
                        }
                        finally {
                            _g = true;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (!_g && !_a && (_b = molSelectedStrings_1.return)) yield _b.call(molSelectedStrings_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                //2. Define new system
                const res = yield pyodide.RunPythonAsync({
                    context: {},
                    script: `
        _new_mol_from_sel.get_mol_info()
        `,
                });
                let system;
                const name = "System"; // ask user for name
                system = {
                    name: name,
                    type: res.isTrajectory
                        ? FileType.trajectory
                        : res.isSDF
                            ? FileType.sdf
                            : FileType.coordinates_single,
                    representations: newToSystemReps,
                    files: [],
                };
                //3. Generate molkit molecule and molstar model
                yield newSystFromMolObj(molstar, pyodide, system, res.isSDF, "_new_mol_from_sel.syst_to");
                //5. Update system tree
                if (system.cellRef) {
                    vss.getState().add_loaded_structure(system);
                }
                else {
                    throw new Error("Error creating the new system.");
                }
            }
            else {
                const toMolId = system.moleculeID;
                if (!toMolId)
                    return;
                const molSelectedStringsFilt = molSelectedStrings.filter((e) => e.molId !== toMolId);
                let newToSystemReps = yield getSystemRepresentations(loaded_systems, toMolId, pyodide);
                yield pyodide.RunPythonAsync({
                    context: {},
                    script: `
          _updated_mol = CopySelectionsToMolecule("${toMolId}")
          `,
                });
                try {
                    for (var _h = true, molSelectedStringsFilt_1 = __asyncValues(molSelectedStringsFilt), molSelectedStringsFilt_1_1; molSelectedStringsFilt_1_1 = yield molSelectedStringsFilt_1.next(), _d = molSelectedStringsFilt_1_1.done, !_d;) {
                        _f = molSelectedStringsFilt_1_1.value;
                        _h = false;
                        try {
                            const sel = _f;
                            const { elements, molId: fromMolId } = sel;
                            const res = yield pyodide.RunPythonAsync({
                                context: { elements, fromMolId },
                                script: `
            from js import elements, fromMolId
            _updated_mol.add_selection_to_tmp_mol(elements, fromMolId)
            `,
                            });
                            if (res["added_smallmol_limits_index"])
                                addSmallMolRepresentations(newToSystemReps, res["added_smallmol_limits_index"]);
                            yield copyRepresentationToTargetSystem(fromMolId, res["addition_limits_index"], elements, newToSystemReps, loaded_systems);
                        }
                        finally {
                            _h = true;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (!_h && !_d && (_e = molSelectedStringsFilt_1.return)) yield _e.call(molSelectedStringsFilt_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                vss.getState().addSystemParamsAfterUpdate(toMolId, {
                    representations: newToSystemReps,
                });
                const updateSmallmolSystems = yield pyodide.RunPythonAsync({
                    context: {},
                    script: `
          _updated_mol.update_syst_to()
          `,
                });
                if (updateSmallmolSystems) {
                    // the molecule change tracker doesn't include small molecule libraries, we have to trigger the system update function manually
                    yield updateMoleculeReps(vss, molstar, pyodide, toMolId);
                }
            }
            //SelectionStore.getState().clearSelection(e, molstar);
            handleClosePopover();
        }
        catch (error) {
            console.error(error);
            let customError;
            if (`${error}`.includes("Cannot concatenate molecules which different number of frames.")) {
                customError =
                    "Cannot concatenate molecules which different number of frames!";
            }
            dispatchNotificationEvent({
                message: customError
                    ? customError
                    : "There was an error when copying the selection.",
                type: "error",
            });
        }
    }); };
    return (_jsxs(MenuList, Object.assign({ dense: true, sx: {
            maxHeight: "80vh",
            overflow: "auto",
        } }, { children: [_jsx(MenuItem, Object.assign({ onClick: (e) => {
                    handleSystemClick(e);
                } }, { children: _jsx(ListItemText, { children: "New system" }) }), `sel-copy-to-new`), _jsx(Divider, {}), systemsArr.map((system, i) => (_jsx(MenuItem, Object.assign({ onClick: (e) => {
                    handleSystemClick(e, system);
                } }, { children: _jsx(ListItemText, { children: system.name }) }), `sel-copy-to-${system.moleculeID ? system.moleculeID : i}`)))] })));
}
export function CopySelection({ vss, pyodide, molstar, handleCloseParentPopover, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
        handleCloseParentPopover();
    };
    const open = Boolean(anchorEl);
    return (_jsxs(_Fragment, { children: [_jsxs(MenuItem, Object.assign({ onClick: handleOpenPopover }, { children: [_jsx(ListItemIcon, { children: _jsx(ContentCopyIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Copy to system" })] })), _jsx(Popover, Object.assign({ id: open ? "selecion-copy-popover" : undefined, open: open, anchorEl: anchorEl, onClose: handleClosePopover, anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                } }, { children: _jsx(CopySelectionOptions, { vss: vss, handleClosePopover: handleClosePopover, pyodide: pyodide, molstar: molstar }) }))] }));
}
