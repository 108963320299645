var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadTables } from "../Tables/loadTables";
import { syncTableToSystems } from "../Tables/utils";
import { loadPlots } from "./plots";
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
import { generateFileConfiguration } from "../";
import { loadSystems } from "./loadSystems";
import { displayNotSupportedError, displayUnmatchedError, } from "./FileReaders/errorMessageFormatting";
import { generateLoadingInstructions } from "./generateLoadingInstructions";
import { findConfigFile } from "./utils";
import { loadMSAs } from "../MSA/loadMSA/loadMSA";
/**
 * Entrypoint of the file loading pipeline.
 * Detects if configuration file(s) are paseed and triggers appropriate actions.
 */
export const fileUploadingHandler = (e, vss, molstar, pyodide, NAPGenericStore, callback) => __awaiter(void 0, void 0, void 0, function* () {
    const target = e.target;
    const acceptedFiles = target.files;
    const configFilesFound = findConfigFile(acceptedFiles);
    if (configFilesFound.length > 1) {
        dispatchNotificationEvent({
            type: "error",
            message: "More than one configuration files found, exactly one required!",
        });
        return;
    }
    const acceptedFilesObject = Object.assign({}, ...Array.from(acceptedFiles).map((x) => ({
        [x["path"] ? x["path"] : x.name]: x,
    })));
    let instructions;
    let localBasePath;
    switch (configFilesFound.length) {
        /* NO NAP FILE, GENERATE IT ON THE FLY */
        case 0:
            const config = generateFileConfiguration(acceptedFiles);
            if (config.unsupportedFilesDetected.length > 0)
                displayNotSupportedError(config.unsupportedFilesDetected);
            if (config.unmatchedFiles.length > 0) {
                displayUnmatchedError(config.unmatchedFiles);
            }
            instructions = config.loadingInstructions;
            break;
        /* NAP FILE PROVIDED, USE IT AS LOADING INSTRUCTIONS */
        case 1:
            const [configFile] = configFilesFound;
            const napFilePath = configFile["path"];
            localBasePath = napFilePath
                ? napFilePath.substring(0, napFilePath.lastIndexOf("/"))
                : undefined;
            instructions = yield generateLoadingInstructions(configFile, acceptedFilesObject, localBasePath);
            break;
        default:
            break;
    }
    if (instructions) {
        yield loadAllFileTypes(acceptedFilesObject, instructions, vss, molstar, pyodide, NAPGenericStore, localBasePath);
        callback(instructions);
    }
});
/**
 * Loads all file types specified in the given loading instructions and adds
 * successfully rendered systems to the VSS state.
 */
export function loadAllFileTypes(acceptedFiles, loadingInstructions, vss, molstar, pyodide, NAPGenericStore, localBasePath, quiet, archivedOnLoad) {
    return __awaiter(this, void 0, void 0, function* () {
        const s = yield loadSystems(acceptedFiles, loadingInstructions, vss, molstar, pyodide, quiet, archivedOnLoad);
        if (loadingInstructions.tables)
            yield loadTables(pyodide, loadingInstructions.tables, NAPGenericStore, acceptedFiles, localBasePath, quiet, archivedOnLoad);
        if (s)
            syncTableToSystems(s);
        if (loadingInstructions.MSAs)
            yield loadMSAs(pyodide, vss, loadingInstructions.MSAs, NAPGenericStore, acceptedFiles, localBasePath);
        if (loadingInstructions.plots)
            yield loadPlots(pyodide, acceptedFiles, loadingInstructions.plots, NAPGenericStore, localBasePath, quiet, archivedOnLoad);
    });
}
