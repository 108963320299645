import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, tooltipClasses, } from "@mui/material";
import { StyledTooltipApps } from "../../../apps/ApplicationsMenu/StyledTooltipApps";
import { TooltipControlled } from "../../../utils/TooltipControlled";
const ButtonBox = ({ tooltipMsg, tooltipPlacement, disabled, active, backgroundColor, iconColor, backgroundHoverColor, iconHoverColor, onMouseOver, onMouseOut, onTooltipOpen, onTooltipClose, tooltipIsOpen, children, tooltipMenu, }) => {
    const _backgroundColor = backgroundColor ? backgroundColor : "auto";
    const _backgroundHoverColor = backgroundHoverColor
        ? backgroundHoverColor
        : "primary.main";
    const _iconColor = iconColor ? iconColor : "auto";
    const _iconHoverColor = iconHoverColor ? iconHoverColor : "secondary.main";
    const box = (_jsx(Box, Object.assign({ sx: {
            height: "100%",
            width: "min-content",
            cursor: disabled ? "default" : "pointer",
            borderRadius: 1,
            transition: "background 0.5s, color 0.5s",
            backgroundColor: active ? _backgroundHoverColor : _backgroundColor,
            "& .MuiSvgIcon-root": {
                color: active ? _iconHoverColor : _iconColor,
                filter: disabled ? "brightness(75%)" : undefined,
            },
            "&:hover": {
                backgroundColor: disabled ? _backgroundColor : _backgroundHoverColor,
                "& .MuiSvgIcon-root": {
                    color: disabled ? _iconColor : _iconHoverColor,
                },
            },
        } }, { children: children })));
    const tooltipProps = {
        placement: tooltipPlacement ? tooltipPlacement : "top-end",
        title: tooltipMsg,
        onOpen: onTooltipOpen,
        onClose: onTooltipClose,
        open: tooltipIsOpen,
    };
    return (_jsx(_Fragment, { children: tooltipMsg ? (tooltipMenu ? (_jsx(StyledTooltipApps, Object.assign({ sx: { [`& .${tooltipClasses.tooltip}`]: { padding: 0, margin: 0 } }, PopperProps: {
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            } }, tooltipProps, { children: box }))) : (_jsx(TooltipControlled, Object.assign({ arrow: true, enterDelay: 1000 }, tooltipProps, { children: box })))) : (_jsx(_Fragment, { children: box })) }));
};
export default ButtonBox;
