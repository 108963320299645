import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Input } from "@mui/material";
import { useEffect, useState } from "react";
export function InputNumberFocusOut({ currVal, disabled, onUpdate, }) {
    const [internalValue, setInternalValue] = useState(currVal.toString());
    useEffect(() => {
        if (internalValue !== currVal.toString())
            setInternalValue(currVal.toString());
    }, [currVal]);
    useEffect(() => {
        onUpdate(Number(internalValue));
    }, [internalValue]);
    return (_jsx(Input
    // sx={{ mr: 1, ml: 1 }}
    , { 
        // sx={{ mr: 1, ml: 1 }}
        value: internalValue, size: "small", onChange: (e) => setInternalValue(e.target.value), disabled: disabled, inputProps: {
            step: 1,
            type: "number",
            "aria-labelledby": "input-slider",
            style: {
                fontSize: "0.875rem",
                color: disabled ? "#808080" : "black",
                margin: 0,
            },
        } }));
}
