import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import styled from "@emotion/styled";
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
export function LoadFileButton({ handleLoadFileCb, disabled, }) {
    return (_jsxs(Button, Object.assign({ fullWidth: true, disabled: disabled, component: "label", role: undefined, size: "small", variant: "contained", tabIndex: -1, startIcon: _jsx(DriveFolderUploadIcon, {}) }, { children: ["Load file", _jsx(VisuallyHiddenInput, { type: "file", onChange: (event) => handleLoadFileCb(event), multiple: true })] })));
}
