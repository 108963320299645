var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FileType, supportedFiles, } from "..";
import { generateModelAndReps } from "./generateModelAndReps";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../NotificationSystem/utils";
import { getRawFileContentOrFSpath } from "../apps/results/fetchDcData";
import { sleep } from "../utils/sleep";
import { extensionToFileType } from "./utils";
import { setVisibility } from "../3dViewer/VisibilityToggle";
import { getFileContentForSystem } from "./FileReaders/readFileWrappers";
import uuidv4 from "../utils/UUID";
export function renderSystem(vss, molstar, pyodide, acceptedFiles, currentSystem, quiet) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const systemFileNames = currentSystem.files ? currentSystem.files : [];
        const filesStr = systemFileNames.length > 1 &&
            systemFileNames.join(", ") !== currentSystem.name
            ? `(${systemFileNames === null || systemFileNames === void 0 ? void 0 : systemFileNames.join(", ")})`
            : "";
        if (!quiet)
            dispatchControlledNotification({
                idx: currentSystem.name,
                type: "info",
                message: `Loading...`,
                fileName: `${currentSystem.name} ${filesStr}`,
            });
        const isRemote = (_a = currentSystem.files) === null || _a === void 0 ? void 0 : _a.every((f) => f.startsWith("dc://"));
        if (isRemote) {
            yield renderFromDatacenter(vss, molstar, pyodide, currentSystem, quiet);
        }
        else {
            yield renderFromFile(vss, molstar, pyodide, acceptedFiles, currentSystem, quiet);
        }
        if (!quiet)
            dispatchDeleteControlledNotification({ idx: currentSystem.name });
        if (currentSystem.visibility === false && currentSystem.cellRef) {
            setVisibility(molstar, currentSystem.cellRef, false);
        }
    });
}
function renderFromDatacenter(
/*
    Warning!
    This function gets the system from a nap file.
    It is NOT a filebrowser callback!
  */
vss, molstar, pyodide, molSystem, quiet) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!molSystem.files)
            throw Error(`System ${molSystem.name} has no files!`);
        /* By topology file we mean most things here, like pdb's or sdf's etc. */
        let topoFileName;
        let trajFileName;
        if (molSystem.type === FileType.trajectory ||
            molSystem.type === FileType.ensemble ||
            molSystem.type === FileType.coordinates_group) {
            trajFileName = molSystem.files.find((f) => {
                const ext = f.split(".").pop();
                return ext && supportedFiles.coordinates_trajectory.includes(ext);
            });
            topoFileName = molSystem.files.find((f) => {
                const ext = f.split(".").pop();
                return (ext &&
                    (supportedFiles.topology.includes(ext) ||
                        supportedFiles.coordinates_static.includes(ext)));
            });
        }
        else {
            const files_coords = molSystem.files.filter((f) => {
                const ext = f.split(".").pop();
                return (ext &&
                    (supportedFiles.coordinates_static.includes(ext) ||
                        supportedFiles.volume.includes(ext)));
            });
            if (files_coords.length > 0) {
                [topoFileName] = files_coords;
            }
        }
        if (!topoFileName)
            throw Error("Topology file is mandatory!");
        yield renderDataCenterSystem(vss, molstar, pyodide, topoFileName, trajFileName, molSystem, undefined, quiet);
    });
}
function renderFromFile(vss, molstar, pyodide, acceptedFiles, system, quiet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { topFileContent, trajFileContent, topFileName, trajFileName, isInVFS, } = yield getFileContentForSystem(acceptedFiles, system);
            yield generateModelAndReps(vss, molstar, pyodide, system, 
            /* force type because undefined is caught beforehand */
            topFileContent, trajFileContent, topFileName, trajFileName, undefined, undefined, quiet, isInVFS);
        }
        catch (e) {
            dispatchNotificationEvent({
                type: "error",
                message: `${e}`,
            });
        }
    });
}
export function renderDataCenterSystem(vss, molstar, pyodide, topFilePath, trajFilePath, system, loadMultiple, quiet, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        const topFileName = topFilePath.split("=").pop();
        const trajFileName = trajFilePath ? trajFilePath.split("=").pop() : undefined;
        const systemName = trajFileName
            ? `${trajFileName}, ${topFileName}`
            : topFileName;
        const idx = system ? system.name : systemName ? systemName : "";
        if (!quiet)
            dispatchControlledNotification({
                idx: idx,
                type: "info",
                message: `Loading...`,
                fileName: systemName,
            });
        const files = [topFilePath];
        if (trajFilePath) {
            files.push(trajFilePath);
        }
        const extension = topFileName === null || topFileName === void 0 ? void 0 : topFileName.split(".").pop();
        const _system = system
            ? system
            : {
                name: systemName ? systemName : "",
                files: files,
                representations: representations,
                type: trajFilePath
                    ? FileType.trajectory
                    : extensionToFileType[extension ? extension : ""],
            };
        let topData;
        let trajData;
        let isInVFS = false;
        try {
            if (pyodide) {
                const topContentOrPath = yield getRawFileContentOrFSpath(topFilePath, pyodide);
                if (topContentOrPath) {
                    if (topContentOrPath.content) {
                        topData = topContentOrPath.content;
                        yield sleep(2000);
                    }
                    else {
                        topData = topContentOrPath.path;
                        isInVFS = true;
                    }
                }
                if (trajFilePath) {
                    const trajContentOrPath = yield getRawFileContentOrFSpath(trajFilePath, pyodide, true);
                    if (trajContentOrPath) {
                        if (trajContentOrPath.content) {
                            if (isInVFS)
                                throw new Error("Error reading topology-trajectory pair");
                            trajData = trajContentOrPath.content;
                            yield sleep(2000);
                        }
                        else {
                            if (!isInVFS)
                                throw new Error("Error reading topology-trajectory pair");
                            trajData = trajContentOrPath.path;
                        }
                    }
                }
            }
            if (topData) {
                yield generateModelAndReps(vss, molstar, pyodide, _system, topData, trajData, topFileName ? topFileName : "", trajFileName, undefined, undefined, quiet, isInVFS);
            }
        }
        catch (e) {
            dispatchNotificationEvent({ message: e, type: "error" });
        }
        if (!quiet)
            dispatchDeleteControlledNotification({ idx: idx });
        return _system.cellRef && _system.cellRef.length > 0 ? _system : undefined;
    });
}
export function renderDataCenterSysterenderSystemFromPDB(vss, molstar, pyodide, pdbId, representations, visibility) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatchControlledNotification({
            idx: pdbId,
            type: "info",
            message: `Loading...`,
            fileName: pdbId,
        });
        const molId = uuidv4();
        const system = {
            name: pdbId,
            files: [`${pdbId}.cif`],
            representations: representations,
            type: FileType.coordinates_single,
            visibility: visibility,
            moleculeID: molId,
        };
        try {
            // create molecule
            yield generateModelAndReps(vss, molstar, pyodide, system, pdbId, undefined, pdbId, undefined, molId, undefined, undefined, true);
        }
        catch (e) {
            dispatchDeleteControlledNotification({ idx: pdbId });
            dispatchNotificationEvent({
                type: "error",
                message: `Error loading ${pdbId}`,
            });
            console.error(e);
            return;
        }
        if (visibility === false && system.cellRef) {
            setVisibility(molstar, system.cellRef, false);
        }
        dispatchDeleteControlledNotification({ idx: pdbId });
        return system.cellRef && system.cellRef.length > 0 ? system : undefined;
    });
}
