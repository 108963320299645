import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy, } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToParentElement, restrictToVerticalAxis, } from "@dnd-kit/modifiers";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
function SortableItem({ id, isActive, children, }) {
    const { attributes, isDragging, listeners, setNodeRef, transform, transition, } = useSortable({ id: id });
    const _isActive = isActive === undefined ? true : isActive;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        color: _isActive ? undefined : "rgba(0,0,0,0.5)",
    };
    return (_jsx(ListItem, Object.assign({ dense: true, ref: setNodeRef, style: style }, attributes, listeners, { children: _jsxs(ListItemButton, Object.assign({ focusRipple: false, selected: isDragging }, { children: [_jsx(DragHandleIcon, { fontSize: "small", sx: { transform: "rotate(90deg)", mx: 1, color: "rgba(0,0,0,0.5)" } }), _jsx(ListItemText, { children: children }), !_isActive && (_jsx(FilterAltOffIcon, { fontSize: "small", sx: { mx: 1, color: "rgba(0,0,0,0.3)" } }))] })) })));
}
export function SortableList({ items, setItems, itemIsActive, }) {
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };
    return (_jsx(DndContext, Object.assign({ collisionDetection: closestCenter, onDragEnd: handleDragEnd, modifiers: [restrictToVerticalAxis, restrictToParentElement] }, { children: _jsx(SortableContext, Object.assign({ items: items, strategy: verticalListSortingStrategy }, { children: _jsx(List, { children: items.map((id) => (_jsx(SortableItem, Object.assign({ id: id, isActive: !itemIsActive || (itemIsActive && itemIsActive[id]) }, { children: id }), `sortable-table-list-${id}`))) }) })) })));
}
