import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { NAPPlotStore, NAPTableStore } from "../..";
import { Box, Fade, Grid, Typography } from "@mui/material";
import { Panels, } from "../../GenericViewerState/SharedState";
import { TablePlotsTabs } from "../../DataViz/DataViz";
import { bottomPanel, rightPanel } from "../panelDimensions";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { NavigationTableData } from "../../Tables/TableNavigation/NavigationTableData";
import { deleteAllDatavizElementIntances } from "../../DataViz/utils";
import { DatavizElements } from "../../DataViz/interfaces";
import RoundDeleteButton from "../../components/Buttons/DeleteButton/RoundDeleteButton";
import { RoundActionIconButton } from "../../components/Buttons/ActionIconButton/RoundActionIconButton";
import { useState } from "react";
import { VerticalDragHandler } from "./VerticalDragHandler";
function TablePlotsButtons({ handleClickHide, handleDeleteAll, }) {
    return (_jsxs(Grid, Object.assign({ container: true, sx: { height: "2.1em" }, alignContent: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Hide", onClick: handleClickHide }, { children: _jsx(ExpandLessIcon, { style: { transform: "rotate(90deg)" }, fontSize: "small" }) })) })), handleDeleteAll ? (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(RoundDeleteButton, { onClick: handleDeleteAll, tooltipMsg: "Close all", icon: "close" }) }))) : null] })));
}
export const PlotsPanel = ({ NAPGenericStore, vss, molstar, pyodide, skipButtons, fileSelectorAsTabs, }) => {
    const { width, setWidth } = NAPGenericStore((state) => ({
        width: state.dataVizPanelWidth,
        setWidth: state.setDataVizPanelWidth,
    }), shallow);
    const [isResizing, setIsResizing] = useState(false);
    const [activePanels, setActivePanels, sideBarWidth, dataVizDetails] = NAPGenericStore((state) => [
        state._activePanels,
        state.setActivePanels,
        state.sideBarWidth,
        state.dataVizDetails,
    ], shallow);
    const handleClickHide = () => {
        setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: false }));
    };
    const [tables] = NAPTableStore((state) => [state.tables], shallow);
    const [plots] = NAPPlotStore((state) => [state.plots], shallow);
    const numTables = tables.filter((t) => !t.archived).length;
    const numPlots = plots.filter((p) => !p.archived).length;
    const singleElement = numTables + numPlots === 1 ? true : false;
    const multipleInstanceOneElement = (numTables === 0 || numPlots === 0) &&
        numTables + numPlots > 1 &&
        !fileSelectorAsTabs;
    let handleDeleteAll;
    if (multipleInstanceOneElement) {
        const elementType = numTables > 0 ? DatavizElements.Tables : DatavizElements.Plots;
        handleDeleteAll = () => {
            deleteAllDatavizElementIntances(elementType, numTables, numPlots);
        };
    }
    const maxHeight = activePanels.console
        ? `calc(100vh - ${bottomPanel.height} + 1.2em)`
        : undefined;
    const rightPanelDimensions = Object.assign(Object.assign({}, rightPanel), { 
        // p: (theme: Theme) => `calc(${theme.spacing(1)})`,
        pl: "10px", maxHeight: maxHeight, width: width });
    return (_jsxs(_Fragment, { children: [_jsx(Fade, Object.assign({ in: activePanels.plots, style: { zIndex: 1 } }, { children: _jsxs(Box, Object.assign({ sx: { userSelect: isResizing ? "none" : "auto" } }, { children: [_jsx(VerticalDragHandler, { onSetWidthCb: (w) => {
                                setWidth(`${w}px`);
                            }, onSetIsResizingCb: (val) => {
                                setIsResizing(val);
                            } }), _jsx(Box, Object.assign({ id: "plotpage-entrypoint", sx: rightPanelDimensions }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "row", sx: { p: (theme) => `calc(${theme.spacing(1)})` } }, { children: [_jsx(Grid, Object.assign({ item: true, xs: singleElement ? "auto" : 12 }, { children: _jsx(TablePlotsButtons, { handleClickHide: handleClickHide, handleDeleteAll: handleDeleteAll }) })), !!(dataVizDetails.name || dataVizDetails.description) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, direction: "column", sx: { order: 1, px: 1 } }, { children: [!!dataVizDetails.name && (_jsx(Typography, Object.assign({ gutterBottom: true, variant: "h5" }, { children: dataVizDetails.name }))), !!dataVizDetails.description && (_jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", color: "text.secondary" }, { children: dataVizDetails.description })))] }))), _jsx(TablePlotsTabs, { vss: vss, molstar: molstar, pyodide: pyodide, NAPGenericStore: NAPGenericStore, skipButtons: skipButtons, fileSelectorAsTabs: fileSelectorAsTabs })] })) }))] })) })), _jsx(NavigationTableData, { NAPGenericStore: NAPGenericStore, vss: vss })] }));
};
