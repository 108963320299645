// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function numToHex(colornum) {
    if (colornum === undefined) {
        return;
    }
    return "#" + ("000000" + colornum.toString(16)).slice(-6);
}
export function hexToNum(color) {
    if (!color)
        return;
    return Number("0x" + color.replace("#", ""));
}
