import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box } from "@mui/material";
import MSAPositionsTypography from "./MSAPositionsTypography";
function MSAPositionsName({ mappingAvailable, }) {
    const nameStyle = {
        pl: 1,
        width: "85px",
        backgroundColor: "white",
    };
    return (_jsx(Box, Object.assign({ display: "inline", sx: {
            left: 0,
            position: "sticky",
            top: 0,
            height: mappingAvailable ? "30px" : "15px",
            zIndex: 5,
        } }, { children: mappingAvailable ? (_jsxs(_Fragment, { children: [_jsx(MSAPositionsTypography, Object.assign({ addToStyle: nameStyle }, { children: "Resid" })), _jsx(MSAPositionsTypography, Object.assign({ addToStyle: Object.assign(Object.assign({}, nameStyle), { pt: "2px", top: "15px" }) }, { children: "Alignment pos." }))] })) : (_jsx(MSAPositionsTypography, Object.assign({ addToStyle: nameStyle }, { children: "Alignment pos." }))) })));
}
export default MSAPositionsName;
