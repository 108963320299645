var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Typography } from "@mui/material";
import { MeasurementAccordion } from "./MeasurementAccordion";
import { MeasurementType, SelectionStore, } from "../../../StateStores/selection.store";
import { shallow } from "zustand/shallow";
import { Loci } from "molstar/lib/mol-model/loci";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import DeleteIcon from "@mui/icons-material/Delete";
import { MeasurementButton } from "./MeassurementButton";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { getButton, getButtons, getModifiers, } from "molstar/lib/mol-util/input/input-observer";
import { Representation } from "molstar/lib/mol-repr/representation";
import { capitalizeFirstLetter } from "../../../FormGenerator/utils";
import { angleLabel, dihedralLabel, distanceLabel } from "./utils";
export function MeasurementsList({ molstar }) {
    const [measurementLoci] = SelectionStore((state) => [state.measurementLoci], shallow);
    return (_jsxs(Box, Object.assign({ sx: { "& .MuiAccordionSummary-content": { my: 1 } } }, { children: [measurementLoci.distance.length > 0 && (_jsx(MeasurementTypeList, { molstar: molstar, measurementType: MeasurementType.distance, measurementTypeLoci: measurementLoci.distance })), measurementLoci.angle.length > 0 && (_jsx(MeasurementTypeList, { molstar: molstar, measurementType: MeasurementType.angle, measurementTypeLoci: measurementLoci.angle })), measurementLoci.dihedral.length > 0 && (_jsx(MeasurementTypeList, { molstar: molstar, measurementType: MeasurementType.dihedral, measurementTypeLoci: measurementLoci.dihedral }))] })));
}
function MeasurementTypeList({ molstar, measurementType, measurementTypeLoci, }) {
    return (_jsx(MeasurementAccordion, Object.assign({ name: `${capitalizeFirstLetter(measurementType)}s` }, { children: _jsx(_Fragment, { children: [...measurementTypeLoci].reverse().map((measureCell, i) => (_jsx(MeasuredElement, { measureCell: measureCell, molstar: molstar, measureIdx: measurementTypeLoci.length - 1 - i, measurementType: measurementType }, `measured-${measurementType}-${i}`))) }) })));
}
function MeasuredElement({ measureCell, molstar, measureIdx, measurementType, }) {
    var _a;
    const selections = (_a = measureCell.obj) === null || _a === void 0 ? void 0 : _a.data.sourceData;
    let label;
    let lociArray;
    switch (measurementType) {
        case MeasurementType.distance:
            const pairs = selections === null || selections === void 0 ? void 0 : selections.pairs;
            lociArray = pairs ? pairs[0].loci : [];
            label = pairs ? distanceLabel(pairs[0], molstar) : undefined;
            break;
        case MeasurementType.angle:
            const triples = selections === null || selections === void 0 ? void 0 : selections.triples;
            lociArray = triples ? triples[0].loci : [];
            label = triples ? angleLabel(triples[0]) : undefined;
            break;
        case MeasurementType.dihedral:
            const quads = selections === null || selections === void 0 ? void 0 : selections.quads;
            lociArray = quads ? quads[0].loci : [];
            label = quads ? dihedralLabel(quads[0]) : undefined;
            break;
    }
    const [isHidden, setIsHidden] = useState(false);
    const [wait, setWait] = useState(false);
    const highlight = () => {
        var _a;
        if (!selections)
            return;
        molstar.managers.interactivity.lociHighlights.clearHighlights();
        for (const loci of lociArray) {
            molstar.managers.interactivity.lociHighlights.highlight({ loci }, false);
        }
        const reprLocis = (_a = measureCell.obj) === null || _a === void 0 ? void 0 : _a.data.repr.getAllLoci();
        if (reprLocis) {
            for (const loci of reprLocis) {
                molstar.managers.interactivity.lociHighlights.highlight({ loci }, false);
            }
        }
    };
    const clearHighlight = () => {
        molstar.managers.interactivity.lociHighlights.clearHighlights();
    };
    const deleteMeasure = () => __awaiter(this, void 0, void 0, function* () {
        if (wait)
            return;
        setWait(true);
        yield PluginCommands.State.RemoveObject(molstar, {
            state: measureCell.parent,
            ref: measureCell.transform.parent,
            removeParentGhosts: true,
        });
        SelectionStore.getState().deleteMeasurementLoci(measurementType, measureIdx);
        setWait(false);
    });
    const focusMeasure = () => {
        if (!selections)
            return;
        const sphere = Loci.getBundleBoundingSphere({ loci: lociArray });
        if (sphere) {
            molstar.managers.camera.focusSphere(sphere);
        }
    };
    const toggleVisibility = () => __awaiter(this, void 0, void 0, function* () {
        if (wait)
            return;
        setWait(true);
        yield PluginCommands.State.ToggleVisibility(molstar, {
            state: measureCell.parent,
            ref: measureCell.transform.parent,
        });
        setIsHidden((prev) => !prev);
        setWait(false);
    });
    const selectMeasure = (e) => {
        SelectionStore.getState().setActiveMeasurement(undefined);
        const buttons = getButtons(e.nativeEvent);
        const button = getButton(e.nativeEvent);
        const modifiers = getModifiers(e.nativeEvent);
        const ev = {
            current: Representation.Loci.Empty,
            buttons,
            button,
            modifiers,
        };
        lociArray.forEach((loci) => {
            if (lociArray[0]) {
                ev.current = { loci };
                molstar.behaviors.interaction.click.next(ev);
            }
        });
    };
    return (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", sx: {
            px: "1rem",
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
        }, onMouseEnter: highlight, onMouseLeave: clearHighlight, onTouchStart: highlight, onTouchEnd: clearHighlight }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "13px" } }, { children: label })), _jsxs(Box, { children: [_jsx(MeasurementButton, Object.assign({ name: "Focus", callback: focusMeasure }, { children: _jsx(CenterFocusStrongIcon, { sx: { fontSize: "18px" } }) })), _jsx(MeasurementButton, Object.assign({ name: isHidden ? "Show" : "Hide", callback: toggleVisibility }, { children: isHidden ? (_jsx(VisibilityOffIcon, { sx: { fontSize: "18px" } })) : (_jsx(VisibilityIcon, { sx: { fontSize: "18px" } })) })), _jsx(MeasurementButton, Object.assign({ name: "Select", callback: selectMeasure }, { children: _jsx(HighlightAltIcon, { sx: { fontSize: "18px" } }) })), _jsx(MeasurementButton, Object.assign({ name: "Delete", callback: deleteMeasure }, { children: _jsx(DeleteIcon, { sx: { color: " #8c0d26", fontSize: "18px" } }) }))] })] })));
}
