import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { StateObjectRef, StateSelection } from "molstar/lib/mol-state";
export function getTrajectoryRef(molstar, cellRefs) {
    let trajectory;
    try {
        trajectory = StateSelection.findAncestorOfType(molstar.state.data.tree, molstar.state.data.cells, cellRefs[0], PluginStateObject.Molecule.Trajectory);
    }
    catch (e) {
        console.warn(e);
    }
    return trajectory === null || trajectory === void 0 ? void 0 : trajectory.transform.ref;
}
export function getTrajectoryNumFrames(trajCellRef, molstar) {
    var _a;
    const trajectory = StateObjectRef.resolveAndCheck(molstar.state.data, trajCellRef);
    if (!trajectory)
        return;
    return (_a = trajectory === null || trajectory === void 0 ? void 0 : trajectory.obj) === null || _a === void 0 ? void 0 : _a.data.frameCount;
}
export function getCurrentTrajMultipleFramesSettings(framesSettings) {
    return {
        from: (framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.from) ? framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.from : 1,
        to: (framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.to) ? framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.to : 1,
        step: (framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.step) ? framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.step : 1,
    };
}
