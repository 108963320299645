import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Collapse, Paper, Box, Typography, Select, MenuItem, } from "@mui/material";
import { SelectionStore } from "../../StateStores/selection.store";
import { shallow } from "zustand/shallow";
export function SelectionOptionsMenu({ isActive, molstar, backgroundColor, }) {
    const [selectionUnit, setSelectionUnit] = SelectionStore((state) => [state.selectionUnit, state.setSelectionUnit], shallow);
    const options = [
        ["element", "Atom/Coarse Element"],
        ["residue", "Residue", "With Symmetry"],
        ["chain", "Chain", "With Symmetry"],
        ["entity", "Entity", "With Symmetry"],
        ["model", "Model", "With Symmetry"],
        // ["operator", "Operator", "With Symmetry"],
        // ["structure", "Structure/Shape"],
        // ["elementInstances", "Atom/Coarse Element Instances", "With Symmetry"],
        // ["residueInstances", "Residue Instances", "With Symmetry"],
        // ["chainInstances", "Chain Instances", "With Symmetry"],
    ];
    return (_jsx(Collapse, Object.assign({ in: isActive, unmountOnExit: true }, { children: _jsx(Paper, Object.assign({ elevation: 0, sx: {
                backgroundColor: backgroundColor ? backgroundColor : undefined,
            } }, { children: _jsxs(Box, Object.assign({ sx: { mt: 1, py: 1, px: 2, gap: 2 }, display: "flex", justifyContent: "flex-start", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "13px" } }, { children: "Selection unit:" })), _jsx(Select, Object.assign({ sx: { fontSize: "14px" }, size: "small", variant: "standard", onChange: (e) => {
                            setSelectionUnit(e.target.value, molstar);
                        }, value: selectionUnit }, { children: options.map(([key, label]) => (_jsx(MenuItem, Object.assign({ value: key }, { children: label }), `select-selectionunit-${key}`))) }))] })) })) })));
}
