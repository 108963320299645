import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Typography } from "@mui/material";
import { NAPMSAStore } from "../StateStores/MSA.store";
import { MSA } from "./MSA";
import { useEffect, useState } from "react";
export function MSAPanel({ parentRef, refSystem, molstar, }) {
    const [msaArr, activeMSAIdx] = NAPMSAStore((state) => [state.msaArr, state.activeMSAIdx], shallow);
    const activeMSA = msaArr[activeMSAIdx];
    let refSystemData;
    if (refSystem) {
        const systCellRef = refSystem.cellRef ? refSystem.cellRef[0] : undefined;
        if (systCellRef) {
            refSystemData = {
                cellRef: systCellRef,
                name: refSystem.name,
            };
        }
    }
    const [refStateVar, setRefStateVar] = useState(parentRef.current);
    useEffect(() => {
        setRefStateVar(parentRef.current);
    }, [parentRef.current]);
    return (_jsx(_Fragment, { children: activeMSA === undefined ? (_jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", color: "text.secondary", sx: { px: 1 } }, { children: "No MSA available." }))) : (_jsx(MSA, { activeMSA: activeMSA, parentRefCurr: refStateVar, refSystem: refSystemData, molstar: molstar })) }));
}
