// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sleep } from "../utils/sleep";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, } from "../NotificationSystem/utils";
import { parseCSVString, parseCSVFile } from "./prepareTable";
import { readLocalTableInChunks } from "../FileLoaders/loadLocalFiles";
import { getRawFileContent } from "../apps/results/fetchDcData";
export function readCsv(tableFilePath, pyodide, acceptedFilesObject, quiet, addColumn, noHeader) {
    return __awaiter(this, void 0, void 0, function* () {
        let tableFileName;
        let tableArr;
        let error;
        let tableDCPath;
        const isRemote = tableFilePath.startsWith("dc://");
        if (isRemote) {
            if (!pyodide) {
                console.error("pyodide instance not found!");
                return;
            }
            // load from datacenter
            tableFileName = tableFilePath.slice(tableFilePath.lastIndexOf("=") + 1);
            if (!quiet)
                dispatchControlledNotification({
                    idx: tableFileName,
                    type: "info",
                    message: `Loading...`,
                    fileName: tableFileName,
                });
            const tableDataPath = tableFilePath;
            const csvString = yield getRawFileContent(tableDataPath, pyodide);
            yield sleep(2000);
            try {
                tableArr = yield parseCSVString(csvString.replace(/\n+$/, ""), addColumn, noHeader);
            }
            catch (e) {
                error = e;
            }
            tableDCPath = tableFilePath.slice(0, tableFilePath.indexOf("?"));
        }
        else {
            // the table should be among the loaded local files
            if (acceptedFilesObject && tableFilePath in acceptedFilesObject) {
                const tableFile = acceptedFilesObject[tableFilePath];
                tableFileName = tableFile.name;
                if (!quiet)
                    dispatchControlledNotification({
                        idx: tableFileName,
                        type: "info",
                        message: `Loading`,
                        fileName: tableFileName,
                    });
                if (tableFile["isInLFS"]) {
                    tableArr = yield readLocalTableInChunks(tableFile["name"], addColumn, noHeader);
                }
                else if (tableFile["isLoaded"]) {
                    try {
                        const _tableFile = tableFile;
                        tableArr = yield parseCSVString(_tableFile.data, addColumn, noHeader);
                    }
                    catch (e) {
                        error = e;
                    }
                }
                else {
                    try {
                        tableArr = yield parseCSVFile(tableFile, addColumn, noHeader);
                    }
                    catch (e) {
                        error = e;
                    }
                }
            }
            else {
                console.error(`Table file ${tableFilePath} ot found.`);
            }
        }
        dispatchDeleteControlledNotification({ idx: tableFileName });
        return {
            tableFileName,
            tableArr,
            error,
            tableDCPath,
            isRemote,
        };
    });
}
