var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { flexRender } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useCallback, useState, useMemo } from "react";
import DOMPurify from "isomorphic-dompurify";
import { getRowIsActive, getRowIsActiveEntries, getToggleVizColnames, } from "./findActiveRows";
import { TableClickAction } from "../utils";
import { NAPGenericStore } from "../GenericViewerState";
import { shallow } from "zustand/shallow";
export function PMVTableBody({ table, rows, isWaiting, onRowClick, onMouseEnter, onMouseLeave, tableContainerRefDiv, svgColumns, rowsAreClickable, activeTableIdx, rowOnClickActions, localBasePath, DCpath, tableID, }) {
    var _a, _b;
    const [visibleSystemsFiles] = NAPGenericStore((state) => [state.visibleSystemsFiles], shallow);
    const [visibleSystemFileEntries] = NAPGenericStore((state) => [state.visibleSystemFileEntries], shallow);
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRefDiv,
        count: rows.length,
        overscan: 10,
        estimateSize: () => (svgColumns && svgColumns.length > 0 ? 145 : 53), //estimate row height for accurate scrollbar dragging
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        // measureElement:
        //   typeof window !== "undefined" &&
        //   navigator.userAgent.indexOf("Firefox") === -1
        //     ? (element) => element?.getBoundingClientRect().height
        //     : undefined,
    });
    const virtualRows = rowVirtualizer.getVirtualItems();
    const totalSize = rowVirtualizer.getTotalSize();
    const paddingTop = virtualRows.length > 0 ? ((_a = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[0]) === null || _a === void 0 ? void 0 : _a.start) || 0 : 0;
    const paddingBottom = virtualRows.length > 0
        ? totalSize - (((_b = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[virtualRows.length - 1]) === null || _b === void 0 ? void 0 : _b.end) || 0)
        : 0;
    const rdkit = window.RDKit;
    const [inactiveRows, setInactiveRows] = useState([]);
    const colNames = table.getAllColumns().map((col) => col.id);
    const { toggleVizColnamesSystem, toggleVizColnamesEntries } = useMemo(() => {
        const toggleVizColnamesSystem = getToggleVizColnames(rowOnClickActions, colNames, [TableClickAction.toggleVisibility, TableClickAction.loadFile]);
        const toggleVizColnamesEntries = getToggleVizColnames(rowOnClickActions, colNames, [TableClickAction.showSystemEntry]);
        return { toggleVizColnamesSystem, toggleVizColnamesEntries };
    }, [tableID]);
    const _getRowIsActive = (row) => {
        let isActiveSyst = false;
        let isActiveEntries = false;
        if (toggleVizColnamesSystem.length > 0) {
            isActiveSyst = getRowIsActive(row, toggleVizColnamesSystem, visibleSystemsFiles, localBasePath, !!DCpath);
        }
        if (toggleVizColnamesEntries.length > 0) {
            isActiveEntries = getRowIsActiveEntries(row, rowOnClickActions, visibleSystemsFiles, visibleSystemFileEntries, localBasePath, !!DCpath);
        }
        return isActiveSyst || isActiveEntries;
    };
    const activeVisibleRows = useMemo(() => {
        const activeVisibleRows = [];
        virtualRows.forEach((virtualRow) => {
            const row = rows[virtualRow.index];
            const isActive = _getRowIsActive(row);
            if (isActive)
                activeVisibleRows.push(row.id);
        });
        return activeVisibleRows;
    }, [
        virtualRows,
        visibleSystemsFiles,
        visibleSystemFileEntries,
        activeTableIdx,
        rows,
    ]);
    const activeVisibleRowsStr = JSON.stringify(activeVisibleRows);
    const renderRows = useCallback((rows) => virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const isActive = activeVisibleRows.includes(row.id);
        const _onRowClick = (row, isActive) => __awaiter(this, void 0, void 0, function* () {
            const rowIdx = row.index;
            if (!inactiveRows.includes(rowIdx)) {
                setInactiveRows((pre) => [...pre, rowIdx]);
                yield onRowClick(row, isActive);
                setInactiveRows((pre) => pre.filter((idx) => idx !== rowIdx));
            }
        });
        return (_jsx(TableRow, Object.assign({ id: `table-row-${row.index}`, "data-index": virtualRow.index, ref: (node) => rowVirtualizer.measureElement(node), hover: true, onClick: () => _onRowClick(row, isActive), onMouseEnter: () => {
                if (onMouseEnter)
                    onMouseEnter(row);
            }, onMouseLeave: () => {
                if (onMouseLeave)
                    onMouseLeave();
            }, onTouchStart: () => {
                if (onMouseEnter)
                    onMouseEnter(row);
            }, onTouchEnd: () => {
                if (onMouseLeave)
                    onMouseLeave();
            }, style: {
                display: "flex",
                position: "absolute",
                transform: `translateY(${virtualRow.start}px)`,
                width: "100%",
            }, selected: isActive }, { children: row.getVisibleCells().map((cell) => {
                let svgCell = false;
                const isSVG = cell.column.columnDef["isSVG"];
                if (isSVG) {
                    try {
                        const mol = rdkit.get_mol(cell.getValue());
                        svgCell = mol ? mol.get_svg(125, 125) : undefined;
                        mol.delete();
                    }
                    catch (_a) {
                        svgCell = undefined;
                    }
                }
                return (_jsx(TableCell, Object.assign({ style: {
                        // display: "flex",
                        width: cell.column.getSize(),
                        verticalAlign: "center",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    } }, { children: svgCell ? (_jsx("div", { dangerouslySetInnerHTML: {
                            __html: DOMPurify.sanitize(svgCell),
                        } })) : (flexRender(cell.column.columnDef.cell, cell.getContext())) }), cell.id));
            }) }), row.id));
    }), [
        inactiveRows,
        virtualRows,
        activeVisibleRowsStr,
        activeTableIdx,
        onRowClick,
    ]);
    return (_jsxs(TableBody, Object.assign({ style: {
            cursor: rowsAreClickable
                ? isWaiting
                    ? "progress"
                    : "pointer"
                : "auto",
            display: "grid",
            height: `${rowVirtualizer.getTotalSize()}px`,
            position: "relative",
        } }, { children: [paddingTop > 0 && (_jsx(TableRow, { children: _jsx(TableCell, { style: { height: `${paddingTop}px` } }) }, "ror-paddingtop")), renderRows(rows), paddingBottom > 0 && (_jsx(TableRow, { children: _jsx(TableCell, { style: { height: `${paddingBottom}px` } }) }, "ror-paddingbottom"))] })));
}
