import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Typography } from "@mui/material";
function MSAPositionsTypography({ children, addToStyle, }) {
    const _addToStyle = addToStyle ? addToStyle : {};
    return (_jsx(Typography, Object.assign({ noWrap: true, variant: "caption", color: "primary", sx: Object.assign(Object.assign({}, _addToStyle), { position: "absolute", fontSize: "10px" }) }, { children: children })));
}
export default MSAPositionsTypography;
