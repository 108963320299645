// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../../NotificationSystem/utils";
import { formatMSA } from "./prepareMSA";
import { readCsv } from "../../Tables/readCsv";
import uuidv4 from "../../utils/UUID";
import { NAPMSAStore } from "../../StateStores";
import { readMSAmapping } from "./readMSAmapping";
function loadMSA(msaData, pyodide, vss, acceptedFilesObject, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        const msaFilePath = msaData.path;
        let msaFileName;
        let error;
        const msaArrData = yield readCsv(msaFilePath, pyodide, acceptedFilesObject, undefined, undefined, true);
        dispatchControlledNotification({
            idx: msaFileName,
            type: "info",
            message: `Loading`,
            fileName: msaFileName,
        });
        let msaRes = undefined;
        if (msaArrData && msaArrData.tableArr && msaArrData.tableArr.length > 0) {
            const { tableFileName, tableArr: msaArr, error: _error, tableDCPath, isRemote, } = msaArrData;
            error = _error;
            msaFileName = tableFileName;
            const loadedMSA = yield formatMSA(msaArr);
            if (loadedMSA) {
                msaRes = {
                    data: loadedMSA,
                    msaID: uuidv4(),
                    name: msaData.name ? msaData.name : tableFileName,
                    path: msaFileName,
                    localBasePath: localBasePath,
                    DCpath: isRemote ? tableDCPath : undefined,
                    // options: msaData.options,
                };
            }
        }
        let msaMapping;
        if (msaRes && msaData.mapping) {
            msaMapping = yield readMSAmapping(msaData.mapping, msaFilePath, vss, pyodide, acceptedFilesObject, localBasePath);
            msaRes.mapping = msaMapping;
        }
        if (!msaRes) {
            error = "Error loading the MSA.";
        }
        dispatchDeleteControlledNotification({ idx: msaFileName });
        if (error) {
            dispatchNotificationEvent({
                type: "error",
                message: error,
            });
        }
        return msaRes;
    });
}
export function loadMSAs(pyodide, vss, msas, NAPGenericStore, acceptedFilesObject, localBasePath) {
    var _a, msas_1, msas_1_1;
    var _b, e_1, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        let loadedMSAs = [];
        try {
            for (_a = true, msas_1 = __asyncValues(msas); msas_1_1 = yield msas_1.next(), _b = msas_1_1.done, !_b;) {
                _d = msas_1_1.value;
                _a = false;
                try {
                    let msaData = _d;
                    const msaConfig = yield loadMSA(msaData, pyodide, vss, acceptedFilesObject, localBasePath);
                    if (msaConfig)
                        loadedMSAs.push(msaConfig);
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = msas_1.return)) yield _c.call(msas_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (loadedMSAs.length > 0) {
            NAPMSAStore.getState().add_msas(loadedMSAs);
            // const activePanels = NAPGenericStore.getState()._activePanels;
            // NAPGenericStore.getState().setActivePanels({
            //   ...activePanels,
            //   sequence: true,
            // });
        }
    });
}
