var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useEffect, useState } from "react";
import { TextField } from "@mui/material";
export const TableTextField = forwardRef((_a, ref) => {
    var { value: initialValue, onChange } = _a, props = __rest(_a, ["value", "onChange"]);
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    useEffect(() => {
        onChange(value);
    }, [value]);
    return (_jsx(TextField, Object.assign({ inputRef: ref }, props, { sx: {
            "& .MuiInputBase-root": { padding: 0 },
        }, onClick: (e) => e.stopPropagation(), value: value, onChange: (e) => setValue(e.target.value), size: "small", 
        // fullWidth
        inputProps: {
            style: {
                fontSize: "0.8rem",
                padding: 5,
            },
        } })));
});
