import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Grid } from "@mui/material";
import { HideColumnsMenu } from "../TableButtons/ColumnFilter";
import { DeleteTable } from "../TableButtons/DeleteTable";
import { ToggleNavigationMode } from "../TableButtons/ToggleNavigationMode";
import { DownloadTableOptions, } from "../TableButtons/DownloadTableOptions";
export function TableButtons({ table, ignoredColumns, navigationMode, handleToggleNavigationMode, handleDownloadTable, onDelete, }) {
    return (_jsxs(Grid, Object.assign({ item: true, container: true, xs: "auto", alignItems: "center", sx: { order: 3 } }, { children: [!!handleToggleNavigationMode && (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { width: "32px" } }, { children: _jsx(ToggleNavigationMode, { handleClick: handleToggleNavigationMode, isActive: navigationMode }) })) }))), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { width: "32px" } }, { children: _jsx(HideColumnsMenu, { table: table, ignoredColumns: ignoredColumns }) })) })), handleDownloadTable ? (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(DownloadTableOptions, { downloadTable: handleDownloadTable }) }))) : null, onDelete ? (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { width: "32px" } }, { children: _jsx(DeleteTable, { onDelete: onDelete }) })) }))) : null] })));
}
