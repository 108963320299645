import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useLocation } from "wouter";
import { shallow } from "zustand/shallow";
import { ListItem, ListItemIcon, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Panels } from "../GenericViewerState/SharedState";
import { TooltipControlled } from "../utils/TooltipControlled";
export function DocumentationButton({ NAPGenericStore, }) {
    const [location, setLocation] = useLocation();
    const panelOpen = location.startsWith("/docs");
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    const togglePanel = () => {
        if (panelOpen)
            setLocation("/"); //i.e. close
        else {
            setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: false, [Panels.sequence]: false, [Panels.plots]: false, [Panels.msa]: false }));
            setLocation("/docs/load");
        }
    };
    return (_jsx(TooltipControlled, Object.assign({ enterDelay: 200, title: "Documentation", arrow: true, placement: "right" }, { children: _jsx(ListItem, Object.assign({ onClick: togglePanel, sx: { cursor: "pointer" } }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: panelOpen ? "secondary.main" : "secondary.light" } }, { children: _jsx(InfoIcon, {}) })) }) })) }), "pm-sidebar-docs-tooltip"));
}
