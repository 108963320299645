var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { memo, useCallback, useEffect, useMemo } from "react";
import { shallow } from "zustand/shallow";
import { Grid } from "@mui/material";
import { Panels } from "../GenericViewerState/SharedState";
import { NAPTableStore } from "../StateStores/tables.store";
import { DataTable } from "./DataTable";
import { hideSystemsFromTable, unArchiveTableSystems } from "./utils";
import { clearHighlight, highlightStructure, } from "../utils/HighlightStructure";
import { DatavizControlOptions } from "../DataViz";
import { SelectTable } from "./SelectTable";
import { useLocation } from "wouter";
import { onRowClick } from "./onRowClick";
import { getToggleVizColnames } from "./findActiveRows";
import { dispatchConfirmationDialogEvent } from "../utils";
export const CSVtables = function (args) {
    const [tables] = NAPTableStore((state) => [state.tables], shallow);
    const activeTable = tables[args.activeTableIdx];
    const tableID = activeTable === null || activeTable === void 0 ? void 0 : activeTable.tableID;
    return _jsx(_CSVtables, Object.assign({}, args, { tableID: tableID }));
};
function propsComparison(prevProps, nextProps) {
    const diffIdx = prevProps.activeTableIdx !== nextProps.activeTableIdx;
    const diffTableId = prevProps.tableID !== nextProps.tableID;
    // const diffTableMaxHeight =
    //   prevProps.tableMaxHeight !== nextProps.tableMaxHeight;
    if (diffIdx || diffTableId) {
        return false;
    }
    return true;
}
const _CSVtables = memo(CSVtablesMemo, propsComparison);
export function CSVtablesMemo({ vss, molstar, pyodide, NAPGenericStore, activeTableIdx, skipButtons, fileSelectorAsTabs, tableID, }) {
    var _a;
    const [tables, del_table, set_activeTableIdx, navigationMode, toggleNavigationMode, setNavigationMode, setNavigationIdx, addColumn, updateTableColumnProperty, clearTableColumn,] = NAPTableStore((state) => [
        state.tables,
        state.del_table,
        state.set_activeTableIdx,
        state.navigationMode,
        state.toggleNavigationMode,
        state.setNavigationMode,
        state.setNavigationIdx,
        state.addColumn,
        state.updateTableColumnProperty,
        state.clearTableColumn,
    ], shallow);
    const activeTable = tables[activeTableIdx];
    const [setActivePanels, setHighligtedTableSystemRef] = NAPGenericStore((state) => [state.setActivePanels, state.setHighligtedTableSystemRef], shallow);
    useEffect(() => {
        const _cb = (e) => {
            const activePanels = NAPGenericStore.getState()._activePanels;
            setActivePanels(Object.assign(Object.assign({}, activePanels), { plots: true }));
        };
        window.addEventListener("PM-Loaded-Table", _cb);
        return () => {
            window.removeEventListener("PM-Loaded-Table", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const totalNumTables = tables.length;
    const activeTableId = activeTable === null || activeTable === void 0 ? void 0 : activeTable.tableID;
    useEffect(() => {
        setNavigationMode(false);
        unArchiveTableSystems(vss);
        setNavigationIdx(0);
    }, [totalNumTables, activeTableIdx, activeTableId]);
    const closePanel = () => {
        const activePanels = NAPGenericStore.getState()._activePanels;
        setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: false }));
    };
    const skipDelete = skipButtons
        ? skipButtons.includes(DatavizControlOptions.delete)
        : false;
    const onDelete = skipDelete
        ? undefined
        : () => {
            del_table(activeTableIdx, closePanel);
        };
    const rowActions = (_a = activeTable === null || activeTable === void 0 ? void 0 : activeTable.options) === null || _a === void 0 ? void 0 : _a.onRowClick;
    const [loadingDCFile] = NAPGenericStore((state) => [state.loadingDCFile], shallow);
    const [, setLocation] = useLocation();
    const openSystemsPanel = () => {
        const activePanels = NAPGenericStore.getState()._activePanels;
        NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { statetree: true, apps: false }));
        setLocation("/");
    };
    const _onRowClick = useCallback((row, rowIsActive) => __awaiter(this, void 0, void 0, function* () {
        yield onRowClick(row, rowIsActive, activeTable, activeTableIdx, navigationMode, vss, molstar, pyodide, openSystemsPanel);
    }), [activeTableId, navigationMode]);
    const onMouseEnter = (row) => {
        const cellRefs = row.original.cellRefs;
        if (!cellRefs)
            return;
        highlightStructure(molstar, cellRefs);
        setHighligtedTableSystemRef(cellRefs);
    };
    const onMouseLeave = () => {
        clearHighlight(molstar);
        setHighligtedTableSystemRef([]);
    };
    const numTables = tables.filter((t) => !t.archived).length;
    const isWaiting = rowActions && loadingDCFile;
    const rowsAreClickable = (rowActions && rowActions.length > 0) ||
        (activeTable === null || activeTable === void 0 ? void 0 : activeTable.columns.some((col) => typeof col.Header === "string" && col.Header.toLowerCase() === "path"));
    const toggleVizColnames = useMemo(() => getToggleVizColnames(rowActions, activeTable === null || activeTable === void 0 ? void 0 : activeTable.columns.map((c) => c.header)), [tableID]);
    const handleToggleNavigationMode = toggleVizColnames.length > 0
        ? () => {
            unArchiveTableSystems(vss);
            toggleNavigationMode();
        }
        : undefined;
    const handleClickTableNavigation = (row) => __awaiter(this, void 0, void 0, function* () {
        hideSystemsFromTable(vss, molstar);
        yield _onRowClick(row, false);
    });
    const setColumnIsEditable = (colId, newVal, isInteractive) => __awaiter(this, void 0, void 0, function* () {
        let ok;
        if (isInteractive && newVal) {
            ok = yield dispatchConfirmationDialogEvent({
                message: "Editing this column may disable related interactive features. Are you sure you want to proceed?",
            });
        }
        else {
            ok = true;
        }
        if (ok) {
            updateTableColumnProperty(colId, "isComment", newVal);
        }
    });
    return numTables > 0 && activeTable !== undefined ? (_jsxs(_Fragment, { children: [numTables > 1 && (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { order: 2, mr: 1 } }, { children: _jsx(SelectTable, { handleChange: set_activeTableIdx, activeTableIdx: activeTableIdx, tables: tables, fileSelectorAsTabs: fileSelectorAsTabs }) }))), _jsx(DataTable, { tableRawData: activeTable, onDelete: onDelete, addTableColumn: addColumn, handleToggleNavigationMode: handleToggleNavigationMode, onRowClick: _onRowClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, isWaiting: isWaiting, rowsAreClickable: rowsAreClickable, activeTableIdx: activeTableIdx, navigationMode: navigationMode, handleClickTableNavigation: handleClickTableNavigation, setColumnIsEditable: setColumnIsEditable, clearTableColumn: clearTableColumn })] })) : (_jsx(_Fragment, { children: "No table data available!" }));
}
