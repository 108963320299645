// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
import { getFilesFullPath } from "./utils";
import { loadLocalFiles } from "./loadLocalFiles";
import { readConfigFile } from "./FileReaders/readFileWrappers";
import { checkForMissingFiles } from "./utils";
export function generateLoadingInstructions(confFile, acceptedFilesObject, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        let loadingInstructions;
        try {
            loadingInstructions = yield readConfigFile(confFile);
        }
        catch (error) {
            dispatchNotificationEvent({ message: error, type: "error" });
            return;
        }
        if (localBasePath) {
            getFilesFullPath(loadingInstructions, localBasePath);
            yield loadLocalFiles(loadingInstructions, acceptedFilesObject);
        }
        const missingFiles = checkForMissingFiles(loadingInstructions, acceptedFilesObject);
        if (missingFiles.length > 0) {
            const errorMsg = localBasePath
                ? "The following files are not found:"
                : "Please upload the session file together with the files referenced there. The following files are missing:";
            dispatchNotificationEvent({
                message: `${errorMsg} ${missingFiles.join(", ")}`,
                type: "error",
            });
            return;
        }
        return loadingInstructions;
    });
}
