import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SortableList } from "./SortableList";
import { useEffect, useMemo, useState } from "react";
import { NAPTableStore } from "../../StateStores";
export function ColumnOrderDialog({ open, onClose, table, }) {
    const tableColumns = table.getAllLeafColumns();
    const columnVisibility = table.getState().columnVisibility;
    const { defColOrder, ignoredColumns, configColumns } = useMemo(() => {
        const defColOrder = [];
        const ignoredColumns = [];
        const configColumns = [];
        tableColumns.forEach((c) => {
            const colId = c.id;
            if (c.columnDef["configColumn"]) {
                configColumns.push(colId);
            }
            else if (columnVisibility[colId] !== false) {
                defColOrder.push(colId);
            }
            else {
                ignoredColumns.push(colId);
            }
        });
        return { defColOrder, ignoredColumns, configColumns };
    }, [tableColumns, columnVisibility]);
    const [listOrder, setListOrder] = useState(defColOrder);
    useEffect(() => {
        setListOrder(defColOrder);
    }, [defColOrder]);
    const onAccept = (x) => {
        setListOrder(x);
    };
    const handleAcceptClick = () => {
        const newOrder = [...configColumns, ...listOrder, ...ignoredColumns];
        table.setColumnOrder(newOrder);
        NAPTableStore.getState().reorderColumns(newOrder);
        onClose();
    };
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: onClose, sx: { "& .MuiPaper-root": { p: 1 } } }, { children: [_jsxs(DialogTitle, Object.assign({ textAlign: "center" }, { children: ["Reorder Columns", _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: onClose, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] })), _jsxs(DialogContent, Object.assign({ sx: { py: 0 } }, { children: [_jsx(DialogContentText, Object.assign({ variant: "body2" }, { children: "Drag and drop the column headers to reorder them." })), _jsx(SortableList, { items: listOrder, setItems: onAccept })] })), _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: handleAcceptClick }, { children: "Confirm" })) }))] })));
}
