var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { selectSystemByIndexArray } from "../stateTree";
import urlJoin from "url-join";
function getChildPosition(system, positionInTree = []) {
    let currChildrenRefs = [];
    currChildrenRefs.push(positionInTree);
    if (system.children) {
        system.children.forEach((s, idx) => {
            const childPositionInTree = [...positionInTree, idx];
            const cr = getChildPosition(s, childPositionInTree);
            currChildrenRefs = currChildrenRefs.concat(cr);
        });
    }
    return currChildrenRefs;
}
export function unselectedNodesOnUncheck(vss, positionInTree) {
    // Remove clicked node + chilren from the array of checked elements
    // Obtain IDs for all system nodes under the selected one
    const tree = vss.getState().loaded_structures;
    const system = selectSystemByIndexArray(positionInTree, tree);
    if (!system)
        return [];
    const selectedNodes = getChildPosition(system, positionInTree);
    // Also remove any parent group
    for (let i = positionInTree.length - 1; i > 0; i--) {
        const parentID = positionInTree.slice(0, i);
        selectedNodes.push(parentID);
    }
    return selectedNodes;
}
export function selectedNodesOnClick(vss, positionInTree, allSelectedIDs) {
    var _a;
    // Add selected node + children to the array of checked elements
    // Obtain IDs for all system nodes under the selected one
    const tree = vss.getState().loaded_structures;
    const system = selectSystemByIndexArray(positionInTree, tree);
    if (!system)
        return [];
    const selectedNodesPos = getChildPosition(system, positionInTree);
    // If all children in a group are selected, select parent as well
    //addParentIfAllChildrenSelected()
    // find parent
    const parentPos = positionInTree.slice(0, -1);
    const parentSystem = selectSystemByIndexArray(parentPos, tree);
    if (!parentSystem)
        return selectedNodesPos;
    let allChecked = true;
    (_a = parentSystem.children) === null || _a === void 0 ? void 0 : _a.forEach((sibsyst, i) => {
        const siblingID = [...parentPos, i].join("-");
        if (siblingID === positionInTree.join("-"))
            return;
        if (!allSelectedIDs.includes(siblingID))
            allChecked = false;
    });
    if (allChecked)
        selectedNodesPos.push(parentPos);
    return selectedNodesPos;
}
export function getAllNodesPosition(vss) {
    const tree = vss.getState().loaded_structures;
    let childPosAll = [];
    tree.forEach((system, i) => {
        const childPos = getChildPosition(system, [i]);
        childPosAll = childPosAll.concat(childPos);
    });
    return childPosAll;
}
export var menuButtons;
(function (menuButtons) {
    menuButtons["createGroup"] = "createGroup";
    menuButtons["deleteSystems"] = "deleteSystems";
    menuButtons["toggleVisibility"] = "toggleVisibility";
    menuButtons["toggleSelectionMode"] = "toggleSelectionMode";
    menuButtons["cloneRepresentation"] = "cloneRepresentation";
})(menuButtons || (menuButtons = {}));
export function selectAllNodeCheckboxes(vss, setIsToggleAllChecked, setCheckedSystems) {
    setIsToggleAllChecked(true);
    const allSystemIDs = getAllNodesPosition(vss).map((e) => e.join("-"));
    setCheckedSystems(allSystemIDs);
}
export function unslectAllNodeCheckboxes(setIsToggleAllChecked, setCheckedSystems) {
    setCheckedSystems([]);
    setIsToggleAllChecked(false);
}
export function removeSystem(molstar, pyodide, vss, cellRefs, positionInTree) {
    cellRefs.forEach((ref) => __awaiter(this, void 0, void 0, function* () {
        yield PluginCommands.State.RemoveObject(molstar, {
            state: molstar.state.data,
            ref: ref,
        });
    }));
    const system = selectSystemByIndexArray(positionInTree, vss.getState().loaded_structures);
    if (!system)
        return;
    deleteMolkitMolecules(system, pyodide);
    vss.getState().remove_system_by_pos(positionInTree);
}
export function deleteMolkitMolecules(system, pyodide) {
    if (system.children) {
        system.children.forEach((childsyst) => deleteMolkitMolecules(childsyst, pyodide));
    }
    else {
        const molID = system.moleculeID;
        if (!molID)
            return;
        pyodide
            .RunPythonAsync({
            context: { molID },
            script: `
        from js import molID
        delete_molecule_data(molID)
        `,
        })
            .then(() => console.log(`deleted molecule with ID ${molID}`));
    }
}
export function removeRepresentation(vss, molstar, refRefLi, positionInTree, selectedRepID, updateStateVars) {
    return __awaiter(this, void 0, void 0, function* () {
        const _updateStateVars = updateStateVars === undefined ? true : updateStateVars;
        // I added updateStateVars so that, in selection changes, we can delete the old molstar cell but not delete elements from the state variables
        if (_updateStateVars) {
            vss.getState().remove_representation(positionInTree, selectedRepID);
        }
        for (let i = 0; i < refRefLi.length; i++) {
            const repRef = refRefLi[i];
            yield PluginCommands.State.RemoveObject(molstar, {
                state: molstar.state.data,
                ref: repRef,
            });
        }
    });
}
export function findTableColName(row, actionColumn) {
    return Object.keys(row.original).find((rowCol) => rowCol.toLowerCase() === actionColumn.toLowerCase());
}
export function getAbsPathFromTable(fileName, localBasePath) {
    let absPath;
    if (fileName.charAt(0) === "/") {
        absPath = fileName;
    }
    else {
        absPath = urlJoin(localBasePath, fileName);
    }
    return absPath;
}
