import { selectSystemByIndexArray } from "../../stateTree";
import { removeSystem } from "../utils";
export function deleteSelectedSystems(molstar, pyodide, vss, checkedSystemsNum, onDeleteCallback) {
    let _loaded_structures = vss.getState().loaded_structures;
    const sortedPositions = checkedSystemsNum
        .sort((a, b) => a - b)
        .reverse();
    for (const positionInTree of sortedPositions) {
        const syst = selectSystemByIndexArray(positionInTree, _loaded_structures);
        if (!syst)
            continue;
        let cellRefs = syst.cellRef;
        // Skip groups
        if (!cellRefs)
            continue;
        removeSystem(molstar, pyodide, vss, cellRefs, positionInTree);
        if (onDeleteCallback)
            onDeleteCallback(_loaded_structures, syst.files);
    }
}
