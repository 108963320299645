import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
import { IconButton } from "@mui/material";
import { TooltipControlled } from "../../../utils/TooltipControlled";
//
export function MeasurementButton({ name, children, callback, }) {
    return (_jsx(TooltipControlled, Object.assign({ title: name, arrow: true, enterDelay: 200, placement: "top" }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: callback }, { children: children })) })));
}
