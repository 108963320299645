// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { NAPGenericStore } from "../../GenericViewerState";
export function onStructureLoadCallback(loadInstructions, setLocation) {
    var _a;
    if (((_a = loadInstructions.systems) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        const activePanels = NAPGenericStore.getState()._activePanels;
        NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { statetree: true, apps: false }));
        setLocation("/");
    }
}
