// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function downloadObjectAsJson(exportObj, exportName) {
    const dataToDownload = "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(exportObj));
    const tempDOMnode = document.createElement("a");
    tempDOMnode.setAttribute("href", dataToDownload);
    tempDOMnode.setAttribute("download", exportName + ".pmv");
    document.body.appendChild(tempDOMnode); // required for firefox
    tempDOMnode.click();
    tempDOMnode.remove();
}
