// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function throttle(func, limit) {
    //Throttling ensures that a function is only executed at most once within a specified period (limit, in ms), regardless of how many times it’s triggered.
    let inThrottle;
    return function (...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}
