var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ListItemIcon, Box, ListItemButton, MenuItem, ListItemText, } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { TooltipControlled } from "../utils/TooltipControlled";
import { useState } from "react";
import { SidebarPopupMenu } from "./SidebarPopupMenu";
import { MenuInput } from "../utils/MenuInput";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
//@ts-ignore
import protein_icon from "../static/images/protein_icon.png";
import { dispatchTextInputDialogEvent } from "../UserInputDialogs/TextInputDialog";
function FileUploadButtonMain({ disabled, callback, }) {
    return (_jsx(TooltipControlled, Object.assign({ enterDelay: 200, title: "Files", arrow: true, placement: "right" }, { children: _jsx(ListItemButton, Object.assign({ onClick: callback, disabled: disabled }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(DriveFolderUploadIcon, {}) })) }) }), "save-current-nap-state") })));
}
function LoadFileMenuItem({ callback }) {
    return (_jsx(MenuInput, Object.assign({ handleChange: callback, multiple: true }, { children: _jsxs(MenuItem, Object.assign({ onClick: () => { }, dense: true }, { children: [_jsx(ListItemIcon, { children: _jsx(FolderOpenIcon, {}) }), _jsx(ListItemText, { children: "Open" })] }), "sidebar-load-files") }), "sidebar-load-files-wrap"));
}
function FromPDBMenuItem({ callback }) {
    const testString = (value) => {
        if (!(value.length === 4 && /^[a-zA-Z0-9]+$/.test(value))) {
            return {
                success: false,
                error: "The PDB ID should be  4 character long and alphanumeric.",
            };
        }
        return { success: true };
    };
    const handleClick = () => __awaiter(this, void 0, void 0, function* () {
        const pdbID = yield dispatchTextInputDialogEvent({
            title: "Get a PDB structure",
            text: "4 letter PDB identifier",
            testCallback: testString,
        });
        if (pdbID) {
            callback(pdbID);
        }
    });
    return (_jsxs(MenuItem, Object.assign({ onClick: handleClick, dense: true }, { children: [_jsx(ListItemIcon, { children: _jsx("img", { src: protein_icon, style: {
                        width: "20px",
                        height: "20px",
                    }, alt: "discord" }) }), _jsx(ListItemText, { children: "Get PDB" })] }), "sidebar-get-pdb"));
}
function FileUploadMenu({ handleClose, anchorEl, menuIsOpen, handleLoadFiles, handleLoadPDB, }) {
    return (_jsx(SidebarPopupMenu, Object.assign({ handleClose: handleClose, anchorEl: anchorEl, menuIsOpen: menuIsOpen }, { children: _jsxs("div", { children: [_jsx(LoadFileMenuItem, { callback: handleLoadFiles }), _jsx(FromPDBMenuItem, { callback: handleLoadPDB })] }) })));
}
export const FileUploadButtonMenu = ({ disabled, handleOpenFiles, handleLoadPDB, }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuIsOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FileUploadButtonMain, { disabled: disabled, callback: handleClick }), _jsx(FileUploadMenu, { handleClose: handleClose, anchorEl: anchorEl, menuIsOpen: menuIsOpen, handleLoadFiles: handleOpenFiles, handleLoadPDB: handleLoadPDB })] }));
};
export const FileUploadButton = ({ disabled, callback, }) => {
    return (_jsx(TooltipControlled, Object.assign({ enterDelay: 200, title: "Load files", arrow: true, placement: "right" }, { children: _jsxs(ListItemButton, Object.assign({ disabled: disabled, component: "label" }, { children: [_jsx("input", { style: { display: "none" }, multiple: true, type: "file", id: "multi-file-upload", onChange: (e) => callback(e) }), _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(DriveFolderUploadIcon, {}) })) })] })) })));
};
