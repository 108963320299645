var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Popover, } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { dispatchTextInputDialogEvent } from "../UserInputDialogs/TextInputDialog";
import { SaveSystemDialog } from "./DownloadSystem/SaveSystemDialog";
import { useState } from "react";
import CopyAllIcon from "@mui/icons-material/CopyAll";
export function MoreSystemOptionsPopover({ anchorEl, handleClosePopover, updateSystemName, downloadSystem, cloneRepresentations, numFrames, }) {
    const dialogOpen = Boolean(anchorEl);
    return (_jsx(Popover, Object.assign({ id: dialogOpen ? "system-extra-options-popover" : undefined, open: dialogOpen, anchorEl: anchorEl, onClose: handleClosePopover, anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "left",
        } }, { children: _jsx(MoreSystemOptionsMenu, { handleClosePopover: handleClosePopover, updateSystemName: updateSystemName, downloadSystem: downloadSystem, cloneRepresentations: cloneRepresentations, numFrames: numFrames }) })));
}
function MoreSystemOptionsMenu({ handleClosePopover, updateSystemName, downloadSystem, cloneRepresentations, numFrames, }) {
    const handleChangeSystemName = () => __awaiter(this, void 0, void 0, function* () {
        const name = yield dispatchTextInputDialogEvent({
            title: "System name",
            text: "",
        });
        if (name && updateSystemName) {
            updateSystemName(name);
        }
        handleClosePopover();
    });
    const handleCloneRepresentations = cloneRepresentations
        ? () => {
            cloneRepresentations();
            handleClosePopover();
        }
        : undefined;
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(MenuList, Object.assign({ dense: true }, { children: [!!updateSystemName && (_jsxs(MenuItem, Object.assign({ onClick: handleChangeSystemName }, { children: [_jsx(ListItemIcon, { children: _jsx(EditIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Rename" })] }))), !!downloadSystem && (_jsxs(MenuItem, Object.assign({ onClick: () => {
                            setOpenSaveDialog(true);
                        } }, { children: [_jsx(ListItemIcon, { children: _jsx(DownloadIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Download" })] }))), !!cloneRepresentations && (_jsxs(MenuItem, Object.assign({ onClick: handleCloneRepresentations }, { children: [_jsx(ListItemIcon, { children: _jsx(CopyAllIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Clone Representations" })] })))] })), !!downloadSystem && (_jsx(SaveSystemDialog, { isOpen: openSaveDialog, handleClose: () => {
                    setOpenSaveDialog(false);
                    handleClosePopover();
                }, handleSaveSystem: downloadSystem, numFrames: numFrames }))] }));
}
