import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FileType } from "../";
import SeqVirtualized from "./SequenceVirtualized";
import { Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { findSystemsByKey } from "../3dViewer/stateTree";
import { getStructureObjects } from "./utils";
import { SelectionStore } from "../StateStores/selection.store";
import { shallow } from "zustand/shallow";
export function SequencesPanel({ parentRef, molstar, loaded_structures, handleResize, }) {
    const protFileTypes = Object.values(FileType).filter((type) => type != "volume" && type != "sdf");
    const protSystems = useMemo(() => findSystemsByKey(loaded_structures, "type", protFileTypes).filter((system) => system.type != "volume" && system.type != "sdf"), [loaded_structures]);
    const protSystemObjs = useMemo(() => {
        const _protSystemObjs = [];
        protSystems.forEach((system) => {
            const cellRef = system.cellRef ? system.cellRef[0] : undefined;
            const { structureData, seqData } = getStructureObjects(cellRef, molstar);
            if (cellRef && system.visibility !== false) {
                _protSystemObjs.push({ structureData, seqData, system });
            }
        });
        return _protSystemObjs;
    }, [protSystems]);
    useEffect(() => {
        handleResize();
    }, [protSystems]);
    const [clickedSequence, setClickedSequence] = SelectionStore((state) => [state.clickedSequence, state.setClickedSequence], shallow);
    const clickedSeqRef = clickedSequence === null || clickedSequence === void 0 ? void 0 : clickedSequence.HTMLElement;
    useEffect(() => {
        const handleOutsiseMouseUp = (event) => {
            if (clickedSeqRef &&
                !clickedSeqRef.contains(event.target)) {
                // on mouseUp outide of the component, stop selection
                setClickedSequence(undefined);
            }
        };
        document.addEventListener("mouseup", handleOutsiseMouseUp);
        return () => {
            document.removeEventListener("mouseup", handleOutsiseMouseUp);
        };
    }, [clickedSeqRef]);
    return (_jsx(_Fragment, { children: protSystemObjs.length === 0 ? (_jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", color: "text.secondary", sx: { px: 1 } }, { children: "No proteins displayed." }))) : (_jsx(SeqVirtualized, { parentRef: parentRef, protSystemObjs: protSystemObjs, molstar: molstar })) }));
}
