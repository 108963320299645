var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { joinMeanAndStdColumns } from "./utils";
import Papa from "papaparse";
import { generateCellButton } from "./TableButtonCell";
import { prepareChunk } from "./prepareTableUtils";
export function parseCSVString(csvString, addColumnName, noHeader) {
    return new Promise((resolve, reject) => {
        const data = [];
        let chunkN = 0;
        Papa.parse(csvString, {
            header: !noHeader,
            dynamicTyping: true,
            delimiter: ",",
            quotechar: '"',
            chunk: function (result, handle) {
                if (result.data) {
                    const chunk = prepareChunk(result.data, chunkN, addColumnName);
                    chunkN += 1;
                    data.push(...chunk);
                }
            },
            complete: () => {
                resolve(data);
            },
            error: (error) => {
                reject(new Error("Error parsing CSV: " + error.message));
            },
        });
    });
}
export function parseCSVFile(tableFile, addColumnName, noHeader) {
    return new Promise((resolve, reject) => {
        const data = [];
        let chunkN = 0;
        Papa.parse(tableFile, {
            header: !noHeader,
            delimiter: ",",
            newline: "",
            dynamicTyping: true,
            preview: 0,
            encoding: "",
            // worker: true,
            comments: false,
            quotechar: '"',
            complete: () => {
                resolve(data);
            },
            error: function (e) {
                reject(new Error("Error parsing CSV: " + e.message));
            },
            skipEmptyLines: true,
            chunk: function (result, handle) {
                if (result.data) {
                    const chunk = prepareChunk(result.data, chunkN, addColumnName);
                    chunkN += 1;
                    data.push(...chunk);
                }
            },
            chunkSize: 1024 * 1024,
            fastMode: false,
        });
    });
}
function findInteractiveColumns(tableOptions) {
    // Get the column whose content is used for interactions (loading files,highligting selections, etc)
    const colNames = [];
    if (tableOptions.onRowClick) {
        tableOptions.onRowClick.forEach((rowAction) => {
            colNames.push(...rowAction.column);
            if (rowAction.options) {
                Object.entries(rowAction.options).forEach(([optionName, optionVals]) => {
                    if (optionName.toLowerCase().includes("column")) {
                        colNames.push(...optionVals);
                    }
                });
            }
        });
        if (tableOptions.rowButton) {
            colNames.push(tableOptions.rowButton.column);
        }
    }
    return colNames;
}
export function formatTable(tablearray, tableRowButton, localBasePath, tableOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        const exRow = tablearray[0];
        joinMeanAndStdColumns(tablearray);
        let interactiveCols = [];
        if (tableOptions) {
            interactiveCols = findInteractiveColumns(tableOptions);
        }
        const _predefSMILESColumns = (tableOptions === null || tableOptions === void 0 ? void 0 : tableOptions.SMILESColumns)
            ? tableOptions === null || tableOptions === void 0 ? void 0 : tableOptions.SMILESColumns
            : [];
        const smilesColumn = Object.keys(exRow).find((col) => col.toLowerCase() === "smiles");
        let svgColumns = smilesColumn
            ? [smilesColumn, ..._predefSMILESColumns]
            : _predefSMILESColumns;
        const columns = Object.keys(exRow).map((header) => {
            const isOriginalIdCol = header === "_pmv_originalId";
            return {
                accessorFn: (r) => r[header],
                header: header ? header : " ",
                minSize: 80,
                // cell: (c) => showInExponentialNotation(c.getValue()),
                enableColumnFilter: !svgColumns.includes(header),
                isNumber: typeof exRow[header] === "number",
                isComment: false,
                isSVG: svgColumns.includes(header),
                configColumn: isOriginalIdCol ? true : false,
                isInteractive: interactiveCols.some((col) => col.toLowerCase() === header.toLowerCase()),
            };
        });
        if (tableRowButton) {
            columns.unshift({
                accessorFn: (r) => "_action_button",
                header: " ",
                size: 30,
                cell: (c) => generateCellButton(c, tableRowButton.action, tableRowButton.column, tableRowButton.tooltip, localBasePath),
                enableColumnFilter: false,
                //@ts-ignore
                configColumn: true,
            });
        }
        const rows = tablearray;
        return { columns: columns, rows: rows, svgColumns: svgColumns };
    });
}
