var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Volume } from "molstar/lib/mol-model/volume";
import { createVolumeRepresentationParams } from "molstar/lib/mol-plugin-state/helpers/volume-representation-params";
import { StateObjectRef } from "molstar/lib/mol-state";
import { updateStateTreeVolume } from "../stateTree";
import { ControlledInput } from "../Controls/Slider/ControlledInput";
import { ControlledSlider } from "../Controls/Slider/ControlledSlider";
export function VolumeIsoControls({ vss, molstar, cellRef, visibility, minIsoValue, maxIsoValue, positionInTree, }) {
    var _a;
    const selectedStruct = StateObjectRef.resolveAndCheck(molstar === null || molstar === void 0 ? void 0 : molstar.state.data, cellRef);
    const isoValue = (_a = selectedStruct === null || selectedStruct === void 0 ? void 0 : selectedStruct.params) === null || _a === void 0 ? void 0 : _a.values.type.params.isoValue.absoluteValue;
    const [isUpdating, setUpdating] = useState(false);
    const [currIsoVal, setIsoVal] = useState(isoValue);
    const callback = (newIso) => __awaiter(this, void 0, void 0, function* () {
        if (!molstar || !selectedStruct)
            return;
        setUpdating(true);
        yield updateMolstar(molstar, selectedStruct, cellRef, newIso);
        setIsoVal(newIso);
        updateStateTreeVolume(vss, positionInTree, "currentIsoValue", newIso);
        setUpdating(false);
    });
    const controlParameters = {
        minVal: minIsoValue ? minIsoValue : 0,
        maxVal: maxIsoValue ? maxIsoValue : 0,
        step: 0.0005,
    };
    return (_jsx(Box, Object.assign({ sx: { padding: "5px 11px" } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 1, alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", sx: { pr: 1, color: visibility ? "black" : "#808080" } }, { children: "IsoValue" })) })), _jsx(Grid, Object.assign({ item: true, xs: true, sx: { minWidth: "5em" } }, { children: _jsx(ControlledSlider, Object.assign({}, controlParameters, { currVal: currIsoVal, disabled: isUpdating || !visibility, onUpdate: callback })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { width: "3.5em", display: "flex" } }, { children: _jsx(ControlledInput, Object.assign({}, controlParameters, { currVal: currIsoVal, disabled: isUpdating || !visibility, onUpdate: callback })) })) }))] })) })));
}
function updateMolstar(_molstar, selectedStruct, cellRef, newVal) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const volValues = (_a = selectedStruct.params) === null || _a === void 0 ? void 0 : _a.values;
        const visuals = volValues.type.params.visuals;
        const alpha = volValues.type.params.alpha;
        const color = volValues.colorTheme.params.value;
        const volumeData = (_b = selectedStruct.obj) === null || _b === void 0 ? void 0 : _b.data.sourceData;
        yield _molstar
            .build()
            .to(cellRef)
            .update(createVolumeRepresentationParams(_molstar, volumeData, {
            typeParams: {
                visuals: visuals,
                alpha: alpha,
                isoValue: Volume.adjustedIsoValue(volumeData, newVal, "absolute"),
            },
            color: "uniform",
            colorParams: { value: color },
        }))
            .commit();
    });
}
