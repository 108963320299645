import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Button, Typography, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { TooltipIfTruncated } from "../../../utils/TooltipIfTruncated";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
function cleanString(str) {
    return str.replace(/^(?:\.\.?\/?)/, "");
}
export function TreeNodeVisibilityButton({ onClick, name, active, isGroup, onMouseEnter, onMouseLeave, isHighlighted, notClickable, isSelection, groupIsExpanded, grouHandleClick, }) {
    const _name = cleanString(name);
    const textRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const handleTooltipClose = () => {
        setOpen(false);
    };
    return (_jsx(TooltipIfTruncated, Object.assign({ textRefCurrent: textRef.current, title: _name, open: open, arrow: true, placement: "top" }, { children: _jsx(Button, Object.assign({ disableRipple: notClickable, size: "small", onClick: notClickable ? undefined : onClick, sx: {
                minWidth: 0,
                width: "100%",
                height: "100%",
                cursor: notClickable ? "default" : "pointer",
                borderRadius: 1,
                transition: "background 0.5s, color 0.5s",
                backgroundColor: active ? "#d9d9d9" : "white",
                "& .MuiTypography-root": {
                    color: active ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.3)",
                },
                "& .MuiSvgIcon-root": {
                    color: active ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.3)",
                },
                "&:hover": {
                    backgroundColor: active
                        ? "rgba(0, 0, 0, 0.12)"
                        : "rgba(0, 0, 0, 0.04)",
                },
            }, onMouseEnter: () => {
                if (onMouseEnter && !notClickable)
                    onMouseEnter();
                handleTooltipOpen();
            }, onMouseLeave: () => {
                if (onMouseLeave && !notClickable)
                    onMouseLeave();
                handleTooltipClose();
            }, onTouchStart: () => {
                if (onMouseEnter && !notClickable)
                    onMouseEnter();
                handleTooltipOpen();
            }, onTouchEnd: () => {
                if (onMouseLeave && !notClickable)
                    onMouseLeave();
                handleTooltipClose();
            } }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center", wrap: "nowrap", spacing: 1 }, { children: [isGroup ? (_jsx(Grid, Object.assign({ item: true, display: "flex" }, { children: _jsx(IconButton, Object.assign({ "aria-label": "toggle-accordion", onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (grouHandleClick) {
                                    grouHandleClick();
                                }
                            }, size: "small", sx: { color: "primary.light", p: 0 } }, { children: groupIsExpanded === false ? (_jsx(ArrowRightIcon, { sx: { fontSize: "1.25rem", mb: "0.15rem" } })) : (_jsx(ArrowDropDownIcon, { sx: { fontSize: "1.25rem", mb: "0.15rem" } })) })) }))) : isSelection ? (_jsx(Grid, Object.assign({ item: true, display: "flex" }, { children: _jsx(HighlightAltIcon, { sx: { fontSize: "1.25rem", mb: "0.15rem" } }) }))) : null, _jsx(Grid, Object.assign({ item: true, zeroMinWidth: true }, { children: _jsx(Typography, Object.assign({ ref: textRef, noWrap: true, sx: {
                                textTransform: "none",
                                textAlign: "left",
                                width: "100%",
                                direction: "rtl",
                                whiteSpace: "nowrap",
                                fontWeight: isHighlighted ? "bold" : undefined,
                                "&:after": {
                                    position: "absolute",
                                },
                                fontSize: "0.95rem",
                            } }, { children: _name })) }))] })) })) })));
}
export default TreeNodeVisibilityButton;
