// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FileType, TrajectoryModeEnum, } from "../utils";
import { displayMultipleFrames } from "../3dViewer/TrajectoryControls/displayMultipleFrames";
import { getCurrentTrajMultipleFramesSettings, getTrajectoryRef, } from "../3dViewer/TrajectoryControls/utils";
export function loadTrajShowingMultipleFrames(cellRef, molstar, framesSettings) {
    return __awaiter(this, void 0, void 0, function* () {
        const trajectoryRef = getTrajectoryRef(molstar, cellRef);
        if (!trajectoryRef)
            return;
        const { from, to, step } = getCurrentTrajMultipleFramesSettings(framesSettings);
        const ensembleCellRefs = yield displayMultipleFrames(trajectoryRef, from, to, step, molstar);
        return ensembleCellRefs;
    });
}
export function setTrajectoryMode(fType, cellRef, molstar, mol, trajMode) {
    return __awaiter(this, void 0, void 0, function* () {
        if (fType !== FileType.trajectory)
            return { cellRef, trajMode: undefined };
        if ((trajMode === null || trajMode === void 0 ? void 0 : trajMode.name) !== TrajectoryModeEnum.multipleFrames &&
            (trajMode === null || trajMode === void 0 ? void 0 : trajMode.name) !== TrajectoryModeEnum.allFrames)
            return { cellRef, trajMode };
        let { from, to, step } = getCurrentTrajMultipleFramesSettings(trajMode === null || trajMode === void 0 ? void 0 : trajMode.options);
        if ((trajMode === null || trajMode === void 0 ? void 0 : trajMode.name) === TrajectoryModeEnum.allFrames) {
            from = 1;
            to = mol.numFrames;
        }
        let _cellRef = cellRef;
        const ensembleCellRefs = yield loadTrajShowingMultipleFrames(cellRef, molstar, { from, to, step });
        if (ensembleCellRefs) {
            _cellRef = ensembleCellRefs;
        }
        const newTrajMode = {
            name: TrajectoryModeEnum.multipleFrames,
            options: { from, to, step },
        };
        return { cellRef: _cellRef, trajMode: newTrajMode };
    });
}
