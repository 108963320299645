// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
function addColumn(data, columnName, pageId) {
    const _pageId = pageId ? pageId : 0;
    const dataWithIds = data.map((row, index) => (Object.assign(Object.assign({}, row), { [columnName]: _pageId + index })));
    return dataWithIds;
}
function transposeData(table) {
    const numColumns = table[0].length;
    // Create an array of empty arrays, one for each original column
    const transposedTable = Array.from({ length: numColumns }, () => []);
    // Iterate over each row in the original table
    table.forEach((row) => {
        // Iterate over each element in the row
        row.forEach((cell, i) => {
            // Push the cell value into the corresponding transposed row
            transposedTable[i].push(cell);
        });
    });
    return transposedTable;
}
export function prepareChunk(chunk, chunkN, addColumnName) {
    let dataWithIds;
    if (addColumnName) {
        dataWithIds = addColumn(chunk, addColumnName, chunkN);
    }
    else {
        dataWithIds = chunk;
    }
    return dataWithIds;
}
