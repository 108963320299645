// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ButtonsType, getButton, getButtons, getModifiers, } from "molstar/lib/mol-util/input/input-observer";
import { NAPMSAStore } from "../../StateStores";
import { handleClick, handleHighlight, handleHighlightThrottled, posIdxToLoci, selectPositionRange, } from "./msaSelection";
import { SelectionStore } from "../../StateStores/selection.store";
export const onMouseMove = (e, posIdx, structureData, molstar, mapping, mappedPositions) => {
    NAPMSAStore.getState().setHoveredMsaPos(posIdx);
    if (NAPMSAStore.getState().lastHoveredMsaPosInSelection === posIdx) {
        return;
    }
    else {
        if (!mapping)
            return;
        const buttons = getButtons(e.nativeEvent);
        const button = getButton(e.nativeEvent);
        const modifiers = getModifiers(e.nativeEvent);
        NAPMSAStore.getState().setLastHoveredMsaPosInSelection(posIdx);
        const loci = posIdxToLoci(posIdx, structureData, mapping);
        if (!loci)
            return;
        const mouseDownPosition = NAPMSAStore.getState().mouseDownPosition;
        if (mouseDownPosition === -1) {
            handleHighlightThrottled(loci, buttons, button, modifiers, molstar);
        }
        else {
            const viewerSelectionMode = SelectionStore.getState().viewerSelectionMode;
            if (viewerSelectionMode) {
                const cb = (loci) => {
                    molstar.managers.interactivity.lociHighlights.highlightOnly({
                        loci,
                    });
                };
                const lociRange = selectPositionRange(mouseDownPosition, posIdx, structureData, mapping, mappedPositions);
                if (lociRange)
                    cb(lociRange);
            }
            else {
                handleHighlight(loci, ButtonsType.Flag.None, ButtonsType.Flag.None, Object.assign(Object.assign({}, modifiers), { shift: true }), molstar);
            }
        }
    }
};
export const onMouseLeave = () => {
    NAPMSAStore.getState().setHoveredMsaPos(-1);
};
export const onMouseDown = (e, posIdx, structureData, molstar, mapping) => {
    if (mapping) {
        const loci = posIdxToLoci(posIdx, structureData, mapping);
        if (loci)
            handleClick(loci, e, molstar);
    }
    NAPMSAStore.getState().setMouseDownPosition(posIdx);
};
export const onMouseUp = (e, posIdx, structureData, molstar, mapping, mappedPositions) => {
    const mouseDownPosition = NAPMSAStore.getState().mouseDownPosition;
    if (mouseDownPosition === -1)
        return;
    if (!mapping)
        return;
    const lociRange = selectPositionRange(mouseDownPosition, posIdx, structureData, mapping, mappedPositions);
    if (lociRange)
        handleClick(lociRange, e, molstar);
    NAPMSAStore.getState().setMouseDownPosition(-1);
};
