var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { uuidv4 } from "../utils/_exports";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../NotificationSystem/utils";
import { createMolstarModels, createMolstarModelsMultipleMols, } from "../3dViewer/createMolstarModels";
import { addRepresentation } from "../3dViewer/showFile";
import { loadInMoleculeKit } from "./loadInMoleculeKit";
import { findSystemByKey } from "../3dViewer/stateTree";
import { renderVolume } from "../3dViewer/renderVolume";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { deleteMolkitMolecules } from "../3dViewer/Controls/utils";
import { setTrajectoryMode } from "./loadTrajShowingMultipleFrames";
import { getFileType } from "./utils";
import { FileType } from "../";
import { getActiveSystemEntries } from "../utils/IndexSelector/indexSelectorToIndexArray";
export function generateModelAndReps(vss, molstar, pyodide, system, topFileContent, trajFileContent, topFileName, trajFileName, 
/*
    If the ID of an existing system is passed, use it to replace its models
    and representations with the new ones generated here. Otherwise generate a
    new uuid and build a new system from scratch.
  */
systemID = uuidv4(), systemLabel, quiet, isInVFS) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        if (topFileContent === null)
            console.error("Topology file content is null!");
        // --------------------- REPLACE EXISTING SYSTEM -------------------------//
        const { loaded_structures } = vss.getState();
        const existingSystem = findSystemByKey(loaded_structures, "moleculeID", systemID);
        if (existingSystem) {
            // Updating an existing system (through ipython)
            deleteMolkitMolecules(existingSystem, pyodide);
            // Delete all the structures of this molecule from molstar
            if (existingSystem === null || existingSystem === void 0 ? void 0 : existingSystem.cellRef) {
                existingSystem.cellRef.forEach((ref) => __awaiter(this, void 0, void 0, function* () {
                    yield PluginCommands.State.RemoveObject(molstar, {
                        state: molstar.state.data,
                        ref: ref,
                    });
                }));
            }
            system = existingSystem;
        }
        // -----------------------------------------------------------------------//
        if (system.type === FileType.volume) {
            let _topFileContent;
            if (isInVFS) {
                _topFileContent = yield pyodide.FS.readFile(topFileContent, "utf8");
            }
            else {
                _topFileContent = topFileContent;
            }
            const volData = yield renderVolume(_topFileContent /* cubefile content is always string */, molstar, system.volumeRepresentation);
            const [volParams, volState] = volData;
            const prevVolumeParams = system.volumeRepresentation
                ? system.volumeRepresentation
                : {};
            const volumeParams = {
                cellRef: [volState.ref],
                volumeRepresentation: Object.assign(Object.assign({}, prevVolumeParams), { minIsoValue: volParams.minIsoVal, maxIsoValue: volParams.maxIsoVal }),
            };
            system.moleculeID = systemID;
            system.cellRef = volumeParams.cellRef;
            system.volumeRepresentation = volumeParams.volumeRepresentation;
            return;
        }
        const file_extension = topFileName.split(".").pop();
        const isSDF = file_extension === "sdf";
        try {
            const mols = yield loadInMoleculeKit(pyodide, systemID, topFileContent, topFileName, isSDF, trajFileContent, trajFileName, isInVFS);
            let singleResidue = new Set(mols[0].resname).size === 1;
            let ftype = getFileType(mols[0], singleResidue, system.type);
            system.cellRef = [];
            system.type = ftype;
            if (isSDF && mols.length > 1) {
                const cellRef = yield createMolstarModelsMultipleMols(molstar, mols, system.name, ftype, system.visibleElementIDs);
                system.cellRef = cellRef;
                system.visibleElementIDs = system.visibleElementIDs
                    ? system.visibleElementIDs
                    : "all";
                system.numEntries = mols.length;
            }
            else {
                const mol = mols[0];
                const cellRef = yield createMolstarModels(molstar, mol, system.name, ftype);
                const { cellRef: _cellRef, trajMode } = yield setTrajectoryMode(ftype, cellRef, molstar, mol, (_a = system.trajectoryParameters) === null || _a === void 0 ? void 0 : _a.mode);
                system.cellRef = _cellRef;
                if (system.trajectoryParameters) {
                    system.trajectoryParameters.mode = trajMode;
                }
            }
            system.moleculeID = systemID;
            if (systemLabel)
                system.name = systemLabel;
            const filesStr = system.files &&
                system.files.length > 1 &&
                system.files.join(", ") !== system.name
                ? `(${(_b = system.files) === null || _b === void 0 ? void 0 : _b.join(", ")})`
                : "";
            if (!quiet)
                dispatchControlledNotification({
                    idx: system.name,
                    type: "info",
                    message: `Creating representations...`,
                    fileName: `${system.name} ${filesStr}`,
                });
            system.aromaticBonds = mols[0].bondtype.includes("ar");
            const activeSystemEntries = getActiveSystemEntries(system);
            const addedReps = yield addRepresentation(molstar, pyodide, singleResidue, systemID, isSDF, isSDF ? undefined : mols[0], system.cellRef, system.visibility, system.representations, system.aromaticBonds, activeSystemEntries);
            if (!quiet)
                dispatchDeleteControlledNotification({ idx: system.name });
            if (addedReps) {
                system.representations = addedReps;
            }
            const currentSysTree = vss.getState().loaded_structures;
            if (existingSystem) {
                // TODO: swap the new system with its match in the state tree
                const newSysts = produce(currentSysTree, (draft) => {
                    let existingSystem = findSystemByKey(draft, "moleculeID", systemID);
                    existingSystem = system;
                });
                return newSysts;
            }
            return [...currentSysTree, system];
        }
        catch (error) {
            console.error(`loadInMoleculeKit failed for ${system.name}.`);
            console.error(error);
            dispatchNotificationEvent({
                message: `Error loading ${system.name}.`,
                type: "error",
            });
        }
    });
}
