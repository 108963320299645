var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { shallow } from "zustand/shallow";
import { SelectionStore } from "../../StateStores/selection.store";
import { Box, Collapse, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover, } from "@mui/material";
import { ActionIconButton, TreeNodeVisibilityButton, } from "../../components/Buttons";
import { Representation } from "molstar/lib/mol-repr/representation";
import { getButton, getButtons, getModifiers, } from "molstar/lib/mol-util/input/input-observer";
import BrushIcon from "@mui/icons-material/Brush";
import { useEffect, useState } from "react";
import { dispatchConfirmationDialogEvent } from "../../utils";
import { CreateRepresentationFromSelection } from "./CreateRepresentationFromSelection";
import { findSystemByKey } from "../stateTree";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { molstarSelectionsToSelectionStrings } from "./utils";
import { CopySelection } from "./CopySelection";
import { isEmptyLoci } from "molstar/lib/mol-model/loci";
import { updateMoleculeReps } from "../../PyodideTerminal/updateMoleculeReps";
function SelectionOptionsMenu({ onFocusClick, onCreateRepClick, onClearSelectionClick, onDeleteClick, createReprPanel, vss, pyodide, molstar, handleCloseParentPopover, }) {
    return (_jsxs(MenuList, Object.assign({ dense: true }, { children: [_jsxs(MenuItem, Object.assign({ onClick: onFocusClick }, { children: [_jsx(ListItemIcon, { children: _jsx(CenterFocusStrongIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Focus" })] })), _jsxs(MenuItem, Object.assign({ onClick: onCreateRepClick }, { children: [_jsx(ListItemIcon, { children: createReprPanel ? (_jsx(CloseIcon, { fontSize: "small" })) : (_jsx(BrushIcon, { fontSize: "small" })) }), _jsx(ListItemText, { children: "Create Representation" })] })), _jsx(CopySelection, { vss: vss, pyodide: pyodide, molstar: molstar, handleCloseParentPopover: handleCloseParentPopover }), _jsxs(MenuItem, Object.assign({ onClick: onDeleteClick }, { children: [_jsx(ListItemIcon, { children: _jsx(DeleteIcon, { fontSize: "small", sx: { color: "rgb(172, 0, 0)" } }) }), _jsx(ListItemText, { children: "Remove" })] }))] })));
}
export function SelectionOptions({ molstar, vss, pyodide, }) {
    const [createReprPanel, setCreateReprPanel] = useState(false);
    const [selectedResidues, setSelectedResidues] = SelectionStore((state) => [state.selectedResidues, state.setSelectedResidues], shallow);
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const dialogOpen = Boolean(anchorEl);
    useEffect(() => {
        setCreateReprPanel(false);
    }, [selectedResidues]);
    useEffect(() => {
        if (!molstar)
            return;
        if (loaded_structures.length === 0 &&
            Object.keys(selectedResidues).length > 0) {
            setSelectedResidues({});
            return;
        }
        // Delete selections that are not associated to a system (because it has been deleted)
        const newSelectedResidues = {};
        let update = false;
        for (let cellRef in selectedResidues) {
            const syst = findSystemByKey(loaded_structures, "cellRef", [cellRef], true);
            if (syst) {
                newSelectedResidues[cellRef] = selectedResidues[cellRef];
            }
            else {
                update = true;
            }
        }
        if (update)
            setSelectedResidues(newSelectedResidues);
    }, [loaded_structures]);
    const onFocusClick = () => {
        const lociAll = [];
        for (const [, selEntry] of molstar.managers.structure.selection.entries) {
            if (!selEntry.structure)
                continue;
            const loci = selEntry.selection;
            if (!loci || isEmptyLoci(loci))
                continue;
            lociAll.push(loci);
        }
        if (lociAll.length > 0)
            molstar.managers.camera.focusLoci(lociAll);
        handleClosePopover();
    };
    const onClearSelectionClick = (e) => {
        const buttons = getButtons(e.nativeEvent);
        const button = getButton(e.nativeEvent);
        const modifiers = getModifiers(e.nativeEvent);
        molstar.behaviors.interaction.click.next({
            current: Representation.Loci.Empty,
            buttons,
            button,
            modifiers,
        });
        handleClosePopover();
    };
    const onCreateRepClick = () => {
        setCreateReprPanel((prev) => !prev);
        handleClosePopover();
    };
    const deleteSelection = () => __awaiter(this, void 0, void 0, function* () {
        var _a, e_1, _b, _c;
        const molSelectedStrings = molstarSelectionsToSelectionStrings(molstar, vss);
        if (!molSelectedStrings)
            return;
        try {
            for (var _d = true, molSelectedStrings_1 = __asyncValues(molSelectedStrings), molSelectedStrings_1_1; molSelectedStrings_1_1 = yield molSelectedStrings_1.next(), _a = molSelectedStrings_1_1.done, !_a;) {
                _c = molSelectedStrings_1_1.value;
                _d = false;
                try {
                    const sel = _c;
                    const { molId: moleculeId, elements } = sel;
                    try {
                        yield pyodide.RunPythonAsync({
                            context: { moleculeId, elements },
                            script: `
            from js import moleculeId, elements
            remove_selection_from_system(moleculeId, elements)
            `,
                        });
                        const detectedChange = elements.some((e) => e.sdfIdx === undefined || e.sdfIdx === 0);
                        if (!detectedChange) {
                            // the molecule change tracker doesn't include small molecule libraries, we have to trigger the system update function manually
                            // Only the 1st molecule is detected by the molecule change tracker, so we need to manage the othes here
                            yield updateMoleculeReps(vss, molstar, pyodide, moleculeId);
                        }
                        handleClosePopover();
                        setSelectedResidues({});
                    }
                    catch (error) {
                        console.error(`Error deleting selection: ${error}`);
                    }
                }
                finally {
                    _d = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = molSelectedStrings_1.return)) yield _b.call(molSelectedStrings_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
    const onDeleteClick = () => __awaiter(this, void 0, void 0, function* () {
        const ok = yield dispatchConfirmationDialogEvent({
            message: "Are you sure you want to remove the selected atoms from all systems?",
        });
        if (ok)
            deleteSelection();
    });
    return Object.keys(selectedResidues).length > 0 ? (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, container: true, alignItems: "flex-start" }, { children: _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(Box, Object.assign({ sx: { width: "100%", px: 1, pl: 1 } }, { children: _jsxs(Paper, { children: [_jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(TreeNodeVisibilityButton, { onClick: () => { }, name: "Selection", active: true, notClickable: true, isSelection: true }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ onClick: handleOpenPopover, backgroundHoverColor: "#0b5394", iconHoverColor: "#ffab40", active: dialogOpen }, { children: _jsx(MoreVertIcon, {}) })) }))] })), _jsx(Collapse, Object.assign({ in: createReprPanel }, { children: _jsx(CreateRepresentationFromSelection, { closeMenu: () => {
                                            setCreateReprPanel(false);
                                        }, molstar: molstar, vss: vss, pyodide: pyodide }) }))] }) })) })) }), `selction-options`), _jsx(Popover, Object.assign({ id: dialogOpen ? "selection-options-popover" : undefined, open: dialogOpen, anchorEl: anchorEl, onClose: handleClosePopover, anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                } }, { children: _jsx(SelectionOptionsMenu, { onFocusClick: onFocusClick, onCreateRepClick: onCreateRepClick, onClearSelectionClick: onClearSelectionClick, createReprPanel: createReprPanel, onDeleteClick: onDeleteClick, vss: vss, pyodide: pyodide, molstar: molstar, handleCloseParentPopover: handleClosePopover }) }))] })) : (_jsx(_Fragment, {}));
}
