import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Collapse, Grid } from "@mui/material";
import { CreateGroupMenu } from "../Groups/CreateGroupMenu";
import { NAPTableStore } from "../../..";
import { menuButtons } from "../utils";
import { ActiveOptionsMenu } from "./ActiveOptionsMenu";
import { GroupButton, VisibilityButton, DeleteButton, SelectionModeButton, MeasureButton, } from "./SystemActionsMenuButtons";
import { shallow } from "zustand/shallow";
import { SelectionStore } from "../../../StateStores/selection.store";
import { SelectionOptionsMenu } from "../..//Selection/SelectionOptionsMenu";
import { MeasurementsMenu } from "../../Selection/Measurements/MeasurementsMenu";
import { CloneRepresentationsMenu } from "../CloneRepresentations/CloneRepresentationsMenu";
export function SystemActionsMenu({ vss, molstar, numSelectedSystems, activeButton, onCreateGroupClick, onCancelCreateGroupClick, onAcceptGroupClick, onGroupNameChange, groupName, onDeleteButtonClick, onCancelDeleteMultipleClick, onAcceptDeleteMultipleClick, onToggleVizButtonClick, onCancelToggleVizClick, onAcceptToggleVizClick, onAcceptCloneRepresentationsClick, onCancelCloneRepresentationsClick, disabledCheckboxSystems, }) {
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const [toggleViewerSelectionMode, viewerSelectionMode, resetSelectionState, toggleMeasureMode, measureMode, setMeasureMode,] = SelectionStore((state) => [
        state.toggleViewerSelectionMode,
        state.viewerSelectionMode,
        state.resetSelectionState,
        state.toggleMeasureMode,
        state.measureMode,
        state.setMeasureMode,
    ]);
    const [navigationMode] = NAPTableStore((state) => [state.navigationMode]);
    const showOptions = loaded_structures.length > 0;
    const buttonIconColor = "#a0cff8";
    const buttonIackgroundHoverColor = "auto";
    const buttonIconHoverColor = "#ffab40";
    const menuBackgroundColor = "rgb(153, 153, 153, 0.7)";
    return showOptions ? (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ sx: { pl: 1, pb: 1 } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 1, justifyContent: "flex-start", wrap: "nowrap", direction: "row" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(GroupButton, { tooltipMsg: "Group", onClick: onCreateGroupClick, disabled: activeButton && activeButton !== menuButtons.createGroup, active: activeButton === menuButtons.createGroup, iconColor: buttonIconColor, backgroundHoverColor: buttonIackgroundHoverColor, iconHoverColor: buttonIconHoverColor }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(VisibilityButton, { tooltipMsg: "Toggle visibility", onClick: onToggleVizButtonClick, visible: true, disabled: activeButton && activeButton !== menuButtons.toggleVisibility, active: activeButton === menuButtons.toggleVisibility, iconColor: buttonIconColor, backgroundHoverColor: buttonIackgroundHoverColor, iconHoverColor: buttonIconHoverColor }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(DeleteButton, { tooltipMsg: "Delete systems", onClick: onDeleteButtonClick, disabled: activeButton && activeButton !== menuButtons.deleteSystems, active: activeButton === menuButtons.deleteSystems, iconColor: buttonIconColor, backgroundHoverColor: buttonIackgroundHoverColor, iconHoverColor: "rgb(172, 0, 0)" }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(SelectionModeButton, { onClick: () => {
                                    if (viewerSelectionMode) {
                                        resetSelectionState(molstar);
                                        setMeasureMode(false);
                                    }
                                    else {
                                        molstar.managers.structure.focus.clear();
                                    }
                                    toggleViewerSelectionMode(molstar);
                                }, active: viewerSelectionMode, iconColor: buttonIconColor, backgroundHoverColor: buttonIackgroundHoverColor }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Collapse, Object.assign({ in: viewerSelectionMode, orientation: "horizontal", unmountOnExit: true }, { children: _jsx(MeasureButton, { onClick: () => {
                                        toggleMeasureMode();
                                    }, active: measureMode, iconColor: buttonIconColor, backgroundHoverColor: buttonIackgroundHoverColor, iconHoverColor: buttonIconHoverColor }) })) }))] })), _jsxs(Box, Object.assign({ sx: { mr: 1 } }, { children: [_jsx(CreateGroupMenu, { onCancelCreateGroupClick: onCancelCreateGroupClick, onAcceptGroupClick: onAcceptGroupClick, numSelectedSystems: numSelectedSystems, onGroupNameChange: onGroupNameChange, isCreateGroupActive: activeButton === menuButtons.createGroup, groupName: groupName, backgroundColor: menuBackgroundColor }), _jsx(ActiveOptionsMenu, { name: "Delete systems", numSelectedSystems: numSelectedSystems, isActive: activeButton === menuButtons.deleteSystems, onAccept: onAcceptDeleteMultipleClick, onCancel: onCancelDeleteMultipleClick, backgroundColor: menuBackgroundColor }), _jsx(ActiveOptionsMenu, { name: "Toggle visibility", numSelectedSystems: numSelectedSystems, isActive: activeButton === menuButtons.toggleVisibility, onAccept: onAcceptToggleVizClick, onCancel: onCancelToggleVizClick, backgroundColor: menuBackgroundColor }), _jsx(CloneRepresentationsMenu, { numSelectedSystems: numSelectedSystems, isActive: activeButton === menuButtons.cloneRepresentation, onAccept: onAcceptCloneRepresentationsClick, onCancel: onCancelCloneRepresentationsClick, backgroundColor: menuBackgroundColor, disabledCheckboxSystems: disabledCheckboxSystems, loaded_structures: loaded_structures }), _jsx(SelectionOptionsMenu, { isActive: viewerSelectionMode && !navigationMode, molstar: molstar, backgroundColor: menuBackgroundColor }), _jsx(MeasurementsMenu, { isActive: measureMode, molstar: molstar, backgroundColor: menuBackgroundColor })] }))] })) })) : null;
}
