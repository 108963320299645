import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MSAPositionsName from "./MSAPositionsName";
import { Box } from "@mui/material";
import MSAPosition from "./MSAPosition";
export function MSAPositions({ msaRef, systNameSpace, mapping, }) {
    const interval = 50;
    return (_jsxs(_Fragment, { children: [_jsx(MSAPositionsName, { mappingAvailable: !!mapping }), _jsx(Box, Object.assign({ display: "flex", flexDirection: "row", sx: {
                    position: "sticky",
                    top: 0,
                    pl: `calc(${systNameSpace} + 0.5rem)`,
                    height: mapping ? "30px" : "15px",
                    zIndex: 4,
                } }, { children: msaRef.map((e, i) => {
                    const intervalPosShown = i % interval === 0;
                    let resid;
                    if (mapping && mapping[i]) {
                        resid = `${mapping[i].resid}${mapping[i].chainid}`;
                    }
                    return (_jsx(MSAPosition, { idx: i, intervalPosShown: intervalPosShown, resid: resid, mappingAvailable: !!mapping }, `seq-position-${i}`));
                }) }))] }));
}
