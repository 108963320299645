import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import * as echarts from "echarts";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useInterval } from "../utils/_exports";
import { rightPanel } from "../UIPanels/panelDimensions";
import { restoreChartPosition, suppressSelection } from "./utils";
import { useWindowSize } from "./DataVizPlots/useWindowSize";
import { shallow } from "zustand/shallow";
export function EchartsComp({ graphOptions, onClickNode, onMouseOverNode, onClickLegend, onSystemVizChange, NAPGenericStore, height, }) {
    const chartContainer = useRef(null);
    const chartElement = chartContainer.current;
    let chartHandler = chartElement
        ? echarts.getInstanceByDom(chartElement)
        : null;
    const size = useWindowSize();
    // Check when the plot is ready
    const [plotIsReady, setPlotIsReady] = useState(false);
    const [plotInterval, setInterval] = useState(1000);
    useInterval(() => {
        chartHandler = chartElement ? echarts.getInstanceByDom(chartElement) : null;
        if (chartHandler) {
            setInterval(null);
            setPlotIsReady(true);
        }
    }, plotInterval);
    useEffect(() => {
        if (chartHandler) {
            setInterval(100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphOptions]);
    // When the plot is ready, add onClick function
    useEffect(() => {
        if (!chartHandler)
            return;
        if (onClickNode) {
            /* on select action */
            chartHandler.off("click");
            chartHandler.on("click", (params) => {
                onClickNode(params, chartHandler);
            });
        }
        if (onClickLegend) {
            chartHandler.off("legendselectchanged");
            chartHandler.on("legendselectchanged", function (params) {
                if (!chartHandler)
                    return;
                suppressSelection(chartHandler, params);
                onClickLegend(params);
            });
        }
        if (onMouseOverNode) {
            /* on select action */
            chartHandler.off("mouseover");
            chartHandler.on("mouseover", (params) => {
                onMouseOverNode(params, chartHandler);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphOptions, chartHandler, plotIsReady]);
    useEffect(() => {
        const targetNode = document.body;
        const config = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver((mutationList) => callback(mutationList, observer, graphOptions));
        observer.observe(targetNode, config);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphOptions]);
    const [dataVizPanelWidth] = NAPGenericStore((state) => [state.dataVizPanelWidth], shallow);
    useEffect(() => chartHandler === null || chartHandler === void 0 ? void 0 : chartHandler.resize(), [chartHandler, dataVizPanelWidth, size]);
    // capture system visibility changes
    useEffect(() => {
        if (!chartHandler)
            return;
        if (!onSystemVizChange)
            return;
        const _cb = (e) => {
            const { system, visibility } = e.detail;
            onSystemVizChange(chartHandler, system, visibility);
        };
        window.addEventListener("PM-VisibilityChange", _cb);
        return () => {
            window.removeEventListener("PM-VisibilityChange", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartHandler]);
    useEffect(() => {
        if (!chartHandler)
            return;
        const _cb = (e) => {
            if (!chartHandler)
                return;
            restoreChartPosition(chartHandler);
        };
        window.addEventListener("PM-Echarts-ResetPosition", _cb);
        return () => {
            window.removeEventListener("PM-Echarts-ResetPosition", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartHandler]);
    return (_jsx(Box, Object.assign({ sx: { overflow: "auto", height: height } }, { children: _jsx("div", { id: "FEBgraph", style: {
                width: graphOptions.width ? graphOptions.width : dataVizPanelWidth,
                minHeight: graphOptions.height
                    ? graphOptions.height
                    : rightPanel.height,
            }, ref: chartContainer }) })));
}
const callback = function (mutationsList, observer, graphOptions) {
    const DOMtarget = document.getElementById("FEBgraph");
    if (DOMtarget) {
        echarts.dispose(DOMtarget);
        const chartHandler = echarts.init(DOMtarget);
        chartHandler.setOption(graphOptions);
        //chartHandler.off("restore");
        chartHandler.on("restore", function () {
            chartHandler.setOption(graphOptions);
        });
        observer.disconnect();
    }
};
