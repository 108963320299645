import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { memo } from "react";
import { MSAPositions } from "./MSAPositions/MSAPositions";
import { MSAVirtualized } from "./MSAVirtualized";
import { StateObjectRef } from "molstar/lib/mol-state";
import { onMouseDown, onMouseLeave, onMouseMove, onMouseUp, } from "./utils/msaEvents";
import { diff } from "deep-object-diff";
const areMemoPropsEqual = (oldProps, newProps) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const msaIdEqual = ((_a = oldProps.activeMSA) === null || _a === void 0 ? void 0 : _a.msaID) === ((_b = newProps.activeMSA) === null || _b === void 0 ? void 0 : _b.msaID);
    const systRefEqual = ((_c = oldProps.refSystem) === null || _c === void 0 ? void 0 : _c.cellRef) === ((_d = newProps.refSystem) === null || _d === void 0 ? void 0 : _d.cellRef);
    const systNameEqual = ((_e = oldProps.refSystem) === null || _e === void 0 ? void 0 : _e.name) === ((_f = newProps.refSystem) === null || _f === void 0 ? void 0 : _f.name);
    const msaColorsOld = ((_g = oldProps.activeMSA) === null || _g === void 0 ? void 0 : _g.coloring)
        ? (_h = oldProps.activeMSA) === null || _h === void 0 ? void 0 : _h.coloring
        : [];
    const msaColorsNew = ((_j = newProps.activeMSA) === null || _j === void 0 ? void 0 : _j.coloring)
        ? (_k = newProps.activeMSA) === null || _k === void 0 ? void 0 : _k.coloring
        : [];
    const msaColorsDiff = diff(msaColorsOld, msaColorsNew);
    const msaColorsEqual = Object.keys(msaColorsDiff).length === 0;
    const parentRefEqual = oldProps.parentRefCurr === newProps.parentRefCurr;
    return (msaIdEqual &&
        systRefEqual &&
        systNameEqual &&
        msaColorsEqual &&
        parentRefEqual);
};
export const MSA = memo(MSAmemo, areMemoPropsEqual);
function MSAmemo({ activeMSA, parentRefCurr, refSystem, molstar, }) {
    var _a, _b, _c;
    const msaData = activeMSA.data;
    const msaSeqs = Object.values(msaData);
    const msaRef = msaSeqs[0];
    const systNameSpace = "130px";
    const cellRef = refSystem === null || refSystem === void 0 ? void 0 : refSystem.cellRef;
    const selectedStruct = cellRef
        ? StateObjectRef.resolveAndCheck(molstar.state.data, cellRef)
        : undefined;
    const structureData = (_a = selectedStruct === null || selectedStruct === void 0 ? void 0 : selectedStruct.obj) === null || _a === void 0 ? void 0 : _a.data;
    const refSystemData = Object.assign(Object.assign({}, refSystem), { structureData });
    const mapping = (_b = activeMSA.mapping) === null || _b === void 0 ? void 0 : _b.posToStruc;
    const mappedPositions = mapping &&
        Object.keys(mapping)
            .map((key) => Number(key))
            .sort((a, b) => a - b);
    const _onMouseMove = (e, posIdx) => onMouseMove(e, posIdx, structureData, molstar, mapping, mappedPositions);
    const _onMouseDown = (e, posIdx) => onMouseDown(e, posIdx, structureData, molstar, mapping);
    const _onMouseUp = (e, posIdx) => onMouseUp(e, posIdx, structureData, molstar, mapping, mappedPositions);
    return (_jsxs(_Fragment, { children: [_jsx(MSAPositions, { msaRef: msaRef, systNameSpace: systNameSpace, mapping: mapping }), _jsx(MSAVirtualized, { msa: activeMSA.data, parentRefCurr: parentRefCurr, systNameSpace: systNameSpace, refSystemData: refSystemData, molstar: molstar, mappingStrucToPos: (_c = activeMSA.mapping) === null || _c === void 0 ? void 0 : _c.strucToPos, mappedPositions: mappedPositions, onMouseMove: _onMouseMove, onMouseDown: _onMouseDown, onMouseUp: _onMouseUp, onMouseLeave: onMouseLeave, msaColoring: activeMSA.coloring })] }));
}
