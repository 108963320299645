import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Fade, Box, Grid } from "@mui/material";
import { NAPMSAStore } from "../../";
import { leftPanel } from "../panelDimensions";
import { useLocation } from "wouter";
import { useEffect, useMemo, useRef, useState } from "react";
import { Structure, StructureProperties, } from "molstar/lib/mol-model/structure";
import { MeasurementType, SelectionStore, } from "../../StateStores/selection.store";
import { diff } from "deep-object-diff";
import { measureAngle, measureDihedral, measureDistance, } from "../../3dViewer/Selection/Measurements/addMeasurements";
import { SeqOrMsaEum, SeqOrMsaSelector } from "../../MSA/SeqOrMsaSelector";
import { MSAPanel } from "../../MSA/MSAPanel";
import { SequencesPanel } from "../../Sequence/SequencesPanel";
import { dispatchMolstarCellChanged } from "../../MSA/MSAOptions/SyncColoroRef";
import { findSystemByKey } from "../../3dViewer/stateTree";
import { MSAOptions } from "../../MSA/MSAOptions/MSAOptions";
export const SequencesAndMSAPanel = ({ NAPGenericStore, vss, molstar, }) => {
    var _a;
    const ref = useRef(null);
    const [activePanels, setTopPanelHeight, dataVizPanelWidth] = NAPGenericStore((state) => [
        state._activePanels,
        state.setTopPanelHeight,
        state.dataVizPanelWidth,
    ], shallow);
    const show = activePanels.sequence;
    const [seqsOrMsa, setSeqsOrMsa] = useState(SeqOrMsaEum.seqs);
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const [msaArr, activeMSAIdx] = NAPMSAStore((state) => [state.msaArr, state.activeMSAIdx], shallow);
    const numMsa = msaArr.length;
    const previousNumMsa = useRef(numMsa);
    useEffect(() => {
        if (numMsa === 0) {
            setSeqsOrMsa(SeqOrMsaEum.seqs);
        }
        else if (previousNumMsa.current < numMsa) {
            // Open panel when a new MSA is added
            setSeqsOrMsa(SeqOrMsaEum.msa);
            const activePanels = NAPGenericStore.getState()._activePanels;
            NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { sequence: true }));
        }
        if (previousNumMsa.current !== numMsa) {
            previousNumMsa.current = numMsa;
        }
    }, [numMsa]);
    const activeMSA = msaArr[activeMSAIdx];
    const msaRefMolId = (_a = activeMSA === null || activeMSA === void 0 ? void 0 : activeMSA.mapping) === null || _a === void 0 ? void 0 : _a.refMolId;
    const displayMSAOpen = seqsOrMsa === SeqOrMsaEum.msa && show;
    const msaRefSystem = useMemo(() => {
        var _a;
        if (!((_a = activeMSA === null || activeMSA === void 0 ? void 0 : activeMSA.mapping) === null || _a === void 0 ? void 0 : _a.refMolId) || !displayMSAOpen)
            return;
        return findSystemByKey(loaded_structures, "moleculeID", activeMSA.mapping.refMolId);
    }, [loaded_structures, msaRefMolId, displayMSAOpen]);
    //----------------
    const [currLoc] = useLocation();
    const appsPanelOpen = activePanels.apps || currLoc.startsWith("/tools");
    const dataVizOpen = activePanels.plots;
    const dimensions = {
        // ...topPanel,
        left: (theme) => `calc(${appsPanelOpen ? leftPanel.width + " + " : ""}${theme.spacing(7)})`,
        width: (theme) => `calc(100vw - (${appsPanelOpen ? leftPanel.width + " + " : ""}${theme.spacing(7)}${dataVizOpen ? " + " + dataVizPanelWidth : ""}))`,
    };
    const handleResize = (show) => {
        if (ref.current) {
            const newSize = !!show ? ref.current.offsetHeight : 0;
            setTopPanelHeight(newSize);
        }
    };
    useEffect(() => {
        handleResize(show);
    }, [show, seqsOrMsa]);
    const handleResizeCompOnWindowResize = () => {
        handleResize(show);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResizeCompOnWindowResize);
        return () => window.removeEventListener("resize", handleResizeCompOnWindowResize);
    }, [show]);
    const [setSelectedResidues] = SelectionStore((state) => [state.setSelectedResidues], shallow);
    const updateSelectedResidues = () => {
        const newSelectedResidues = {};
        for (const [cellRef, { structure }] of molstar.managers.structure.selection
            .entries) {
            if (!structure)
                continue;
            if (!(cellRef in newSelectedResidues))
                newSelectedResidues[cellRef] = {};
            Structure.eachAtomicHierarchyElement(structure, {
                residue: (loc) => {
                    const position = StructureProperties.residue.label_seq_id(loc);
                    const chain = StructureProperties.chain.auth_asym_id(loc);
                    if (!(chain in newSelectedResidues[cellRef]))
                        newSelectedResidues[cellRef][chain] = [];
                    newSelectedResidues[cellRef][chain].push(position);
                },
            });
        }
        const selectionDiff = diff(SelectionStore.getState().selectedResidues, newSelectedResidues);
        if (Object.keys(selectionDiff).length > 0) {
            setSelectedResidues(newSelectedResidues);
        }
    };
    useEffect(() => {
        if (!molstar)
            return;
        molstar.behaviors.interaction.click.subscribe((event) => {
            const activeMeasurement = SelectionStore.getState().activeMeasurement;
            if (activeMeasurement === MeasurementType.distance) {
                // create distances based on selection
                measureDistance(molstar);
            }
            else if (activeMeasurement === MeasurementType.angle) {
                // create distances based on selection
                measureAngle(molstar);
            }
            else if (activeMeasurement === MeasurementType.dihedral) {
                // create distances based on selection
                measureDihedral(molstar);
            }
            // get molstar selections
            updateSelectedResidues();
        });
        return () => molstar.behaviors.interaction.click.unsubscribe();
    }, [molstar]);
    useEffect(() => {
        if (!molstar)
            return;
        const mosltarUpdate = molstar.state.data.events.cell.stateUpdated;
        mosltarUpdate.subscribe((event) => {
            dispatchMolstarCellChanged({ event });
        });
        return () => mosltarUpdate.unsubscribe();
    }, [molstar]);
    const msaparentRef = useRef(null);
    const seqParentRef = useRef(null);
    const displayProtSeqsd = seqsOrMsa === SeqOrMsaEum.seqs;
    const displayMSA = seqsOrMsa === SeqOrMsaEum.msa;
    return (_jsx(Fade, Object.assign({ in: show, style: { zIndex: 1 }, ref: ref, unmountOnExit: true, timeout: 0 }, { children: _jsx(Box, Object.assign({ id: "sequence-entrypoint", sx: Object.assign(Object.assign({}, dimensions), { backgroundColor: "white", position: "fixed", display: "block", top: 0 }) }, { children: _jsxs(Grid, Object.assign({ container: true, direction: displayProtSeqsd ? "column" : "row", justifyContent: "flex-start", alignItems: displayProtSeqsd ? "stretch" : "center" }, { children: [numMsa > 0 && (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(SeqOrMsaSelector, { seqsOrMsa: seqsOrMsa, setSeqsOrMsa: setSeqsOrMsa }) }))), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { maxHeight: "140px", overflowY: "auto" }, ref: seqParentRef }, { children: displayProtSeqsd && (_jsx(SequencesPanel, { parentRef: seqParentRef, loaded_structures: loaded_structures, molstar: molstar, handleResize: () => {
                                    handleResize(show);
                                } })) })) })), displayMSA && (_jsx(MSAOptions, { refSystem: msaRefSystem, molstar: molstar })), _jsx(Grid, Object.assign({ item: true, xs: 12, sx: {
                            width: "100%",
                            maxHeight: "140px",
                            overflowY: "auto",
                        }, ref: msaparentRef }, { children: displayMSAOpen && (_jsx(MSAPanel, { parentRef: msaparentRef, refSystem: msaRefSystem, molstar: molstar })) }))] })) })) })));
};
