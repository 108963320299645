import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Typography, Paper, List, ListItem, ListItemButton, Box, Button, Tooltip, ListSubheader, } from "@mui/material";
import { highlightStructure, clearHighlight, } from "../..//utils/HighlightStructure";
import { repTypeToLabel, repColorToLabel } from "./representationParamOptions";
import { shallow } from "zustand/shallow";
import { cameraFocus } from "../Controls/CameraControls/cameraFocus";
import AddCircle from "@mui/icons-material/AddCircle";
import { removeRepresentation } from "../Controls/utils";
import { RepresentationRowOptions } from "./RepresentationRowOptions";
import { selectSystemByIndexArray } from "../stateTree";
export function RepresentationListWithActions({ vss, molstar, selectedRepID, setSelectedRepID, handleToggleRepVisibility, NAPGenericStore, handleAddRepresentation, disabled, }) {
    var _a, _b;
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const activeSystem = activeSystemPosition
        ? selectSystemByIndexArray(activeSystemPosition, loaded_structures)
        : undefined;
    const numReps = ((_a = activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations) === null || _a === void 0 ? void 0 : _a.length) || 0;
    const selectedRep = numReps && (activeSystem === null || activeSystem === void 0 ? void 0 : activeSystem.representations)
        ? activeSystem.representations[selectedRepID]
        : undefined;
    const selectedRepRefs = (_b = selectedRep === null || selectedRep === void 0 ? void 0 : selectedRep.refs) === null || _b === void 0 ? void 0 : _b.map((r) => r.repRef);
    const _selectedRepRefs = selectedRepRefs
        ? selectedRepRefs.filter((e) => e !== undefined)
        : undefined;
    const handleRemoveRepClick = () => {
        if (!_selectedRepRefs)
            return;
        removeRepresentation(vss, molstar, _selectedRepRefs, activeSystemPosition, selectedRepID);
        setSelectedRepID(0);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Paper, Object.assign({ variant: "outlined", sx: { p: "0", width: "100%" } }, { children: _jsx(List, Object.assign({ dense: true, disablePadding: true, sx: { maxHeight: "125px", overflowY: "auto" } }, { children: activeSystem &&
                        activeSystem.representations &&
                        activeSystem.representations.length > 0 ? (activeSystem.representations.map((repParams, idx) => (_jsx(SystemRepresentationListRow, { molstar: molstar, repParams: repParams, handleSelectRep: () => setSelectedRepID(idx), handleToggleRepVisibility: handleToggleRepVisibility, isActive: idx === selectedRepID, disabled: !!disabled, idx: idx, handleFocus: () => {
                            if (_selectedRepRefs)
                                cameraFocus(molstar, _selectedRepRefs);
                        }, handleRemoveRep: handleRemoveRepClick }, `representation-row-${idx}`)))) : (_jsx(ListSubheader, Object.assign({ sx: { lineHeight: "30px" } }, { children: "No representations available" }))) })) })), _jsx(AddRepresentation, { handleAddRepresentation: handleAddRepresentation, disabled: disabled })] }));
}
function AddRepresentation({ handleAddRepresentation, disabled, }) {
    return (_jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: "Add Representation" }, { children: _jsx(Button, Object.assign({ variant: "outlined", size: "small", endIcon: _jsx(AddCircle, {}), disabled: disabled, onClick: handleAddRepresentation }, { children: "Add" })) })));
}
export function SystemRepresentationListRow({ molstar, repParams, handleSelectRep, handleToggleRepVisibility, handleRemoveRep, handleFocus, isActive, disabled, idx, }) {
    const visible = repParams.visibility === undefined ? true : repParams.visibility;
    const repRefs = repParams.refs
        ? repParams.refs.filter((e) => e.repRef !== undefined)
        : undefined;
    const repRef = repRefs && repRefs[0] ? repRefs[0].repRef : undefined;
    const handleMouseEnter = () => {
        if (!repRef)
            return;
        highlightStructure(molstar, [repRef]);
    };
    const handleMouseLeave = () => clearHighlight(molstar);
    return (_jsx(ListItem, Object.assign({ disablePadding: true }, { children: _jsx(ListItemButton, Object.assign({ disabled: disabled, disableTouchRipple: true, sx: { px: "12px", py: 0 }, selected: isActive, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onClick: (e) => {
                if (disabled || repRef === undefined)
                    return;
                e.detail > 1 ? handleToggleRepVisibility() : handleSelectRep();
            }, onTouchStart: handleMouseEnter, onTouchEnd: handleMouseLeave }, { children: _jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", sx: { width: "100%", height: "26px" }, gap: 1, alignItems: "center" }, { children: [_jsx(Box, Object.assign({ sx: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flexBasis: "20%",
                            flexShrink: 0,
                            flexGrow: 0,
                        } }, { children: _jsx(RepresentationRowText, { disabled: !visible || disabled, content: repTypeToLabel[repParams.type] }) })), _jsx(Box, Object.assign({ sx: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flexBasis: "20%",
                            flexShrink: 0,
                            flexGrow: 0,
                        } }, { children: _jsx(RepresentationRowText, { disabled: !visible || disabled, content: repColorToLabel[repParams.color] }) })), _jsx(Box, Object.assign({ sx: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }, flex: isActive ? 3 : 5 }, { children: _jsx(RepresentationRowText, { disabled: !visible || disabled, content: repParams.selection }) })), isActive && (_jsx(Box, Object.assign({ sx: {
                            whiteSpace: "nowrap",
                            overflow: "visible",
                            textOverflow: "ellipsis",
                        }, flex: 2 }, { children: _jsx(RepresentationRowOptions, { visible: visible, disabled: disabled, handleToggleRepVisibility: handleToggleRepVisibility, handleFocus: handleFocus, handleRemoveRep: handleRemoveRep }) })))] })) })) }), `repr-entry-${idx}`));
}
function RepresentationRowText({ disabled, content, }) {
    return (_jsx(Typography, Object.assign({ noWrap: true, sx: {
            textTransform: "none",
            textAlign: "left",
            width: "100%",
            fontSize: "0.75rem",
            userSelect: "none",
            color: disabled ? "#BDBDBD" : "auto",
        } }, { children: content })));
}
