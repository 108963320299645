import { jsx as _jsx } from "react/jsx-runtime";
import { trimText } from "../utils";
import { MSAPanelSelector } from "./MSAPanelSelector";
export function SelectMSA({ handleChange, activeMSAIdx, msaArr, }) {
    const onChange = (event) => {
        handleChange(Number(event.target.value));
    };
    const options = msaArr.map((msa, i) => ({
        label: trimText(msa.name, 50, true),
        value: i,
    }));
    return (_jsx(MSAPanelSelector, { activeValue: activeMSAIdx, onChange: onChange, options: options }));
}
