// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export function loadFileAsArrayBuffer(fileContent, systemName) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject(`File reading was aborted for ${systemName}`);
        reader.onerror = () => reject(`File reading has failed for ${systemName}`);
        reader.onload = () => {
            // Do whatever you want with the file contents
            const stringFileContent = reader.result;
            resolve(stringFileContent);
        };
        reader.readAsArrayBuffer(fileContent);
    });
}
