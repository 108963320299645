import { setVisibility } from "./VisibilityToggle";
export function truncateString(str, maxlen) {
    if (str.length > maxlen) {
        return `...${str.slice(-(maxlen - 3))}`;
    }
    return str;
}
export function hideNotVisibleRepresentations(system, molstar) {
    var _a;
    const hiddenReps = [];
    (_a = system.representations) === null || _a === void 0 ? void 0 : _a.forEach((rep) => {
        if (rep.visibility === false) {
            const repRefs = rep.refs ? rep.refs.map((ref) => ref.repRef) : [];
            const _repRefs = repRefs.filter((rep) => rep !== undefined);
            hiddenReps.push(..._repRefs);
        }
        // rep.visibility = newVal;
    });
    setVisibility(molstar, hiddenReps, false);
}
