// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { create } from "zustand";
import { NAPPlotStore } from "./plots.store";
function reconstructCSVCellVal(cellVal) {
    const _cellVal = `${cellVal}`;
    if ((_cellVal.startsWith('"') && _cellVal.endsWith('"')) ||
        (_cellVal.startsWith("'") && _cellVal.endsWith("'"))) {
        return _cellVal;
    }
    return _cellVal.includes(",") ? `"${_cellVal}"` : _cellVal;
}
export function reconstructCSV(columns, rows) {
    const filteredColumns = columns.filter((c) => !c.configColumn);
    const headers = filteredColumns.map((c) => { var _a; return (_a = c.header) === null || _a === void 0 ? void 0 : _a.toString(); }).join(",");
    const rowsStr = rows.map((r) => filteredColumns.map((col) => reconstructCSVCellVal(r[col.header])).join(","));
    return `${headers}\n${rowsStr.join("\n")}`;
}
export const NAPTableStore = create()((set, get) => ({
    tables: [],
    activeTableIdx: 0,
    navigationMode: false,
    navigationIdx: { currentIdx: 0, originalIdx: 0 },
    set_tables: (x) => set({ tables: x }),
    set_activeTableIdx: (x) => set({ activeTableIdx: x }),
    add_table: (x) => {
        set((state) => ({
            tables: [...state.tables, x],
            activeTableIdx: get().tables.length,
        }));
    },
    add_tables: (x) => {
        set((state) => ({
            tables: [...state.tables, ...x],
            activeTableIdx: get().tables.length,
        }));
    },
    del_table: (idx, cb) => set((state) => {
        const updated_tables = produce(state.tables, (draft) => {
            draft.splice(idx, 1);
        });
        if (updated_tables.length === 0 &&
            NAPPlotStore.getState().plots.length === 0)
            cb();
        return { tables: updated_tables, activeTableIdx: 0 };
    }),
    del_all_tables: () => set((state) => ({
        tables: [],
        activeTableIdx: 0,
    })),
    toggleNavigationMode: () => {
        set({ navigationMode: !get().navigationMode });
    },
    setNavigationMode: (x) => set({ navigationMode: x }),
    setNavigationIdx: (currentIdx, originalIdx) => {
        set({
            navigationIdx: {
                currentIdx: currentIdx,
                originalIdx: originalIdx !== undefined ? originalIdx : currentIdx,
            },
        });
    },
    addColumn: (name, refColId, addToRight) => set((state) => {
        const updated_tables = produce(state.tables, (draft) => {
            const table = draft[state.activeTableIdx];
            if (table) {
                const refColIdx = table.columns.findIndex((col) => col.header === refColId);
                const addIdx = addToRight ? refColIdx + 1 : refColIdx;
                const header = name;
                const newColumn = {
                    accessorFn: (r) => r[header],
                    header: header ? header : " ",
                    minSize: 80,
                    // cell: (c) => showInExponentialNotation(c.getValue()),
                    enableColumnFilter: true,
                    isNumber: false,
                    isComment: true,
                };
                table.columns.splice(addIdx, 0, newColumn);
                table.rows.forEach((row) => {
                    row[header] = "";
                });
            }
        });
        return { tables: updated_tables };
    }),
    updateTableCell: (tableId, rowIdx, columnId, value) => set((state) => {
        const updated_tables = produce(state.tables, (draft) => {
            const table = draft[tableId];
            if (table) {
                if (rowIdx < table.rows.length) {
                    const row = table.rows[rowIdx];
                    if (columnId in row) {
                        row[columnId] = value;
                    }
                }
            }
        });
        return { tables: updated_tables };
    }),
    reorderColumns: (columnList) => set((state) => {
        const updated_tables = produce(state.tables, (draft) => {
            const table = draft[state.activeTableIdx];
            if (table) {
                const originalColumns = table.columns;
                // Obtain table columns corresponsinf to columnList
                const reorderedCols = columnList
                    .map((id) => originalColumns.find((item) => item.header === id))
                    .filter(Boolean);
                // Find columns that were not in columnList
                const columnsNotReordered = originalColumns.filter((item) => !columnList.includes(item.header));
                // Combine them
                const finalColumns = [...reorderedCols, ...columnsNotReordered];
                table.columns = finalColumns;
            }
        });
        return { tables: updated_tables };
    }),
    updateTableColumnProperty: (colId, propName, newVal) => set((state) => {
        const updated_tables = produce(state.tables, (draft) => {
            const table = draft[state.activeTableIdx];
            if (table) {
                const colIdx = table.columns.findIndex((col) => col.header === colId);
                const col = table.columns[colIdx];
                col[propName] = newVal;
            }
        });
        return { tables: updated_tables };
    }),
    clearTableColumn: (colId) => set((state) => {
        const updated_tables = produce(state.tables, (draft) => {
            const table = draft[state.activeTableIdx];
            if (!table)
                return;
            const colIdx = table.columns.findIndex((col) => col.header === colId);
            const col = table.columns[colIdx];
            table.rows.forEach((row) => {
                row[col.header] = "";
            });
        });
        return { tables: updated_tables };
    }),
}));
