var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { sleep } from "../utils/sleep";
import { NAPPlotStore, PlotType } from "../StateStores/plots.store";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, } from "../NotificationSystem/utils";
import { Buffer } from "buffer";
import { getRawFileContent } from "../apps/results/fetchDcData";
import { loadFileAsArrayBuffer } from "./FileReaders/loadFileAsArrayBuffer";
import { loadFileAsText } from "./FileReaders/loadFileAsText";
function loadPlot(pyodide, acceptedFilesObject, filePath, plotType, plotOptions, localBasePath, quiet, archivedOnLoad) {
    return __awaiter(this, void 0, void 0, function* () {
        const isRemote = filePath.startsWith("dc://");
        let plotName;
        let fileContent;
        plotName = filePath.slice(filePath.lastIndexOf("=") + 1);
        let plotDCPath;
        if (!quiet)
            dispatchControlledNotification({
                idx: plotName,
                type: "info",
                message: `Loading...`,
                fileName: plotName,
            });
        if (isRemote) {
            if (plotName.endsWith(".png")) {
                const plotData = yield getRawFileContent(filePath, pyodide, true);
                if (typeof plotData === "string") {
                    try {
                        fileContent = JSON.parse(plotData).data;
                    }
                    catch (e) {
                        fileContent = undefined;
                        console.warn(e);
                    }
                }
                else {
                    const buffer = plotData ? Buffer.from(plotData, "binary") : undefined;
                    fileContent = buffer ? buffer.toString("base64") : undefined;
                }
            }
            else {
                fileContent = yield getRawFileContent(filePath, pyodide);
            }
            plotDCPath = filePath.slice(0, filePath.indexOf("?"));
            yield sleep(2000);
        }
        else if (filePath in acceptedFilesObject) {
            const loadedFile = acceptedFilesObject[filePath];
            if (loadedFile["isLoaded"]) {
                fileContent = loadedFile["data"];
            }
            else {
                if (filePath.endsWith(".png")) {
                    const binContent = yield loadFileAsArrayBuffer(loadedFile, filePath);
                    const buffer = binContent
                        ? //@ts-ignore
                            Buffer.from(binContent, "binary")
                        : undefined;
                    fileContent = buffer ? buffer.toString("base64") : undefined;
                }
                else {
                    fileContent = yield loadFileAsText(loadedFile, filePath);
                }
            }
        }
        if (fileContent && plotType === PlotType.interactive) {
            try {
                fileContent = JSON.parse(fileContent);
            }
            catch (e) {
                fileContent = undefined;
                console.warn(e);
            }
        }
        if (!quiet)
            dispatchDeleteControlledNotification({ idx: plotName });
        if (!fileContent) {
            return;
        }
        NAPPlotStore.getState().add_plot({
            name: plotName ? plotName : filePath,
            contents: fileContent,
            options: plotOptions,
            type: plotType,
            localBasePath: localBasePath,
            archived: archivedOnLoad,
            DCpath: isRemote ? plotDCPath : undefined,
        });
        return true;
    });
}
export function loadPlots(pyodide, acceptedFilesObject, plots, NAPGenericStore, localBasePath, quiet, archivedOnLoad) {
    var _a, plots_1, plots_1_1;
    var _b, e_1, _c, _d, _e, e_2, _f, _g;
    return __awaiter(this, void 0, void 0, function* () {
        let newPlots = false;
        if (Array.isArray(plots)) {
            try {
                for (_a = true, plots_1 = __asyncValues(plots); plots_1_1 = yield plots_1.next(), _b = plots_1_1.done, !_b;) {
                    _d = plots_1_1.value;
                    _a = false;
                    try {
                        let plot = _d;
                        let plotType;
                        const plotFile = plot.path;
                        if (plotFile.endsWith(".png") || plotFile.endsWith("svg")) {
                            plotType = PlotType.image;
                        }
                        else if (plotFile.endsWith(".plot.json")) {
                            plotType = PlotType.interactive;
                        }
                        if (plotType) {
                            const success = yield loadPlot(pyodide, acceptedFilesObject, plot.path, plotType, plot.options, localBasePath, quiet);
                            if (success) {
                                newPlots = true;
                            }
                        }
                    }
                    finally {
                        _a = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_a && !_b && (_c = plots_1.return)) yield _c.call(plots_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            try {
                // remove once app manifests with plots in the output are updated
                //@ts-ignore
                for (var _h = true, _j = __asyncValues(plots.files), _k; _k = yield _j.next(), _e = _k.done, !_e;) {
                    _g = _k.value;
                    _h = false;
                    try {
                        let plotFile = _g;
                        let plotType;
                        if (plotFile.endsWith(".png") || plotFile.endsWith(".svg")) {
                            plotType = PlotType.image;
                        }
                        else if (plotFile.endsWith(".plot.json")) {
                            plotType = PlotType.interactive;
                        }
                        if (plotType) {
                            const success = yield loadPlot(pyodide, acceptedFilesObject, plotFile, plotType, undefined, undefined, quiet, archivedOnLoad);
                            if (success) {
                                newPlots = true;
                            }
                        }
                    }
                    finally {
                        _h = true;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (!_h && !_e && (_f = _j.return)) yield _f.call(_j);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        if (newPlots && !archivedOnLoad) {
            const activePanels = NAPGenericStore.getState()._activePanels;
            NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { plots: true }));
        }
    });
}
