import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Fade, Grid } from "@mui/material";
import { NAPMSAStore, NAPPlotStore, NAPTableStore } from "../../";
import { useLocation } from "wouter";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { ViewerHelpTitle } from "./ViewerHelpTitle";
import { ViewerHelpActions } from "./ViewerHelpActions";
import { consoleMinHeight } from "../../UIPanels/panelDimensions";
export function ViewerHelpDialog({ disabled, vss, molstar, pyodide, NAPGenericStore, }) {
    const [location] = useLocation();
    const [open, setOpen] = useState(true);
    const hasSystems = vss((state) => state.loaded_structures.length > 0, shallow);
    const hasTables = NAPTableStore((state) => state.tables.length > 0, shallow);
    const hasPlots = NAPPlotStore((state) => state.plots.length > 0, shallow);
    const hasMSA = NAPMSAStore((state) => state.msaArr.length > 0, shallow);
    const acceptedLocations = ["/", "/landing", "/login"];
    useEffect(() => {
        if (hasSystems ||
            hasTables ||
            hasPlots ||
            hasMSA ||
            !acceptedLocations.includes(location)) {
            setOpen(false);
        }
    }, [hasSystems, hasTables, hasPlots, hasMSA, location]);
    return (_jsx(Fade, Object.assign({ in: open, timeout: 500, unmountOnExit: true }, { children: _jsx(Box, Object.assign({ sx: {
                display: "block",
                position: "fixed",
                height: `calc(100vh - ${consoleMinHeight}  - 1em)`,
                left: (theme) => theme.spacing(7),
                right: 0,
                top: 0,
                zIndex: 1,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", sx: {
                    height: "100%",
                    position: "relative",
                    zIndex: "1",
                    color: "#f6f4ee",
                }, wrap: "nowrap", justifyContent: "center", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: {} }, { children: _jsx(ViewerHelpTitle, {}) })), _jsx(Grid, Object.assign({ item: true, xs: true, sx: {
                            overflowY: "auto",
                            maxHeight: "200px",
                        } }, { children: _jsx(ViewerHelpActions, { disabled: disabled, vss: vss, molstar: molstar, pyodide: pyodide, NAPGenericStore: NAPGenericStore }) }))] })) })) })));
}
