// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
function addColumn(data, columnName, pageId) {
    const _pageId = pageId ? pageId : 0;
    const dataWithIds = data.map((row, index) => (Object.assign(Object.assign({}, row), { [columnName]: _pageId + index })));
    return dataWithIds;
}
function transposeData(data) {
    const keys = Object.keys(data[0]);
    const transposedArray = keys.map((key) => data.map((row) => row[key]));
    // Create an array of objects, with keys as original headers and values as transposed rows
    const transposedData = transposedArray[0].map((_, colIndex) => {
        return keys.reduce((obj, key, index) => {
            obj[key] = transposedArray[index][colIndex];
            return obj;
        }, {});
    });
    return transposedData;
}
export function prepareChunk(chunk, chunkN, addColumnName) {
    let dataWithIds;
    if (addColumnName) {
        dataWithIds = addColumn(chunk, addColumnName, chunkN);
    }
    else {
        dataWithIds = chunk;
    }
    return dataWithIds;
}
