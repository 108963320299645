import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ListItem, ListItemIcon, Box } from "@mui/material";
import { useState } from "react";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { DiscordListButton } from "../LogosImages/DiscordLogo";
import { DiscordInvitationPanel } from "../LogosImages/DiscordInvitationPanel";
import { MailContactButton } from "./MailContactButton";
import { SidebarPopupMenu } from "./SidebarPopupMenu";
import { TooltipControlled } from "../utils/TooltipControlled";
import { BugReportPanel } from "./BugReport/BugReportPanel";
import { ReportBugMenuItem } from "./BugReport/BugReportButton";
function HelpMainButton({ handleClick, }) {
    return (_jsx(TooltipControlled, Object.assign({ enterDelay: 200, title: "Help", arrow: true, placement: "right" }, { children: _jsx(ListItem, Object.assign({ onClick: handleClick, sx: { cursor: "pointer" } }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(LiveHelpIcon, {}) })) }) })) })));
}
function HelpMenu({ handleClose, anchorEl, menuIsOpen, setDiscordPanelIsOpen, setBugPanelIsOpen, }) {
    return (_jsx(SidebarPopupMenu, Object.assign({ handleClose: handleClose, anchorEl: anchorEl, menuIsOpen: menuIsOpen, arrowPosition: "bottom" }, { children: _jsxs("div", { children: [_jsx(DiscordListButton, { setDiscordOpen: setDiscordPanelIsOpen }), _jsx(MailContactButton, {}), _jsx(ReportBugMenuItem, { setBugPanelIsOpen: setBugPanelIsOpen })] }) })));
}
export function HelpButtons() {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuIsOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [discordPanelIsOpen, setDiscordPanelIsOpen] = useState(false);
    const [bugPanelIsOpen, setBugPanelIsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(HelpMainButton, { handleClick: handleClick }), _jsx(HelpMenu, { handleClose: handleClose, anchorEl: anchorEl, menuIsOpen: menuIsOpen, setDiscordPanelIsOpen: setDiscordPanelIsOpen, setBugPanelIsOpen: setBugPanelIsOpen }), _jsx(DiscordInvitationPanel, { open: discordPanelIsOpen, setOpen: setDiscordPanelIsOpen }), _jsx(BugReportPanel, { open: bugPanelIsOpen, setOpen: setBugPanelIsOpen })] }));
}
