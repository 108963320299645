import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { NAPMSAStore } from "../../StateStores";
import { Grid } from "@mui/material";
import { SelectMSA } from "../SelectMSA";
import RoundDeleteButton from "../../components/Buttons/DeleteButton/RoundDeleteButton";
import { RefSystemIndicator } from "./RefSystemIndicator";
import { SyncColroRef } from "./SyncColoroRef";
export function MSAOptions({ refSystem, molstar, }) {
    var _a;
    const [msaArr, del_msa, activeMSAIdx, setActiveMSAIdx] = NAPMSAStore((state) => [
        state.msaArr,
        state.del_msa,
        state.activeMSAIdx,
        state.setActiveMSAIdx,
    ], shallow);
    const onDelete = () => {
        del_msa(activeMSAIdx);
        // setActiveMSAIdx(0);
    };
    const activeMSA = msaArr[activeMSAIdx];
    let refSystemName = refSystem === null || refSystem === void 0 ? void 0 : refSystem.name;
    let refSystemCellRef = (refSystem === null || refSystem === void 0 ? void 0 : refSystem.cellRef) ? refSystem.cellRef[0] : undefined;
    const mapStrucToPos = (_a = activeMSA === null || activeMSA === void 0 ? void 0 : activeMSA.mapping) === null || _a === void 0 ? void 0 : _a.strucToPos;
    return (_jsx(_Fragment, { children: activeMSA !== undefined && (_jsxs(_Fragment, { children: [msaArr.length > 1 && (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(SelectMSA, { handleChange: setActiveMSAIdx, activeMSAIdx: activeMSAIdx, msaArr: msaArr }) }))), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(RoundDeleteButton, { onClick: onDelete, tooltipMsg: "Close MSA", confirmationMsg: `Are you sure you want to close this MSA?`, icon: "close" }) })), refSystemName && refSystemCellRef && (_jsx(RefSystemIndicator, { refSystName: refSystemName, cellRef: refSystemCellRef, molstar: molstar })), mapStrucToPos !== undefined && refSystem !== undefined && (_jsx(SyncColroRef, { molstar: molstar, refSystem: refSystem, mapStrucToPos: mapStrucToPos, msaId: activeMSA === null || activeMSA === void 0 ? void 0 : activeMSA.msaID }))] })) }));
}
