import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { MSASequence } from "./MSASequence";
import { useEffect, useMemo, useRef } from "react";
import { SelectionStore } from "../StateStores/selection.store";
import { shallow } from "zustand/shallow";
import { NAPMSAStore } from "../StateStores";
export function MSAVirtualizedSequences({ totalSize, systNameSpace, virtualRows, msaEntries, refSystemData, molstar, mappingStrucToPos, mappedPositions, msaColoring, onMouseMove, onMouseDown, onMouseUp, onMouseLeave, }) {
    const [selectedResidues] = SelectionStore((state) => [state.selectedResidues], shallow);
    const refSelectedResidues = refSystemData.cellRef && selectedResidues[refSystemData.cellRef];
    const selectedPoses = useMemo(() => {
        const selectedPoses = [];
        if (!refSelectedResidues || !mappingStrucToPos)
            return;
        for (const chainSel in refSelectedResidues) {
            if (chainSel in mappingStrucToPos) {
                for (const posSel of refSelectedResidues[chainSel]) {
                    const msaPos = mappingStrucToPos[chainSel][posSel];
                    if (msaPos !== undefined) {
                        const msaPosNum = Number(msaPos);
                        if (!Number.isNaN(msaPosNum))
                            selectedPoses.push(msaPosNum);
                    }
                }
            }
        }
        return selectedPoses;
    }, [refSelectedResidues]);
    const componentRef = useRef(null);
    // Stop seleciton when we click out
    useEffect(() => {
        const onOutsideClick = () => {
            if (NAPMSAStore.getState().lastHoveredMsaPosInSelection === -1)
                return;
            NAPMSAStore.getState().setLastHoveredMsaPosInSelection(-1);
            NAPMSAStore.getState().setMouseDownPosition(-1);
            molstar.managers.interactivity.lociHighlights.clearHighlights();
        };
        // Handler to detect outside click
        function handleClickOutside(event) {
            const refCurrent = componentRef.current;
            if (refCurrent && !refCurrent.contains(event.target)) {
                onOutsideClick(); // Call your function when outside is clicked
            }
        }
        // Bind the event listener
        document.addEventListener("mouseup", handleClickOutside);
        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);
    return (_jsx(Box, Object.assign({ ref: componentRef, onMouseLeave: onMouseLeave, sx: {
            height: `${totalSize}px`,
            // width: "100%",
            position: "relative",
            pl: `calc(${systNameSpace} + 0.5rem)`,
        }, id: "msa-sequences" }, { children: virtualRows.map((virtualRow) => {
            const msaEntry = msaEntries[virtualRow.index];
            const name = msaEntry[0];
            const seqElements = msaEntry[1];
            return (_jsx("div", Object.assign({ className: virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven", style: {
                    position: "absolute",
                    top: 0,
                    // left: 0,
                    // width: "100%",
                    // display: "contents",
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                } }, { children: _jsx(MSASequence, { seqElements: seqElements, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, mappedPositions: mappedPositions, selectedPoses: selectedPoses, msaColoring: msaColoring }, `msa-sequence-${name}`) }), virtualRow.index));
        }) })));
}
