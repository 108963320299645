import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Box } from "@mui/material";
import { bottomPanel, consoleMinHeight } from "../panelDimensions";
import { PyodideTerminal } from "../../PyodideTerminal/PyodideTerminal";
export const ConsolePanel = ({ NAPGenericStore, }) => {
    const [activePanels] = NAPGenericStore((state) => [state._activePanels], shallow);
    const dimensions = Object.assign(Object.assign({}, bottomPanel), { left: (theme) => `calc(${theme.spacing(7)})`, width: (theme) => `calc(100vw - ${theme.spacing(7)} )`, height: activePanels.console ? bottomPanel.height : consoleMinHeight, zIndex: 2 });
    return (_jsx(Box, Object.assign({ id: "console-entrypoint", sx: dimensions }, { children: _jsx(PyodideTerminal, { NAPGenericStore: NAPGenericStore }) })));
};
