import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
import { defaultNAPtheme } from "../../themes";
/** The styled() function takes the original component and applies some extra
 * styling rules based on the available theme and the props passed.
 * Notice that the 'shouldForwardProp' parameter means
 * "pass the prop to the original component as is".
 */
const FixedDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiDrawer-paper": paperStyle(theme),
}));
const paperStyle = (theme) => ({
    backgroundColor: `${theme.palette.primary.main}`,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    overflowY: "auto",
    width: `calc(${theme.spacing(7)} + 1px)`,
    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(${theme.spacing(7)} + 1px)`,
    // }, /* ENABLE THIS FOR RESPONSIVE LAYOUTS */
});
const Sidebar = ({ children }) => (_jsx(FixedDrawer, Object.assign({ theme: defaultNAPtheme, variant: "permanent" }, { children: children })));
export default Sidebar;
