// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { FileType } from "../../utils/Interfaces";
import { createArrayFromStartToN } from "../general_utils";
import indexSelector from "./indexSelector";
import addReverseIndexing from "./utils/addReverseIndexing";
export function indexSelectorToIndexArray(selString, numElements, zeroBased) {
    if (selString === undefined)
        return [];
    let arrayElements = [];
    try {
        const arrayToSelect = zeroBased
            ? createArrayFromStartToN(0, numElements - 1)
            : createArrayFromStartToN(1, numElements);
        arrayElements = indexSelector(selString, addReverseIndexing(arrayToSelect));
    }
    catch (e) { }
    const _arrayElements = arrayElements.filter((e) => e !== undefined);
    return _arrayElements;
}
export function getActiveSystemEntries(system) {
    if (system.type !== FileType.sdf &&
        system.type !== FileType.coordinates_group)
        return;
    const numElements = system.numEntries;
    if (!numElements || numElements <= 1)
        return;
    return indexSelectorToIndexArray(system.visibleElementIDs, numElements, true);
}
