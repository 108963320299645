import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Typography, CircularProgress, Backdrop, Box } from "@mui/material";
import { useState } from "react";
import { useInterval } from "./utils";
import { dispatchControlledNotification } from "./NotificationSystem/utils";
export function MolkitLoaderStatus({ open }) {
    const [interval, setInterval] = useState(1000);
    const [progress, setProgress] = useState(0);
    useInterval(() => {
        if (window.workersNotSupported) {
            dispatchControlledNotification({
                idx: "not-supported",
                message: "Your browser does not support key features required for this application.",
                type: "error",
            });
            setInterval(null);
        }
        if (window.pyodideLoaderStatus)
            setProgress(window.pyodideLoaderStatus);
        if (window.pyodideWorkerReady) {
            setInterval(null);
        }
    }, interval);
    return (_jsxs(Backdrop, Object.assign({ sx: { color: "primary", zIndex: (theme) => theme.zIndex.drawer + 1 }, open: open }, { children: [_jsx(CircularProgress, {}), _jsx(Box, Object.assign({ sx: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                } }, { children: _jsx(Typography, Object.assign({ variant: "caption", component: "div", color: "#999999", sx: { pt: "2px" } }, { children: `${progress}%` })) }))] })));
}
