var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export function TextInputDialog() {
    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        text: "",
    });
    // const [inputVal, setInputVal] = useState("");
    // const [error, setError] = useState("");
    const [resolve, setResolve] = useState(() => { });
    const [fieldContent, setFieldContent] = useState({ value: "", error: "" });
    const [testCallback, setTestCallback] = useState();
    const closeAndReturnUndefined = () => {
        resolve(undefined);
        setOpen(false);
        setFieldContent({ value: "", error: "" });
    };
    const closeAndReturnData = () => __awaiter(this, void 0, void 0, function* () {
        if (testCallback) {
            const { success, error } = testCallback(fieldContent.value);
            if (!success) {
                const _error = error ? error : "Invalid string.";
                setFieldContent((prev) => {
                    return Object.assign(Object.assign({}, prev), { error: _error });
                });
                return;
            }
        }
        resolve(fieldContent.value);
        setOpen(false);
        setFieldContent({ value: "", error: "" });
    });
    useEffect(() => {
        const _cb = (e) => {
            const { title, text, resolve, testCallback } = e.detail;
            setDialogContent({
                title: title,
                text: text,
            });
            setOpen(true);
            setResolve(() => resolve);
            setTestCallback((x) => testCallback);
        };
        window.addEventListener("PM-TextInput-Dialog", _cb);
        return () => {
            window.removeEventListener("PM-TextInput-Dialog", _cb);
        };
    }, []);
    const handleInputChange = (value) => {
        let error = "";
        const maxLen = 50;
        if (value.length > maxLen)
            error = `This must be ${maxLen} characters or fewer.`;
        else
            error = "";
        setFieldContent({
            value,
            error,
        });
    };
    const disableConfirm = !fieldContent.value || !!fieldContent.error;
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: closeAndReturnUndefined, sx: { "& .MuiPaper-root": { p: 1 } } }, { children: [_jsxs(DialogTitle, Object.assign({ textAlign: "center" }, { children: [dialogContent.title ? dialogContent.title : null, _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: closeAndReturnUndefined, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] })), _jsx(DialogContent, Object.assign({ sx: { py: 0 } }, { children: _jsx(TextField, { error: !!fieldContent.error, helperText: fieldContent.error, sx: { my: 1 }, label: dialogContent.text, variant: "outlined", value: fieldContent.value, size: "small", onChange: (e) => handleInputChange(e.target.value), autoFocus: true, onKeyDown: (e) => {
                        if (e.key === "Enter" && !disableConfirm) {
                            closeAndReturnData();
                        }
                    } }) })), _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", disabled: disableConfirm, onClick: closeAndReturnData }, { children: "Confirm" })) }))] })));
}
export function dispatchTextInputDialogEvent({ title, text, testCallback, }) {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent("PM-TextInput-Dialog", {
            detail: { resolve, title, text, testCallback },
        }));
    });
}
