import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useVirtualizer } from "@tanstack/react-virtual";
import { MSAVirtualizedNames } from "./MSAVirtualizedNames";
import { MSAVirtualizedSequences } from "./MSAVirtualizedSequences";
export function MSAVirtualized({ msa, parentRefCurr, systNameSpace, refSystemData, molstar, mappingStrucToPos, mappedPositions, msaColoring, onMouseMove, onMouseDown, onMouseUp, onMouseLeave, }) {
    const msaEntries = Object.entries(msa);
    const rowVirtualizer = useVirtualizer({
        count: msaEntries.length,
        getScrollElement: () => parentRefCurr,
        estimateSize: () => 25,
        overscan: 2,
    });
    const virtualRows = rowVirtualizer.getVirtualItems();
    return (_jsxs(_Fragment, { children: [_jsx(MSAVirtualizedNames, { virtualRows: virtualRows, msaEntries: msaEntries, systNameSpace: systNameSpace }), _jsx(MSAVirtualizedSequences, { virtualRows: virtualRows, msaEntries: msaEntries, systNameSpace: systNameSpace, totalSize: rowVirtualizer.getTotalSize(), refSystemData: refSystemData, molstar: molstar, mappingStrucToPos: mappingStrucToPos, mappedPositions: mappedPositions, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseLeave: onMouseLeave, msaColoring: msaColoring })] }));
}
