import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Link, Typography } from "@mui/material";
import { NAPMSAStore } from "../../StateStores";
import { shallow } from "zustand/shallow";
import { memo } from "react";
import MSAPositionsTypography from "./MSAPositionsTypography";
function HoveredPosTypography({ children, }) {
    return (_jsx(Typography, Object.assign({ color: "primary", sx: {
            color: "white",
            fontSize: "10px",
            fontFamily: "monospace",
            p: "1px",
        } }, { children: children })));
}
function HoverPos({ idx, resid, mappingAvailable, }) {
    const isHovered = NAPMSAStore((state) => state.hoveredMsaPos === idx, shallow);
    return (_jsxs(Box, Object.assign({ visibility: isHovered ? "visible" : "hidden", sx: {
            position: "absolute",
            zIndex: 10,
            backgroundColor: "secondary.main",
            borderRadius: "2px",
            mt: mappingAvailable ? (resid ? 0 : "17px") : 0,
        } }, { children: [mappingAvailable && _jsx(HoveredPosTypography, { children: resid }), _jsx(HoveredPosTypography, { children: idx })] })));
}
function ShownStaticPos({ idx, resid, mappingAvailable, }) {
    return mappingAvailable ? (_jsxs(_Fragment, { children: [!!resid && (_jsx(MSAPositionsTypography, Object.assign({ addToStyle: {
                    zIndex: 2,
                } }, { children: resid }))), _jsx(MSAPositionsTypography, Object.assign({ addToStyle: {
                    zIndex: 2,
                    top: "17px",
                } }, { children: idx }))] })) : (_jsx(MSAPositionsTypography, Object.assign({ addToStyle: {
            zIndex: 2,
        } }, { children: idx })));
}
const MSAPosition = memo(MSAPositionMemo);
function MSAPositionMemo({ idx, intervalPosShown, resid, mappingAvailable, }) {
    return (_jsxs(Box, Object.assign({ sx: {
            position: "relative",
            textAlign: "left",
            width: "8px",
            backgroundColor: "white",
            height: mappingAvailable ? "34px" : "15px",
        } }, { children: [intervalPosShown && (_jsx(ShownStaticPos, { idx: idx, resid: resid, mappingAvailable: mappingAvailable })), _jsx(HoverPos, { idx: idx, resid: resid, mappingAvailable: mappingAvailable }), _jsx(Link, Object.assign({ className: "sequencePosition", component: "button", underline: "none", sx: {
                    fontFamily: "monospace",
                    fontSize: "0.8rem",
                    color: "black",
                    visibility: "hidden",
                } }, { children: "." }))] })));
}
export default MSAPosition;
