// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { dispatchNotificationEvent } from "../../NotificationSystem/utils";
import { supportedFiles } from "../../";
export function formatErrors(validationErrors) {
    const errorList = [];
    validationErrors.forEach((err) => {
        const valid_types = Array.isArray(err.schema)
            ? `(${err.schema.join(", ")})`
            : "";
        const error_string = `'${err.currentValue}' in ${err.path} ${err.errMessage} ${valid_types}`;
        errorList.push(error_string);
    });
    return errorList;
}
export function displayNotSupportedError(unsupportedFiles) {
    const extUnique = [];
    unsupportedFiles.forEach((fname) => {
        const ext = fname.split(".").pop();
        if (ext && !extUnique.includes(ext)) {
            extUnique.push(ext);
        }
    });
    const errorMsg = `The extension${unsupportedFiles.length > 1 ? "s" : ""} ${extUnique.join(", ")} ${unsupportedFiles.length > 1 ? "are" : "is"} not supported. The following files were skipped: ${unsupportedFiles.join(", ")}`;
    dispatchNotificationEvent({ message: errorMsg, type: "error" });
}
export function displayUnmatchedError(unmatchedFiles) {
    const topFiles = unmatchedFiles.filter((e) => {
        const ext = e.split(".").pop();
        return ext && supportedFiles.topology.includes(ext);
    });
    const trajFiles = unmatchedFiles.filter((e) => {
        const ext = e.split(".").pop();
        return ext && supportedFiles.coordinates_trajectory.includes(ext);
    });
    if (topFiles.length > 0 && trajFiles.length > 0)
        dispatchNotificationEvent({
            message: `The following trajectory and topology files could not be matched, please load only one file of each type: ${unmatchedFiles.join(", ")}`,
            type: "error",
        });
    else if (topFiles.length > 0)
        dispatchNotificationEvent({
            message: `Topology file${topFiles.length > 1 ? "s" : ""} loaded without corresponding trajectory file: ${topFiles.join(", ")}. Please load a topology and trajectory file pair.`,
            type: "error",
        });
    else
        dispatchNotificationEvent({
            message: `Trajectory file${trajFiles.length > 1 ? "s" : ""} loaded without corresponding topology file: ${trajFiles.join(", ")}. Please load a topology and trajectory file pair.`,
            type: "error",
        });
}
