import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, IconButton, Typography, Collapse, Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
export function ActiveOptionsMenu({ name, numSelectedSystems, isActive, onAccept, onCancel, backgroundColor, }) {
    return (_jsx(Collapse, Object.assign({ in: isActive, unmountOnExit: true }, { children: _jsx(Paper, Object.assign({ elevation: 0, sx: {
                backgroundColor: backgroundColor ? backgroundColor : undefined,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center", sx: { mt: 1, py: 1, px: 2 }, spacing: 1 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: true }, { children: [_jsx(Typography, { children: name }), _jsxs(Typography, Object.assign({ sx: { fontSize: "13px" } }, { children: ["(", numSelectedSystems, " selected)"] }))] })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ onClick: onCancel, color: "primary", size: "small" }, { children: _jsx(CloseIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ size: "small", color: "primary", onClick: onAccept, disabled: numSelectedSystems === 0 }, { children: _jsx(CheckIcon, {}) })) }))] })) })) })));
}
