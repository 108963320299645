// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { bottomPanel, consoleMinHeight } from "../../UIPanels/panelDimensions";
export function applyActionOnPlotNodes(action, chartHandler, nodeIDs) {
    chartHandler.dispatchAction({
        type: action,
        dataIndex: nodeIDs,
    });
}
export function calculatePlotHeight(yPosition, consoleIsOpen) {
    const consoleHeight = consoleIsOpen
        ? ` - ${bottomPanel.height}`
        : ` - ${consoleMinHeight}`;
    return `calc(100vh - ${yPosition}px${consoleHeight})`;
}
