// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { posSelToLoci } from "./msaSelection";
import { StructureElement } from "molstar/lib/mol-model/structure";
// Binary search to find the first index >= target
export const binarySearchStart = (arr, target) => {
    let low = 0;
    let high = arr.length - 1;
    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        if (arr[mid] < target) {
            low = mid + 1;
        }
        else {
            high = mid - 1;
        }
    }
    return arr[low]; // Element corresponding to first index where arr[low] >= target
};
// Binary search to find the last index <= target
export const binarySearchEnd = (arr, target) => {
    let low = 0;
    let high = arr.length - 1;
    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        if (arr[mid] <= target) {
            low = mid + 1;
        }
        else {
            high = mid - 1;
        }
    }
    return arr[high]; // Element corresponding to last index where arr[high] <= target
};
export const getMSALoci = (mapStrucToPos, structureData) => {
    let lociAll;
    for (const chainId in mapStrucToPos) {
        const chainRedids = Object.keys(mapStrucToPos[chainId]).map((res) => Number(res));
        const loci = posSelToLoci(chainRedids, chainId, structureData, true);
        if (loci) {
            lociAll = lociAll ? StructureElement.Loci.union(lociAll, loci) : loci;
        }
    }
    return lociAll;
};
