import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Box } from "@mui/material";
import { Sequence } from "./Sequence";
import { SelectionStore } from "../StateStores/selection.store";
import { useVirtualizer } from "@tanstack/react-virtual";
import { SystemName } from "./SeqSystemName";
export default function SeqVirtualized({ parentRef, protSystemObjs, molstar, }) {
    const [selectedResidues, clickedSequence] = SelectionStore((state) => [state.selectedResidues, state.clickedSequence], shallow);
    const rowVirtualizer = useVirtualizer({
        count: protSystemObjs.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 40,
        overscan: 2,
    });
    const virtualRows = rowVirtualizer.getVirtualItems();
    const systNameSpace = "100px";
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                    height: "3px",
                } }, { children: virtualRows.map((virtualRow) => {
                    const { system } = protSystemObjs[virtualRow.index];
                    return (_jsx("div", Object.assign({ className: virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven", style: {
                            maxWidth: systNameSpace,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            // width: "100%",
                            // display: "contents",
                            height: `${virtualRow.size}px`,
                            transform: `translateY(${virtualRow.start}px)`,
                        } }, { children: _jsx(SystemName, { space: systNameSpace, systemName: system.name }) }), `name-${virtualRow.index}`));
                }) })), _jsx(Box, Object.assign({ sx: {
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    // width: "100%",
                    position: "relative",
                    pl: `calc(${systNameSpace} + 0.5rem)`,
                }, id: "seq-right" }, { children: virtualRows.map((virtualRow) => {
                    const { structureData, seqData, system } = protSystemObjs[virtualRow.index];
                    const cellRef = system.cellRef ? system.cellRef[0] : undefined;
                    const selectedSystResidues = cellRef && selectedResidues ? selectedResidues[cellRef] : undefined;
                    return (_jsxs("div", Object.assign({ className: virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven", style: {
                            position: "absolute",
                            top: 0,
                            // left: 0,
                            // width: "100%",
                            // display: "contents",
                            height: `${virtualRow.size}px`,
                            transform: `translateY(${virtualRow.start}px)`,
                        } }, { children: [_jsx(Sequence, { name: system.name, cellRef: cellRef, molstar: molstar, selectedSystResidues: selectedSystResidues, isVisible: system.visibility !== false, seqIsClicked: !!cellRef && (clickedSequence === null || clickedSequence === void 0 ? void 0 : clickedSequence.cellRef) === cellRef, structureData: structureData, seqData: seqData }, system.moleculeID), " "] }), virtualRow.index));
                }) }))] }));
}
