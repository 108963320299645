import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { TooltipControlled } from "../utils/TooltipControlled";
export function SystemName({ space, systemName, }) {
    return (_jsx(_Fragment, { children: _jsx(Box, Object.assign({ sx: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: space,
                direction: "rtl",
                textAlign: "left",
            } }, { children: _jsx(TooltipControlled, Object.assign({ arrow: true, enterDelay: 1000, title: systemName }, { children: _jsx(Typography, Object.assign({ variant: "caption", sx: {
                        pl: 1,
                        py: "4px",
                        backgroundColor: "white",
                        fontSize: "10px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                    } }, { children: systemName })) })) })) }));
}
