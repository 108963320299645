import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Divider, Grid, Switch, Typography } from "@mui/material";
import { StructureElement } from "molstar/lib/mol-model/structure";
import { StateObjectRef } from "molstar/lib/mol-state";
import { Color } from "molstar/lib/mol-util/color";
import { useEffect, useMemo, useRef, useState } from "react";
import { getMSALoci } from "../utils/utils";
import { NAPMSAStore } from "../../StateStores";
import debounce from "../../utils/Debounce";
import { shallow } from "zustand/shallow";
import { SelectMSAcolorRep } from "./SelectMSAcolorRep";
export function SyncColroRef({ molstar, refSystem, mapStrucToPos, msaId, }) {
    var _a;
    const [syncColorActive] = NAPMSAStore((state) => [state.syncColorActive], shallow);
    const [colorRepIdx, setColorRepIdx] = useState(0);
    useEffect(() => {
        setColorRepIdx(0);
    }, [msaId]);
    const refReps = refSystem === null || refSystem === void 0 ? void 0 : refSystem.representations;
    const numReps = refReps ? refReps.length : 0;
    useEffect(() => {
        if (colorRepIdx >= numReps) {
            setColorRepIdx(numReps === 0 ? 0 : numReps - 1);
        }
    }, [numReps]);
    const activeRef = refReps && refReps.length > colorRepIdx ? refReps[colorRepIdx] : undefined;
    const repRef = (activeRef === null || activeRef === void 0 ? void 0 : activeRef.refs) && (activeRef === null || activeRef === void 0 ? void 0 : activeRef.refs.length) > 0
        ? activeRef.refs[0].repRef
        : undefined;
    const cellRef = (activeRef === null || activeRef === void 0 ? void 0 : activeRef.refs) && (activeRef === null || activeRef === void 0 ? void 0 : activeRef.refs.length) > 0
        ? activeRef.refs[0].cellRef
        : undefined;
    const selectedStruct = cellRef
        ? StateObjectRef.resolveAndCheck(molstar.state.data, cellRef)
        : undefined;
    const structureData = (_a = selectedStruct === null || selectedStruct === void 0 ? void 0 : selectedStruct.obj) === null || _a === void 0 ? void 0 : _a.data;
    const mappedSegmentLoci = useMemo(() => getMSALoci(mapStrucToPos, structureData), [msaId, cellRef]);
    const handleSyncColors = () => {
        updateMSAColors(repRef, mappedSegmentLoci, molstar);
    };
    const debounceHandleSyncColors = debounce(handleSyncColors, 500);
    // const [syncActive, setSyncActive] = useState(false);
    const handleSyncChange = (event) => {
        const setActive = event.target.checked;
        NAPMSAStore.getState().setSyncColorActive(setActive);
        if (setActive)
            debounceHandleSyncColors();
        else {
            NAPMSAStore.getState().setMSAColoring(undefined);
        }
    };
    useEffect(() => {
        if (!NAPMSAStore.getState().syncColorActive)
            return;
        debounceHandleSyncColors();
    }, [repRef]);
    molstar.state.data.events.cell.stateUpdated;
    const _activeRefColors = {
        colorTheme: activeRef === null || activeRef === void 0 ? void 0 : activeRef.color,
        customColor: activeRef === null || activeRef === void 0 ? void 0 : activeRef.color_value,
        carbonColor: activeRef === null || activeRef === void 0 ? void 0 : activeRef.carbon_color_value,
        bFactRange: activeRef === null || activeRef === void 0 ? void 0 : activeRef.bfactor_color_range,
        bFactColors: activeRef === null || activeRef === void 0 ? void 0 : activeRef.bfactor_colors,
    };
    const activeRefColorsRef = useRef(_activeRefColors);
    const handleMolstarCellChanged = (event) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
        if (!NAPMSAStore.getState().syncColorActive)
            return;
        if (event.ref !== repRef)
            return;
        const newColorTheme = (_d = (_c = (_b = (_a = event === null || event === void 0 ? void 0 : event.cell) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.colorTheme) === null || _d === void 0 ? void 0 : _d.name;
        const newCustomColor = (_l = (_k = (_j = (_h = (_g = (_f = (_e = event === null || event === void 0 ? void 0 : event.cell) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.values) === null || _g === void 0 ? void 0 : _g.colorTheme) === null || _h === void 0 ? void 0 : _h.params) === null || _j === void 0 ? void 0 : _j.carbonColor) === null || _k === void 0 ? void 0 : _k.params) === null || _l === void 0 ? void 0 : _l.value;
        const newCarbonColor = (_r = (_q = (_p = (_o = (_m = event === null || event === void 0 ? void 0 : event.cell) === null || _m === void 0 ? void 0 : _m.params) === null || _o === void 0 ? void 0 : _o.values) === null || _p === void 0 ? void 0 : _p.colorTheme) === null || _q === void 0 ? void 0 : _q.params) === null || _r === void 0 ? void 0 : _r.value;
        const newBFactRange = (_w = (_v = (_u = (_t = (_s = event === null || event === void 0 ? void 0 : event.cell) === null || _s === void 0 ? void 0 : _s.params) === null || _t === void 0 ? void 0 : _t.values) === null || _u === void 0 ? void 0 : _u.colorTheme) === null || _v === void 0 ? void 0 : _v.params) === null || _w === void 0 ? void 0 : _w.domain;
        const newBFactColors = (_2 = (_1 = (_0 = (_z = (_y = (_x = event === null || event === void 0 ? void 0 : event.cell) === null || _x === void 0 ? void 0 : _x.params) === null || _y === void 0 ? void 0 : _y.values) === null || _z === void 0 ? void 0 : _z.colorTheme) === null || _0 === void 0 ? void 0 : _0.params) === null || _1 === void 0 ? void 0 : _1.list) === null || _2 === void 0 ? void 0 : _2.colors;
        if (activeRefColorsRef.current.colorTheme !== newColorTheme ||
            activeRefColorsRef.current.customColor !== newCustomColor ||
            activeRefColorsRef.current.carbonColor !== newCarbonColor ||
            JSON.stringify(activeRefColorsRef.current.bFactRange) !==
                JSON.stringify(newBFactRange) ||
            JSON.stringify(activeRefColorsRef.current.bFactColors) !==
                JSON.stringify(newBFactColors)) {
            debounceHandleSyncColors();
            activeRefColorsRef.current = {
                colorTheme: newColorTheme,
                customColor: newCustomColor,
                carbonColor: newCarbonColor,
                bFactRange: newBFactRange,
                bFactColors: newBFactColors,
            };
        }
    };
    useEffect(() => {
        const _cb = (e) => {
            const { event } = e.detail;
            handleMolstarCellChanged(event);
        };
        window.addEventListener("PM-MolstarCellChanged", _cb);
        return () => {
            window.removeEventListener("PM-MolstarCellChanged", _cb);
        };
    }, [repRef]);
    return (_jsxs(_Fragment, { children: [_jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), " ", _jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { px: 1 } }, { children: _jsxs(Box, Object.assign({ display: "flex", alignItems: "center", gap: 1 }, { children: [_jsxs(Box, { children: [_jsx(Typography, Object.assign({ display: "inline", variant: "caption", sx: {
                                        fontSize: "10px",
                                        pr: "5px",
                                    } }, { children: "Sync ref. color" })), _jsx(Switch, { size: "small", checked: syncColorActive, onChange: handleSyncChange, inputProps: { "aria-label": "controlled" } })] }), syncColorActive && refReps !== undefined && refReps.length > 0 && (_jsx(SelectMSAcolorRep, { activeValue: colorRepIdx, callback: setColorRepIdx, representations: refReps }))] })) }))] }));
}
function updateMSAColors(repRef, mappedSegmentLoci, molstar) {
    let msaColoring;
    if (repRef) {
        const selectedRep = StateObjectRef.resolveAndCheck(molstar.state.data, repRef);
        if (mappedSegmentLoci) {
            msaColoring = [];
            StructureElement.Loci.forEachLocation(mappedSegmentLoci, (loc) => {
                var _a, _b;
                {
                    let posColor;
                    const color = (_b = (_a = selectedRep === null || selectedRep === void 0 ? void 0 : selectedRep.obj) === null || _a === void 0 ? void 0 : _a.data.repr) === null || _b === void 0 ? void 0 : _b.theme.color.color(loc);
                    if (color) {
                        posColor = Color.toHexStyle(color);
                    }
                    msaColoring.push(posColor);
                }
            });
        }
    }
    NAPMSAStore.getState().setMSAColoring(msaColoring);
}
export function dispatchMolstarCellChanged({ event }) {
    window.dispatchEvent(new CustomEvent("PM-MolstarCellChanged", {
        detail: { event },
    }));
}
