import { StateAction } from "molstar/lib/mol-state";
import { StateSelection } from "molstar/lib/mol-state";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { StateTransforms } from "molstar/lib/mol-plugin-state/transforms";
import { ParamDefinition as PD } from "molstar/lib/mol-util/param-definition";
import debounce from "../utils/Debounce";
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
const UpdateTrajectory = StateAction.build({
    display: { name: "Update Trajectory" },
    params: {
        action: PD.Select("advance", PD.arrayToOptions(["advance", "reset", "setFrame"])),
        by: PD.Optional(PD.Numeric(1)),
        frame: PD.Optional(PD.Numeric(0)),
        ref: PD.Text(),
    },
})(({ params, state }) => {
    const models = state
        .selectQ((q) => q.ofTransformer(StateTransforms.Model.ModelFromTrajectory))
        .filter((model) => model.sourceRef === params.ref);
    const update = state.build();
    if (params.action === "reset") {
        for (const m of models) {
            update.to(m).update({ modelIndex: 0 });
        }
    }
    else {
        for (const m of models) {
            const parent = StateSelection.findAncestorOfType(state.tree, state.cells, m.transform.ref, PluginStateObject.Molecule.Trajectory);
            if (!parent || !parent.obj)
                continue;
            const traj = parent.obj;
            update.to(m).update((old) => {
                let modelIndex;
                if (params.action === "setFrame") {
                    if (params.frame || params.frame === 0) {
                        modelIndex = params.frame % traj.data.frameCount;
                    }
                    else {
                        const errorMsg = "Frame index not defined.";
                        dispatchNotificationEvent({
                            type: "error",
                            message: errorMsg,
                        });
                        throw new Error(errorMsg);
                    }
                }
                else {
                    modelIndex = (old.modelIndex + params.by) % traj.data.frameCount;
                }
                if (modelIndex < 0)
                    modelIndex += traj.data.frameCount;
                return { modelIndex };
            });
        }
    }
    return state.updateTree(update);
});
export const setFrame = (molstar, ref, frame) => {
    try {
        PluginCommands.State.ApplyAction(molstar, {
            state: molstar.state.data,
            action: UpdateTrajectory.create({
                action: "setFrame",
                ref: ref,
                frame: frame,
            }),
        });
    }
    catch (e) {
        console.error(e);
    }
};
function updateFrame(molstar, frameVal, trajKey) {
    setFrame(molstar, trajKey, frameVal - 1);
}
export const debounce_updateFrame = debounce((molstar, frameVal, trajKey) => updateFrame(molstar, frameVal, trajKey), 250);
