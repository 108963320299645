import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, MenuItem, Select } from "@mui/material";
export function MSAPanelSelector({ activeValue, onChange, options, }) {
    return (_jsx(Box, Object.assign({ sx: { m: "2px" } }, { children: _jsx(Select, Object.assign({ size: "small", labelId: "select-seq-panel-label", id: "select-seq-panel", value: activeValue, label: "", onChange: onChange, sx: {
                "& .MuiSelect-select": { fontSize: "0.875rem", py: "2px" },
            }, inputProps: {
                style: {
                    fontSize: "0.875rem",
                },
            }, MenuProps: {
                PaperProps: {
                    sx: {
                        "& .MuiMenuItem-root": {
                            fontSize: "0.875rem",
                        },
                    },
                },
            } }, { children: options.map(({ label, value }) => (_jsx(MenuItem, Object.assign({ sx: { fontSize: "0.875rem" }, value: value }, { children: label }), `select-seq-panel-option-${value}`))) })) })));
}
