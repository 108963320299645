import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Paper } from "@mui/material";
import { NAPTableStore } from "../../StateStores/tables.store";
import { useKeyPress } from "./../../utils/useKeyPress";
import { useEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import { PreviousNextButtons } from "../../3dViewer/Controls/PreviousNextButtons";
import { NAPGenericStore } from "../../GenericViewerState";
export function TableNavigation({ handleClickTableNavigation, rows, sorting, columnFilters, rowsNotReady, }) {
    const [isWaiting] = NAPGenericStore((state) => [state.loadingDCFile]);
    const [navigationIdx, setRowIdx] = NAPTableStore((state) => [state.navigationIdx, state.setNavigationIdx], shallow);
    const numRows = rows.length;
    const rowIdx = navigationIdx.currentIdx;
    useEffect(() => {
        handleClickTableNavigation(rowIdx);
    }, [rowIdx]);
    const handleClickStep = (step) => {
        const newIdx = rowIdx + step;
        handleClickSet(newIdx);
    };
    const handleClickSet = (newIdx) => {
        if (isWaiting) {
            return;
        }
        const nextRow = rows[newIdx];
        if (!nextRow)
            return;
        const newOriginalIdx = nextRow.original["_pmv_originalId"];
        setRowIdx(newIdx, newOriginalIdx);
    };
    useKeyPress(["ArrowRight"], () => {
        handleClickStep(1);
    }, false, false, false);
    useKeyPress(["ArrowLeft"], () => {
        handleClickStep(-1);
    }, false, false, false);
    // Listent to events to navigate table
    useEffect(() => {
        const _cb = (e) => {
            const { step } = e.detail;
            handleClickStep(step);
        };
        window.addEventListener("PM-TableNavigationStep", _cb);
        return () => {
            window.removeEventListener("PM-TableNavigationStep", _cb);
        };
    }, []);
    useEffect(() => {
        const _cb = (e) => {
            const { value } = e.detail;
            handleClickSet(value);
        };
        window.addEventListener("PM-TableNavigationSet", _cb);
        return () => {
            window.removeEventListener("PM-TableNavigationSet", _cb);
        };
    }, []);
    // Set active row to 0 after filtering and sorting
    const previousSortingRef = useRef(sorting);
    const previousColumnFiltersRef = useRef(columnFilters);
    useEffect(() => {
        // Check if the sorting or column filter states have changed
        const hasSortingChanged = JSON.stringify(previousSortingRef.current) !== JSON.stringify(sorting);
        const hasFilterChanged = JSON.stringify(previousColumnFiltersRef.current) !==
            JSON.stringify(columnFilters);
        // If sorting/col filters has changed and rows are available (not in the middle of fetching/loading)
        if ((hasSortingChanged || hasFilterChanged) &&
            !rowsNotReady &&
            rows.length > 0 &&
            !isWaiting) {
            NAPTableStore.getState().setNavigationIdx(0, rows[0].original["_pmv_originalId"]);
            handleClickTableNavigation(0);
            // Update the previous sorting and col.filter states
            previousSortingRef.current = sorting;
            previousColumnFiltersRef.current = columnFilters;
        }
    }, [sorting, columnFilters, rowsNotReady, rows, isWaiting]);
    return (_jsx(Paper, Object.assign({ sx: { order: 6, width: "100%", mt: 1 } }, { children: _jsx(PreviousNextButtons, { handleClickFirst: () => handleClickSet(0), handleClickPrevious: () => handleClickStep(-1), handleClickNext: () => handleClickStep(1), handleClickLast: () => handleClickSet(numRows - 1), firstIsDisabled: isWaiting || rowIdx === 0, previousIsDisabled: isWaiting || rowIdx === 0, nextIsDisabled: isWaiting || rowIdx === numRows - 1, lastIsDisabled: isWaiting || rowIdx === numRows - 1, size: "large" }) })));
}
export function dispatchTableNavigationStepEvent({ step }) {
    window.dispatchEvent(new CustomEvent("PM-TableNavigationStep", {
        detail: { step },
    }));
}
export function dispatchTableNavigationSetEvent({ value }) {
    window.dispatchEvent(new CustomEvent("PM-TableNavigationSet", {
        detail: { value },
    }));
}
