// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchData } from "./fetchData";
import produce from "immer";
export const useInfiniteTableQuery = ({ tableID, sorting, columnFilters, filteredSortedData, fetchSize = 25, }) => {
    return useInfiniteQuery(
    // Create a unique query key that includes all necessary dependencies. Adding sorting and filters state as key causes table to reset and fetch from new beginning upon sort/filter
    ["tableData", tableID, sorting, columnFilters], ({ pageParam = 0 }) => __awaiter(void 0, void 0, void 0, function* () {
        const start = pageParam * fetchSize;
        const fetchedData = yield fetchData(start, fetchSize, filteredSortedData); // pretend api call
        return fetchedData;
    }), {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
};
export const updateTableData = (oldData, rowIndex, fetchSize, columnId, newValue) => {
    if (!oldData)
        return;
    return produce(oldData, (draft) => {
        // Calculate the page index and the row index within that page
        const pageIndex = Math.floor(rowIndex / fetchSize);
        const rowIndexInPage = rowIndex % fetchSize;
        // Update the specific cell in the draft
        draft.pages[pageIndex].data[rowIndexInPage][columnId] = newValue;
    });
};
export const clearFetchedTableColumn = (oldData, columnId) => {
    if (!oldData)
        return;
    return produce(oldData, (draft) => {
        draft.pages.forEach((page) => {
            page.data.forEach((row) => {
                row[columnId] = "";
            });
        });
    });
};
