import { defaultColorRange } from "./representationParamOptions";
function defineUncertainityColorParams(bfactor_color_range, bfactor_colors) {
    const _bfactor_color_range_min = bfactor_color_range && bfactor_color_range.length > 0
        ? bfactor_color_range[0]
        : 0;
    const _bfactor_color_range_max = bfactor_color_range && bfactor_color_range.length > 1
        ? bfactor_color_range[1]
        : 100;
    const _bfactor_colors = bfactor_colors ? bfactor_colors : defaultColorRange;
    return {
        domain: [_bfactor_color_range_min, _bfactor_color_range_max],
        list: {
            colors: _bfactor_colors,
            // kin: "interpolate",
        },
    };
}
export function defineColorParams(color, color_value, carbon_color_value, bfactor_color_range, bfactor_colors) {
    const uniformColorAvailable = color === "uniform" && color_value ? color_value : undefined;
    const carbonColorAvailable = color === "element-symbol" && carbon_color_value
        ? carbon_color_value
        : undefined;
    const bFactorColorSettings = color === "uncertainty";
    const colorParams = uniformColorAvailable
        ? {
            value: uniformColorAvailable,
            carbonColor: undefined,
        }
        : carbonColorAvailable
            ? {
                carbonColor: {
                    name: "uniform",
                    params: { value: carbonColorAvailable },
                },
            }
            : bFactorColorSettings
                ? defineUncertainityColorParams(bfactor_color_range, bfactor_colors)
                : undefined;
    return colorParams;
}
