import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { repColorToLabel } from "../../3dViewer/RepresentationControls/representationParamOptions";
import { Box, MenuItem, Select, Typography } from "@mui/material";
export function SelectMSAcolorRep({ activeValue, callback, representations, }) {
    const onChange = (e) => {
        callback(e.target.value);
    };
    const options = representations.map((rep, i) => ({
        value: i,
        label: `${i} - ${repColorToLabel[rep.color]}`,
    }));
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ display: "inline", variant: "caption", sx: {
                    fontSize: "10px",
                    //   pr: "5px",
                } }, { children: "to representation" })), _jsx(Box, Object.assign({ sx: { m: "2px" } }, { children: _jsx(Select, Object.assign({ size: "small", labelId: "select-seq-panel-label", id: "select-seq-panel", value: activeValue, label: "", onChange: onChange, sx: {
                        "& .MuiSelect-select": { fontSize: "10px", py: "1px" },
                    }, inputProps: {
                        style: {
                            fontSize: "10px",
                        },
                    }, MenuProps: {
                        PaperProps: {
                            sx: {
                                "& .MuiMenuItem-root": {
                                    fontSize: "10px",
                                },
                            },
                        },
                    } }, { children: options.map(({ label, value }) => (_jsx(MenuItem, Object.assign({ sx: { fontSize: "10pxm" }, value: value }, { children: label }), `select-seq-panel-option-${value}`))) })) }))] })));
}
