// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { StateSelection } from "molstar/lib/mol-state";
export function formatRange(arr) {
    if (arr.length === 0)
        return "";
    // Sort the array to ensure it's in order
    arr.sort((a, b) => a - b);
    let result = [];
    let start = arr[0];
    let end = arr[0];
    for (let i = 1; i <= arr.length; i++) {
        // Check if the current number is consecutive
        if (arr[i] === end + 1) {
            end = arr[i]; // extend the range
        }
        else {
            // If it's not consecutive, push the range or the single number to the result
            if (start === end) {
                result.push(`${start}`);
            }
            else {
                result.push(`${start}-${end}`);
            }
            // Reset start and end to the current number
            start = arr[i];
            end = arr[i];
        }
    }
    // Join the result into a string
    return result.join(",");
}
export function getSDFTrajectory(molstar, cellRefs) {
    if (cellRefs.length === 0)
        return;
    const cellRef = cellRefs[0];
    let trajectory;
    try {
        trajectory = StateSelection.findAncestorOfType(molstar.state.data.tree, molstar.state.data.cells, cellRef, PluginStateObject.Molecule.Trajectory);
    }
    catch (_a) { }
    return trajectory;
}
