import { FileType } from "..";
import urlJoin from "url-join";
import { supportedFiles } from "..";
function joinIfNotAbsolute(basePath, filePath) {
    if (filePath.charAt(0) === "/")
        return filePath;
    return urlJoin(basePath, filePath);
}
function updateDCPathInSystTree(system, basePath) {
    var _a;
    if (system.children) {
        system.children.forEach((child) => {
            updateDCPathInSystTree(child, basePath);
        });
    }
    else {
        system.files = (_a = system.files) === null || _a === void 0 ? void 0 : _a.map((f) => joinIfNotAbsolute(basePath, f));
    }
}
export function getFilesFullPath(configFile, basePath) {
    // Modify nap file so that it contains full paths
    for (let elementType in configFile) {
        const elData = configFile[elementType];
        if (Array.isArray(elData)) {
            elData.forEach((systOrOther) => {
                if (systOrOther.path) {
                    // it's a table or plot
                    systOrOther.path = joinIfNotAbsolute(basePath, systOrOther.path);
                }
                else {
                    // it's a system
                    updateDCPathInSystTree(systOrOther, basePath);
                }
            });
        }
        else {
            if (elData.files) {
                elData.files = elData.files.map((fileName) => joinIfNotAbsolute(basePath, fileName));
            }
        }
    }
}
export function getFileType(mol, singleResidue, predefinedType) {
    if (mol && mol.numFrames > 1) {
        return predefinedType ? predefinedType : FileType.trajectory;
    }
    return singleResidue ? FileType.sdf : FileType.coordinates_single;
}
const suppFileToFiletype = {
    coordinates_static: FileType.coordinates_single,
    coordinates_trajectory: FileType.trajectory,
    topology: FileType.trajectory,
    volume: FileType.volume,
};
export const extensionToFileType = {};
Object.entries(supportedFiles)
    .filter((e) => e[0] !== "loading_batch")
    .forEach((e) => {
    const [suppfile, extli] = e;
    extli.forEach((ext) => {
        if (suppfile in FileType) {
            const _suppfile = suppfile;
            const ftype = suppFileToFiletype[_suppfile];
            extensionToFileType[ext] = ftype;
        }
    });
});
export function toAbsPath(base, mypath) {
    return mypath.charAt(0) === "/" ? mypath : urlJoin(base, mypath);
}
export function findConfigFile(acceptedFiles) {
    return Array.from(acceptedFiles).filter((myFile) => {
        const ext = myFile.name.split(".").pop();
        return ext && supportedFiles.instructions.includes(ext);
    });
}
export function checkForMissingFiles(loadingInstructions, acceptedFilesObject) {
    let missingFiles = [];
    const { systems } = loadingInstructions;
    if (!systems)
        return missingFiles;
    systems.forEach((system) => {
        const missingFilesSystem = getMissingFilesRecursive(system, acceptedFilesObject);
        missingFiles = missingFiles.concat(missingFilesSystem);
    });
    return missingFiles;
}
function getMissingFilesRecursive(system, acceptedFilesObject) {
    var _a;
    let missingFiles = [];
    if (system.children) {
        system.children.forEach((s) => {
            const mf = getMissingFilesRecursive(s, acceptedFilesObject);
            missingFiles = missingFiles.concat(mf);
        });
    }
    else {
        const isRemote = (_a = system.files) === null || _a === void 0 ? void 0 : _a.every((f) => f.startsWith("dc://"));
        if (system.files && !isRemote) {
            system.files.forEach((f) => {
                if (!(f in acceptedFilesObject))
                    missingFiles.push(f);
            });
        }
    }
    return missingFiles;
}
