var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { Box } from "@mui/material";
import { MolStarWrapper } from "../../3dViewer/MolstarWrapper";
import { viewer, bottomPanel } from "../panelDimensions";
const bottomPanelHeight = bottomPanel.height;
const space = (theme) => theme.spacing(7);
export const ViewerPanel = ({ NAPGenericStore, version }) => {
    const [activePanels, topPanelHeight, rightPanelWidth] = NAPGenericStore((state) => [
        state._activePanels,
        state.topPanelHeight,
        state.dataVizPanelWidth,
    ], shallow);
    const showingPlots = activePanels.plots;
    const showingConsole = activePanels.console;
    useEffect(() => {
        function resizeMolStar() {
            return __awaiter(this, void 0, void 0, function* () {
                if (!window.molstar)
                    return;
                yield window.molstar.handleResize();
            });
        }
        resizeMolStar();
    }, [activePanels]);
    const varHeight = () => {
        const noConsole = version === "fullHeight" || version === "full";
        const bottomPadding = noConsole
            ? "0px"
            : showingConsole
                ? bottomPanelHeight
                : "1.75em";
        return `calc(${viewer.height} - ${bottomPadding} - ${topPanelHeight}px)`;
    };
    const varWidth = (theme) => {
        const leftPadding = "0px";
        const rightPadding = showingPlots ? rightPanelWidth : "0px";
        return `calc(100vw - ${leftPadding} - ${rightPadding} - ${version === "full" ? "0px" : space(theme)})`;
    };
    const varLeft = (theme) => {
        const leftPadding = "0px";
        return `calc(${leftPadding} + ${version === "full" ? "0px" : space(theme)})`;
    };
    const varRight = (theme) => {
        const rightPadding = showingPlots ? rightPanelWidth : "0px";
        return `calc(${rightPadding} + ${space(theme)})`;
    };
    const viewer_dimensions = Object.assign(Object.assign({}, viewer), { left: varLeft, right: varRight, width: varWidth, height: varHeight, top: `${topPanelHeight - 1}px` });
    return (_jsx(Box, Object.assign({ sx: viewer_dimensions }, { children: _jsx(MolStarWrapper, {}) })));
};
