// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { indexSelectorToIndexArray } from "../../utils/IndexSelector/indexSelectorToIndexArray";
export function updateVisibleSystemEntriesState(numElements, visibility, system, activeTextFieldVal, NAPGenericStore) {
    const systFiles = system === null || system === void 0 ? void 0 : system.files;
    if (!systFiles || systFiles.length === 0)
        return;
    if (visibility) {
        if (numElements !== undefined && systFiles && systFiles.length > 0) {
            const visibleElementIdx = indexSelectorToIndexArray(activeTextFieldVal, numElements);
            NAPGenericStore.getState().setVisibleSystemFileEntries(systFiles[0], visibleElementIdx);
        }
    }
    else {
        NAPGenericStore.getState().removeVisibleSystemFileEntries(systFiles[0]);
    }
}
