// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import Ajv from "ajv/dist/jtd";
import { FileType, TableClickAction, PlotClickAction, PlotHoverAction, IsLoadingGroup, TableRowButtonAction, TrajectoryModeEnum, HighlightSelectionType, } from "../Interfaces";
import { arrayify_enum } from "./arrayify_enum";
const ajv = new Ajv({ verbose: true, allErrors: true });
// const schemma_all: JTDSchemaType<LoadingInstructions>={
//   optionalProperties: {
//     systems: { elements: { ref: "node" } },
//   }
// }
const schema = {
    definitions: {
        parent: {
            optionalProperties: {
                systems: { elements: { ref: "node" } },
                plots: { elements: { ref: "plots" } },
                tables: { elements: { ref: "table" } },
                MSAs: { elements: { ref: "MSA" } },
            },
        },
        plots: {
            properties: {
                path: { type: "string" },
            },
            optionalProperties: {
                options: { ref: "plotOptions" },
            },
        },
        table: {
            properties: {
                path: { type: "string" },
            },
            optionalProperties: {
                options: { ref: "tableOptions" },
                name: { type: "string" },
            },
        },
        tableOptions: {
            optionalProperties: {
                hideColumns: { elements: { type: "string" } },
                ignoreColumns: { elements: { type: "string" } },
                highlightColumns: { elements: { type: "string" } },
                SMILESColumns: { elements: { type: "string" } },
                onRowClick: { elements: { ref: "tableOptionsOnRowClick" } },
                rowButton: { ref: "tableOptionsRowButton" },
                color: { type: "string" },
            },
        },
        tableOptionsOnRowClick: {
            properties: {
                action: { enum: arrayify_enum(TableClickAction) },
                column: { elements: { type: "string" } },
            },
            optionalProperties: {
                options: { ref: "TableOnClickOptions" },
            },
        },
        TableOnClickOptions: {
            optionalProperties: {
                fileName_column: { elements: { type: "string" } },
                representations: { elements: { ref: "struct_reps" } },
                selectionType: { enum: arrayify_enum(HighlightSelectionType) },
                selectionColumn: { elements: { type: "string" } },
                showIndexColumn: { elements: { type: "string" } },
            },
        },
        tableOptionsRowButton: {
            properties: {
                action: { enum: arrayify_enum(TableRowButtonAction) },
                column: { type: "string" },
            },
            optionalProperties: {
                tooltip: { type: "string" },
            },
        },
        plotOptions: {
            optionalProperties: {
                onSeriesClick: { elements: { ref: "plotOptionsOnSeriesClick" } },
                onSeriesHover: { elements: { ref: "plotOptionsOnSeriesHover" } },
                onLegendClick: { elements: { ref: "plotOptionsOnLegendClick" } },
            },
        },
        plotOptionsOnSeriesClick: {
            properties: {
                action: { enum: arrayify_enum(PlotClickAction) },
            },
            optionalProperties: {
                dataName: { elements: { type: "string" } },
                seriesName: { elements: { type: "string" } },
                plotDataType: { elements: { type: "string" } },
            },
        },
        plotOptionsOnLegendClick: {
            properties: {
                action: { enum: arrayify_enum(PlotClickAction) },
            },
            optionalProperties: {
                dataName: { elements: { type: "string" } },
                seriesName: { elements: { type: "string" } },
                plotDataType: { elements: { type: "string" } },
            },
        },
        plotOptionsOnSeriesHover: {
            properties: {
                action: { enum: arrayify_enum(PlotHoverAction) },
            },
            optionalProperties: {
                dataName: { elements: { type: "string" } },
                seriesName: { elements: { type: "string" } },
                plotDataType: { elements: { type: "string" } },
            },
        },
        MSA: {
            properties: {
                path: { type: "string" },
            },
            optionalProperties: {
                name: { type: "string" },
                mapping: { type: "string" },
            },
        },
        node: {
            properties: {
                name: { type: "string" },
            },
            optionalProperties: {
                type: { enum: arrayify_enum(FileType) },
                files: { elements: { type: "string" } },
                cellRef: { elements: { type: "string" } },
                moleculeID: { type: "string" },
                visibility: { type: "boolean" },
                visibleElementIDs: { type: "string" },
                representations: { elements: { ref: "struct_reps" } },
                volumeRepresentation: { ref: "vol_reps" },
                trajectoryParameters: { ref: "traj_params" },
                children: { elements: { ref: "node" } },
                isRemote: { type: "boolean" },
                stateTreePosition: { elements: { type: "uint32" } },
                archived: { type: "boolean" },
                category: { type: "string" },
                isLoading: { enum: arrayify_enum(IsLoadingGroup) },
                aromaticBonds: { type: "boolean" },
                numEntries: { type: "int16" },
            },
        },
        struct_reps: {
            properties: {
                selection: { type: "string" },
                alpha: { type: "float64" },
                color: { type: "string" },
                type: { type: "string" },
            },
            optionalProperties: {
                refs: { elements: { ref: "molstar_refs" } },
                name: { type: "string" },
                visibility: { type: "boolean" },
                color_value: { type: "uint32" },
                carbon_color_value: { type: "uint32" },
                aromaticBonds: { type: "boolean" },
                ignoreLight: { type: "boolean" },
                bfactor_color_range: {
                    elements: { type: "float64" },
                },
                bfactor_colors: { elements: { type: "uint32" } },
            },
        },
        molstar_refs: {
            properties: {
                cellRef: { type: "string" },
            },
            optionalProperties: {
                repRef: { type: "string" },
            },
        },
        vol_reps: {
            optionalProperties: {
                type: { enum: ["wireframe", "solid"] },
                currentIsoValue: { type: "float64" },
                minIsoValue: { type: "float64" },
                maxIsoValue: { type: "float64" },
                alpha: { type: "float64" },
                color: { type: "float64" },
            },
        },
        traj_params: {
            optionalProperties: {
                frame: { type: "uint32" },
                step: { type: "uint32" },
                mode: { ref: "traj_mode" },
            },
        },
        traj_mode: {
            properties: {
                name: { enum: arrayify_enum(TrajectoryModeEnum) },
            },
            optionalProperties: {
                options: { ref: "traj_mode_options" },
            },
        },
        traj_mode_options: {
            properties: {
                from: { type: "uint32" },
                to: { type: "uint32" },
                step: { type: "uint32" },
            },
        },
    },
    ref: "parent",
};
const validate = ajv.compile(schema);
/**
 *
 * @param x The parsed json object to be validated.
 * @returns {Object} An object containing an `isOk` boolean and an error array (optional).
 */
const validateNAPfile = (x) => {
    if (x && x["plots"]) {
        if ("files" in x["plots"]) {
            const newFormat = x.plots.files.map((f) => {
                return { path: f };
            });
            x["plots"] = newFormat;
        }
    }
    if (validate(x)) {
        return { isOk: true };
    }
    else {
        const _err = validate.errors;
        validate.errors = null;
        const err = _err === null || _err === void 0 ? void 0 : _err.map((e) => {
            return {
                path: e.instancePath,
                errMessage: e.message,
                currentValue: e.data,
                schema: e.schema,
            };
        });
        return { isOk: false, errors: err };
    }
};
export default validateNAPfile;
