// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { MolScriptBuilder as query } from "molstar/lib/mol-script/language/builder";
import { StructureSelectionQueries as SelectionQueries } from "molstar/lib/mol-plugin-state/helpers/structure-selection-query";
const _merge = (selections) => query.struct.combinator.merge(selections);
const select_all = () => SelectionQueries.all.expression;
const select_water = () => SelectionQueries.water.expression;
const select_ligand = () => SelectionQueries.ligand.expression;
const select_protein = () => {
    return _merge([
        SelectionQueries.protein.expression,
        select_by_residue_name("ACE"),
        select_by_residue_name("NME"),
    ]);
};
const select_heteroatoms = () => {
    return query.struct.modifier.exceptBy({
        0: select_all(),
        by: query.struct.combinator.merge([select_protein(), select_water()]),
    });
};
const select_by_residue_name = (residueName) => {
    return query.struct.generator.atomGroups({
        "residue-test": query.core.rel.eq([
            query.ammp("auth_comp_id"),
            residueName,
        ]),
    });
};
const select_element = (elementSymbol) => {
    return query.struct.generator.atomGroups({
        "atom-test": query.core.rel.eq([query.acp("elementSymbol"), elementSymbol]),
    });
};
const select_all_non_protein = () => {
    return query.struct.modifier.exceptBy({
        0: select_all(),
        by: select_protein(),
    });
};
function select_atoms_multipleEntries(atomIdsArray) {
    const groups = [];
    for (let i = 0; i < atomIdsArray.length; i += 1) {
        const atomIds = atomIdsArray[i];
        if (atomIds === undefined)
            continue;
        const regexPattern = `.* \\(${i + 1}\\)$`;
        const entrySel = query.struct.generator.atomGroups({
            "atom-test": query.core.set.has([
                query.core.type.set(atomIds),
                query.struct.atomProperty.core.sourceIndex(),
            ]),
            "entity-test": query.core.str.match([
                query.re(regexPattern),
                query.acp("modelLabel"),
            ]),
        });
        groups.push(entrySel);
    }
    return query.struct.combinator.merge(groups);
}
const select_atoms = (atomIdsArray) => {
    if (atomIdsArray.length <= 0)
        return;
    if (atomIdsArray.length === 1) {
        return query.struct.generator.atomGroups({
            "atom-test": query.core.set.has([
                query.core.type.set(atomIdsArray[0]),
                query.struct.atomProperty.core.sourceIndex(),
            ]),
        });
    }
    return select_atoms_multipleEntries(atomIdsArray);
};
export const basicStructureQueries = {
    all: select_all,
    water: select_water,
    ligand: select_ligand,
    protein: select_protein,
    element: select_element,
    heteroatoms: select_heteroatoms,
    "non-protein": select_all_non_protein,
    atoms: select_atoms,
};
