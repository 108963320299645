import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid } from "@mui/material";
import ColorPickerPopover from "../SelectColor";
import produce from "immer";
import RoundDeleteButton from "../../../components/Buttons/DeleteButton/RoundDeleteButton";
import { AddColorButton } from "./AddColorButton";
export function ColorRangeElements({ colors, onUpdate, }) {
    const onColorChange = (idx, newColor) => {
        onUpdate((cols) => {
            const newColors = produce(cols, (draftCols) => {
                draftCols[idx] = newColor;
            });
            return newColors;
        });
    };
    const onColorDelete = (rvIdx) => {
        onUpdate((cols) => cols.filter((_, index) => index !== rvIdx));
    };
    const onColorAdd = () => {
        onUpdate((cols) => {
            const newColors = produce(cols, (draftCols) => {
                draftCols.push("#ffffff");
            });
            return newColors;
        });
    };
    const noRenove = colors.length <= 1;
    return (_jsx(_Fragment, { children: _jsx(Box, Object.assign({ sx: {
                display: "flex",
                "& .MuiPaper-root": { p: 0 },
                maxWidth: "172px",
                width: "100%",
            }, justifyContent: "center", alignItems: "center" }, { children: _jsxs(Grid, Object.assign({ container: true, sx: {
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }, direction: "row" }, { children: [colors.map((color, i) => {
                        const deleteColor = (_jsx(RoundDeleteButton, { onClick: () => {
                                onColorDelete(i);
                            }, icon: "delete", tooltipMsg: "Remove" }));
                        return (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { m: "3px" } }, { children: _jsx(ColorPickerPopover, { color: color, onColorChange: (x) => {
                                    onColorChange(i, x);
                                }, extraOptions: noRenove ? undefined : deleteColor }) }), `color-range-element-${i}`));
                    }), colors.length <= 20 && (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(AddColorButton, { onClick: onColorAdd }) })))] })) })) }));
}
