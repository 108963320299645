import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Typography, Grid, TextField, IconButton, Collapse, Paper, } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
export function CreateGroupMenu({ isCreateGroupActive, onCancelCreateGroupClick, onAcceptGroupClick, numSelectedSystems, onGroupNameChange, groupName, backgroundColor, }) {
    return (_jsx(Collapse, Object.assign({ in: isCreateGroupActive, unmountOnExit: true }, { children: _jsx(Paper, Object.assign({ elevation: 0, sx: {
                backgroundColor: backgroundColor ? backgroundColor : undefined,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "row", alignItems: "flex-start", sx: { p: 2 } }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, alignItems: "center", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextField, { sx: { width: "100%" }, id: "create-group-name", label: "Create New Group", variant: "outlined", value: groupName, size: "small", onChange: (e) => onGroupNameChange(e.target.value), inputProps: {
                                        style: { fontSize: "0.875rem" },
                                    } }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ onClick: onCancelCreateGroupClick, color: "primary", size: "small" }, { children: _jsx(CloseIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: onAcceptGroupClick, color: "primary", disabled: numSelectedSystems === 0 }, { children: _jsx(CheckIcon, {}) })) }))] })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsxs(Typography, Object.assign({ sx: { fontSize: "13px", pt: 1 } }, { children: ["(", numSelectedSystems, " selected)"] })) }))] })) })) })));
}
