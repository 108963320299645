import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Button, Divider, Typography } from "@mui/material";
//@ts-ignore
import logo_white from "../../static/images/pm_logo_white.png";
import { fileUploadingHandler } from "../../FileLoaders";
import { useLocation } from "wouter";
import { onStructureLoadCallback } from "../../utils/FileUpload/onStructureLoadCallback";
import { PDBIDInput } from "./PDBInput";
import { LoadFileButton } from "./LoadFileButton";
export function ViewerHelpActions({ disabled, vss, molstar, pyodide, NAPGenericStore, }) {
    const [, setLocation] = useLocation();
    function openSystemsPanel(loadInstructions) {
        onStructureLoadCallback(loadInstructions, setLocation);
    }
    const handleLoadFileCb = (e) => fileUploadingHandler(e, vss, molstar, pyodide, NAPGenericStore, openSystemsPanel);
    const handleGoToApps = () => {
        setLocation("/tools");
    };
    return (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", gap: 1, flexDirection: "column" }, { children: [_jsx(Typography, Object.assign({ display: "inline" }, { children: "Load a molecular structure to begin:" })), _jsx(PDBIDInput, { disabled: disabled, vss: vss, molstar: molstar, pyodide: pyodide, onSuccessCallback: openSystemsPanel }), _jsx(LoadFileButton, { disabled: disabled, handleLoadFileCb: handleLoadFileCb }), _jsx(Divider, Object.assign({ sx: {
                    ":before": { borderColor: "#595959" },
                    ":after": { borderColor: "#595959" },
                }, flexItem: true }, { children: "or" })), _jsx(Button, Object.assign({ disabled: disabled, fullWidth: true, size: "small", variant: "contained", onClick: handleGoToApps, startIcon: disabled ? undefined : (_jsx("img", { src: logo_white, style: { width: "18px", height: "18px" }, alt: "pmlogo" })) }, { children: "Go to Applications" }))] })));
}
