// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Script } from "molstar/lib/mol-script/script";
import { StructureElement, StructureSelection, } from "molstar/lib/mol-model/structure";
import { getButton, getButtons, getModifiers, } from "molstar/lib/mol-util/input/input-observer";
import { Representation } from "molstar/lib/mol-repr/representation";
import { createLociRange } from "../../Sequence/selectResidueRange";
import { binarySearchEnd, binarySearchStart } from "./utils";
import { throttle } from "../../utils/throttle";
export function posSelToLoci(resIdx, chainId, structureData, onlyCA) {
    let loci;
    try {
        const sel = Script.getStructureSelection((Q) => {
            const selExpr = {
                "entity-test": Q.core.rel.eq([Q.ammp("entityType"), "polymer"]),
                "residue-test": typeof resIdx === "number"
                    ? Q.core.rel.eq([
                        Q.struct.atomProperty.macromolecular.label_seq_id(),
                        resIdx,
                    ])
                    : Q.core.set.has([Q.set(...resIdx), Q.ammp("auth_seq_id")]),
                "group-by": Q.struct.atomProperty.macromolecular.residueKey(),
                "chain-test": Q.core.rel.eq([
                    Q.struct.atomProperty.macromolecular.auth_asym_id(),
                    chainId,
                ]),
            };
            if (onlyCA) {
                selExpr["atom-test"] = Q.core.rel.eq([Q.ammp("label_atom_id"), "CA"]);
            }
            return Q.struct.generator.atomGroups(selExpr);
        }, structureData);
        loci = StructureSelection.toLociWithSourceUnits(sel);
    }
    catch (e) {
        console.error(e);
    }
    return loci;
}
export function posIdxToLoci(posIdx, structureData, mapping) {
    let loci;
    try {
        const mappedPos = mapping[posIdx];
        const chainId = mappedPos && mappedPos["chainid"];
        const resIdxStr = mappedPos && mappedPos["resid"];
        if (chainId === undefined ||
            resIdxStr === undefined ||
            Number.isNaN(Number(resIdxStr)))
            return;
        loci = posSelToLoci(Number(resIdxStr), chainId, structureData);
    }
    catch (e) {
        console.error(e);
    }
    return loci;
}
export const handleClick = (loci, e, molstar) => {
    const buttons = getButtons(e.nativeEvent);
    const button = getButton(e.nativeEvent);
    const modifiers = getModifiers(e.nativeEvent);
    const ev = {
        current: Representation.Loci.Empty,
        buttons,
        button,
        modifiers,
    };
    if (loci && !StructureElement.Loci.isEmpty(loci)) {
        ev.current = { loci };
    }
    molstar.behaviors.interaction.click.next(ev);
};
export function selectPositionRange(startPosIdx, endPosIdx, structureData, mapping, mappedPositions) {
    let loci;
    try {
        const _mappedPositions = mappedPositions
            ? mappedPositions
            : Object.keys(mapping)
                .map((key) => Number(key))
                .sort((a, b) => a - b);
        const [_startPosIdx, _endPosIdx] = [startPosIdx, endPosIdx].sort((a, b) => a - b);
        let startPosIdxMapped;
        let skipFirstPos;
        if (_startPosIdx in mapping) {
            startPosIdxMapped = _startPosIdx;
            skipFirstPos = true;
        }
        else {
            // Find the index of the first position >= start
            startPosIdxMapped = binarySearchStart(_mappedPositions, _startPosIdx);
            skipFirstPos = false;
        }
        let endPosIdxMapped;
        if (_endPosIdx in mapping) {
            endPosIdxMapped = _endPosIdx;
        }
        else {
            // Find the index of the last position <= end
            endPosIdxMapped = binarySearchEnd(_mappedPositions, _endPosIdx);
        }
        if (startPosIdxMapped > endPosIdxMapped) {
            return;
        }
        if (startPosIdxMapped === endPosIdxMapped && !skipFirstPos) {
            loci = posIdxToLoci(startPosIdxMapped, structureData, mapping);
        }
        else {
            const startLoci = posIdxToLoci(startPosIdxMapped, structureData, mapping);
            const endLoci = posIdxToLoci(endPosIdxMapped, structureData, mapping);
            if (!startLoci || !endLoci)
                return;
            const lociRange = createLociRange(startLoci, endLoci);
            if (lociRange) {
                loci = skipFirstPos
                    ? StructureElement.Loci.subtract(lociRange, startLoci)
                    : lociRange;
            }
        }
    }
    catch (e) {
        console.error(e);
    }
    return loci;
}
export const handleHighlight = (loci, buttons, button, modifiers, molstar) => {
    const ev = {
        current: Representation.Loci.Empty,
        buttons,
        button,
        modifiers,
    };
    if (loci && !StructureElement.Loci.isEmpty(loci)) {
        ev.current = { loci };
    }
    molstar.behaviors.interaction.hover.next(ev);
};
export const handleHighlightThrottled = throttle(handleHighlight, 250);
