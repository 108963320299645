// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getRawFileContent } from "../../apps/results/fetchDcData";
import { findSystemFileInTree } from "../../Tables/utils";
import { loadFileAsText } from "../../FileLoaders/FileReaders/loadFileAsText";
import { getAbsPathFromTable } from "../../3dViewer/Controls/utils";
import { getStrucToPosMap } from "./prepareMSA";
function loadMSAmapping(mappingFilePathAbs, msaFilePath, pyodide, acceptedFilesObject, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        let fileContent;
        const isRemote = msaFilePath.startsWith("dc://");
        if (isRemote) {
            const prefixIdx = msaFilePath.indexOf("=");
            const prefix = msaFilePath.slice(0, prefixIdx + 1);
            const mappingRemoteFilePath = `${prefix}${mappingFilePathAbs}`;
            fileContent = yield getRawFileContent(mappingRemoteFilePath, pyodide);
        }
        else if (acceptedFilesObject && mappingFilePathAbs in acceptedFilesObject) {
            const loadedFile = acceptedFilesObject[mappingFilePathAbs];
            if (loadedFile["isLoaded"]) {
                fileContent = loadedFile["data"];
            }
            else {
                fileContent = yield loadFileAsText(loadedFile, mappingFilePathAbs);
            }
        }
        else if (localBasePath) {
            //@ts-ignore
            const wIPCR = window.ipcr;
            if (!wIPCR)
                return;
            const mappingFile = yield wIPCR.invoke("read-local-file", mappingFilePathAbs);
            mappingFile;
            fileContent = mappingFile === null || mappingFile === void 0 ? void 0 : mappingFile.data;
        }
        if (fileContent) {
            try {
                fileContent = JSON.parse(fileContent);
            }
            catch (e) {
                fileContent = undefined;
            }
        }
        return fileContent;
    });
}
export function readMSAmapping(mappingFilePath, msaFilePath, vss, pyodide, acceptedFilesObject, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        const mappingFilePathAbs = localBasePath
            ? getAbsPathFromTable(mappingFilePath, localBasePath)
            : mappingFilePath;
        const mappingData = yield loadMSAmapping(mappingFilePathAbs, msaFilePath, pyodide, acceptedFilesObject, localBasePath);
        if (!mappingData)
            return;
        const refFilePath = localBasePath
            ? getAbsPathFromTable(mappingData["file"], localBasePath)
            : mappingData["file"];
        const map = mappingData["mapping"];
        if (!refFilePath || !map)
            return;
        const systems = vss.getState().loaded_structures;
        const refSystem = findSystemFileInTree(systems, refFilePath);
        const refMolId = refSystem === null || refSystem === void 0 ? void 0 : refSystem.moleculeID;
        if (!refMolId)
            return;
        const strucToPos = getStrucToPosMap(map);
        return {
            posToStruc: map,
            strucToPos: strucToPos,
            refMolId: refMolId,
            filePath: mappingFilePathAbs,
        };
    });
}
